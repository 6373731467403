import { filter, globe, right } from "assets/icons";
import { useState } from "react";
import Filters from "./Filters/Filters";
import Preferences from "./Preferences/Preferences";

const Notifications = () => {
  const [tabOpen, setTabOpen] = useState<null | string>(null);
  return (
    <div className="flex flex-col gap-12">
      <div
        className={`flex flex-col gap-12 ${
          tabOpen === "filters" || tabOpen === "preferences" ? "hidden" : ""
        }`}
      >
        <div className="text-black font-bold text-lg">Notifications</div>
        <div className="text-black text-base">
          Select the kinds of notifications you get about your activities, interests, and
          recommendations.
        </div>
      </div>
      <div
        className={`flex items-center justify-between cursor-pointer ${
          tabOpen === "filters" || tabOpen === "preferences" ? "hidden" : ""
        }`}
        onClick={() => setTabOpen("filters")}
      >
        <div className="flex items-center gap-2">
          <img src={filter} />
          <span>
            <p className="text-green text-lg">Filters</p>
            <p className="text-gray-200 text-sm">
              Choose the notifications you would like to see and those you don’t
            </p>
          </span>
        </div>
        <img src={right} />
      </div>

      <div
        className={`flex items-center justify-between cursor-pointer ${
          tabOpen === "filters" || tabOpen === "preferences" ? "hidden" : ""
        }`}
        onClick={() => setTabOpen("preferences")}
      >
        <div className="flex items-center gap-2">
          <img src={globe} />
          <span>
            <p className="text-green text-lg">Preferences</p>
            <p className="text-gray-200 text-sm">
              Select your preferences by notification type.{" "}
              <span className="hover:text-orange">Learn more</span>
            </p>
          </span>
        </div>
        <img src={right} />
      </div>

      <div>
        {tabOpen === "filters" && <Filters />}
        {tabOpen === "preferences" && <Preferences />}
      </div>
    </div>
  );
};

export default Notifications;
