import { Journal } from "assets/images";
import { useState } from "react";
import JournalNote from "./JournalNote";

const Journals = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        className="bg-[#FFFAF2] rounded-[10px] lg:min-w-[258px] min-h-[110px] cursor-pointer p-2"
        onClick={() => handleOpenModal()}
      >
        <div>
          <div className="flex justify-center items-center gap-2 py-4 ">
            <img src={Journal} />
            <p className="text-[24px] text-[#3C4E40] text-center ">Journal</p>
          </div>
          <p className="text-[15px] text-[#3C4E40] text-center">
            What’s Happening? You can write about it.
          </p>
        </div>
      </div>
      {showModal && <JournalNote closeme={() => handleCloseModal()} />}
    </>
  );
};

export default Journals;
