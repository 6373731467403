import { SVGProps } from "react";
/* eslint-disable prettier/prettier */
type Props = {
  size?: number;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;

const BMliveshow = ({ size, color, className, ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={color}
      className={className}
      viewBox="0 0 24 17"
      {...props}
    >
      
      <path d="M4.67136 1.6034L3.52922 0.498795C3.52444 0.503661 3.51951 0.507845 3.51473 0.512468C1.27041 2.6829 0.00662691 5.62488 2.59879e-05 8.69442C-0.00657493 11.764 1.24454 14.711 3.4795 16.8904L4.62165 15.7858C2.68416 13.8984 1.601 11.3441 1.61033 8.68447C1.61965 6.02486 2.72068 3.4777 4.67136 1.60301V1.6034Z"/>{" "}
      <path d="M20.4855 0.512661C20.4654 0.493196 20.4444 0.474072 20.424 0.454559L19.2819 1.55916C20.2596 2.48692 21.0376 3.59278 21.571 4.8129C22.1044 6.03302 22.3826 7.34324 22.3896 8.66792C22.3967 9.9926 22.1323 11.3055 21.6119 12.5309C21.0914 13.7562 20.3251 14.8697 19.3573 15.8071L20.4995 16.9117C22.7437 14.734 24.0027 11.7841 24 8.7095C23.9974 5.63491 22.7333 2.68699 20.4853 0.512952L20.4855 0.512661Z"/>{" "}
      <path d="M6.82286 3.6841C5.44365 5.0074 4.66377 6.80595 4.65444 8.68486C4.64511 10.5638 5.4071 12.3695 6.7731 13.7055L7.91107 12.605C6.84735 11.5606 6.25524 10.1508 6.26457 8.68487C6.2739 7.2189 6.8839 5.81633 7.96083 4.78466L6.82286 3.6841Z"/>{" "}
      <path d="M15.9926 4.74027C16.5376 5.25059 16.9724 5.86059 17.2717 6.53482C17.5709 7.20905 17.7286 7.93407 17.7356 8.66776C17.7426 9.40144 17.5988 10.1292 17.3126 10.8086C17.0263 11.4881 16.6032 12.1058 16.068 12.6258L17.2065 13.7266C17.8914 13.0622 18.4332 12.273 18.8005 11.4047C19.1678 10.5364 19.3533 9.6062 19.3462 8.66805C19.3392 7.7299 19.1398 6.80242 18.7596 5.93933C18.3793 5.07624 17.8258 4.29469 17.131 3.63995L15.9926 4.74027Z"/>{" "}
      <path d="M13.9295 6.73571C13.4576 6.30755 12.8475 6.0498 12.202 6.00589C11.5566 5.96199 10.9152 6.13461 10.3859 6.49467C9.85672 6.85474 9.47201 7.38023 9.29664 7.9826C9.12127 8.58497 9.16597 9.2274 9.42319 9.80162C9.68041 10.3758 10.1344 10.8468 10.7088 11.135C11.2831 11.4233 11.9426 11.5112 12.5762 11.3841C13.2097 11.257 13.7786 10.9225 14.187 10.4371C14.5953 9.95162 14.8181 9.34488 14.8179 8.71909C14.8182 8.34727 14.7395 7.97936 14.5866 7.63818C14.4338 7.29701 14.2101 6.98983 13.9295 6.73571ZM12.0003 9.88695C11.7615 9.88695 11.528 9.81846 11.3294 9.69013C11.1308 9.5618 10.9761 9.37941 10.8847 9.16601C10.7933 8.95261 10.7694 8.71779 10.816 8.49125C10.8626 8.2647 10.9776 8.05661 11.1464 7.89328C11.3153 7.72995 11.5305 7.61873 11.7647 7.57366C11.999 7.5286 12.2418 7.55173 12.4624 7.64012C12.6831 7.72851 12.8717 7.8782 13.0043 8.07026C13.137 8.26231 13.2079 8.48811 13.2079 8.71909C13.2079 8.87245 13.1766 9.02432 13.1159 9.16601C13.0553 9.3077 12.9663 9.43644 12.8542 9.54489C12.742 9.65334 12.6089 9.73936 12.4624 9.79805C12.3159 9.85674 12.1589 9.88695 12.0003 9.88695Z"/>{" "}
    </svg>
  );
};
BMliveshow.defaultProps = {
  size: 16,
  color: "currentColor"
};

export default BMliveshow;
