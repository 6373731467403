import { Therapist } from "./ProfileSummary";

interface Props {
  therapist: Therapist;
}

const InsuranceAndOtherChecks = ({ therapist }: Props) => {
  const { insurance, checks } = therapist;
  return (
    <section className="my-4 text-xs md:text-sm flex gap-3 md:gap-7 shadlow-lg">
      <div className="w-[50%] px-2 sm:px-4 py-5 bg-[#FF9C00] rounded-lg shadow-2xl">
        <h3 className="w-[] mb-2 font-semibold">Insurance Plans Accepted</h3>
        {insurance.map((item, index) => (
          <li className="ml-2 leading-[20px] sm:leading-[25px] md:leading-[32px]" key={index}>
            {item}
          </li>
        ))}
      </div>
      <div className="w-[50%] px-2 sm:px-4 py-5 bg-[#FF9C00] rounded-lg shadow-2xl">
        <h3 className="mb-2 font-semibold">Swift Check</h3>
        {checks.map((item, index) => (
          <li className="ml-4 leading-[20px] sm:leading-[25px] md:leading-[32px] list-none" key={index}>
            {item}
          </li>
        ))}
      </div>
    </section>
  );
};

export default InsuranceAndOtherChecks;
