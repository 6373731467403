import { DashboardWrapper } from "components";
import EventsView from "./EventsView";

export const EventsContainer = () => {
  return (
    <DashboardWrapper>
      <EventsView />
    </DashboardWrapper>
  );
};
