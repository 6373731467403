import { TherapistDashboardWrapper } from "components";
import TherLiveshowView from "./TherLiveshowView";

export const TherLiveshowContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherLiveshowView />
    </TherapistDashboardWrapper>
  );
};
