import React, { useState } from "react";
import { SVCheckbox } from "components/modules";
import { Form, Formik } from "formik";

interface StepTwoProps {
  stepOneData: any;
  onPreviousStep: () => void;
}

const StepTwo: React.FC<StepTwoProps> = ({ stepOneData }) => {
  const [formData] = useState<any>({
    buddhism: false,
    islam: false,
    taoism: false,
    christianityOrCatholic: false,
    jainism: false,
    otherReligion: false,
    confucianism: false,
    judaism: false,
    noPreferenceReligion: false,
    hinduism: false,
    sikhism: false,
    preferNotToSayReligion: false,
    african: false,
    latinoOrHispanic: false,
    mixedRace: false,
    black: false,
    middleEastern: false,
    noPreferenceRace: false,
    carribean: false,
    pacificIslander: false,
    preferNotToSayRace: false,
    eastAsian: false,
    southAsianOrIndian: false,
    indigenous: false,
    southeastAsian: false,
    psychologist: false,
    licensedMedicalHealthCounsellor: false,
    psychiatrist: false,
    licensedClinicalPsychologist: false,
    licensedClinicalSocialWorker: false,
    licensedProfessionalClinicalCounsellor: false,
    licensedProfessionalCounsellor: false,
    marriageAndFamilyTherapist: false,
    psychiatricNursePractitioner: false,
    noPreferenceProfessional: false
  });

  const handleSubmit = (values: any) => {
    // Combine the form data from StepOne and StepTwo
    const combinedFormData = {
      ...stepOneData,
      ...values
    };

    // Process the combined form data or perform any other actions
    console.log(combinedFormData);
  };

  return (
    <Formik initialValues={formData} onSubmit={handleSubmit}>
      <Form>
        <div className="mt-8">
          <p className="font-bold text-lg">
            3. Is there a religious background, you prefer in a mental health professional?
          </p>
          <div className="grid grid-cols-3 gap-4 mt-8 ml-6">
            <SVCheckbox nameAndId="buddhism" label="Buddhism" />
            <SVCheckbox nameAndId="islam" label="Islam" />
            <SVCheckbox nameAndId="taoism" label="Taoism" />
            <SVCheckbox nameAndId="christianityOrCatholic" label="Christianity/Catholic" />
            <SVCheckbox nameAndId="jainism" label="Jainism" />
            <SVCheckbox nameAndId="other" label="Other" />
            <SVCheckbox nameAndId="confucianism" label="Confucianism" />
            <SVCheckbox nameAndId="judaism" label="Judaism" />
            <SVCheckbox nameAndId="noPreference" label="No Preference" />
            <SVCheckbox nameAndId="hinduism" label="Hinduism" />
            <SVCheckbox nameAndId="sikhism" label="Sikhism" />
            <SVCheckbox nameAndId="preferNotToSay" label="Prefer Not To Say" />
          </div>
        </div>

        <div className="mt-16">
          <p className="font-bold text-lg">
            4. Is there a racial/ethnic background, you prefer in a mental health professional?
          </p>
          <div className="grid grid-cols-3 gap-4 mt-8 ml-6">
            <SVCheckbox nameAndId="african" label="African" />
            <SVCheckbox nameAndId="latinoOrHispanic" label="Latino / Hispanic" />
            <SVCheckbox nameAndId="mixedRace" label="Mixed Race" />
            <SVCheckbox nameAndId="black" label="Black" />
            <SVCheckbox nameAndId="middleEastern" label="Middle Eastern" />
            <SVCheckbox nameAndId="noPreference" label="No Preference" />
            <SVCheckbox nameAndId="carribean" label="Carribean" />
            <SVCheckbox nameAndId="pacificIslander" label="Pacific Islander" />
            <SVCheckbox nameAndId="preferNotToSay" label="Prefer Not To Say" />
            <SVCheckbox nameAndId="eastAsian" label="East Asian" />
            <SVCheckbox nameAndId="southAsianOrIndian" label="South Asian / Indian" />
            <SVCheckbox nameAndId="Indigenous" label="Indigenous" />
            <SVCheckbox nameAndId="southeastAsian" label="South East Asian" />
          </div>
        </div>

        <div className="mt-16">
          <p className="font-bold text-lg">
            5. What type of mental health professional would you like to receive care from?
          </p>
          <div className="grid grid-cols-2 gap-4 mt-8 ml-6">
            <SVCheckbox nameAndId="psychologist" label="Psychologist (Phd/PsyD)" />
            <SVCheckbox
              nameAndId="licensedMedicalHealthCounsellor"
              label="Licensed Medical Health Counsellor (LMHC)"
            />
            <SVCheckbox nameAndId="psychiatrist" label="Psychiatrist (MD/DO)" />
            <SVCheckbox
              nameAndId="licensedClinicalPsychologist"
              label="Licensed Clinical Psychologist (LCP)"
            />
            <SVCheckbox
              nameAndId="licensedClinicalSocialWorker"
              label="Licensed CLinical Social Worker (LCSW)"
            />
            <SVCheckbox
              nameAndId="licensedProfessionalClinicalCounsellor"
              label="Licensed Professional Clinical Counsellor (LPDC)"
            />
            <SVCheckbox
              nameAndId="licensedProfessionalCounsellor"
              label="Licensed Professional Counsellor (LPC)"
            />
            <SVCheckbox
              nameAndId="marriageAndFamilyTherapist"
              label="Marriage & Family Therapist (MFT)"
            />
            <SVCheckbox
              nameAndId="PsychiatricNursePractitioner"
              label="Psychiatric Nurse Practitioner (PMHNP)"
            />
            <SVCheckbox nameAndId="noPreference" label="No Preference" />
          </div>
        </div>
        <div className="flex justify-end mt-16">
          <button type="submit" className="bg-orange text-white p-2 rounded-md w-36">
            See Results
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default StepTwo;
