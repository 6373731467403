import { DashboardWrapper } from "components";
import LogoutView from "./LogoutView";

export const LogoutContainer = () => {
  return (
    <DashboardWrapper>
      <LogoutView />
    </DashboardWrapper>
  );
};
