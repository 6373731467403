import { useState } from "react";
import UpcomingAppointments from "./UpcomingAppointments";
import CompletedAppointments from "./CompletedAppointments";
import CancelledAppointments from "./CancelledAppointments";

interface TabProps {
  label: string;
  status: "upcoming" | "completed" | "cancelled";
  onClick: (status: "upcoming" | "completed" | "cancelled") => void;
  isActiveTab: boolean;
}

const Tab = ({ label, status, onClick, isActiveTab }: TabProps) => (
  <div
    onClick={() => onClick(status)}
    className={`cursor-pointer text-lg py-1 border-b-4 ${
      isActiveTab ? "border-b-green" : "border-b-[#F6F9FB]"
    }`}
  >
    {label}
  </div>
);

type ComponentsType = {
  [key in "upcoming" | "completed" | "cancelled"]: JSX.Element;
};

const components: ComponentsType = {
  upcoming: <UpcomingAppointments />,
  completed: <CompletedAppointments />,
  cancelled: <CancelledAppointments />
};

const Appointments = () => {
  const [tabOpen, setTabOpen] = useState<"upcoming" | "completed" | "cancelled">("upcoming");

  const handleTabClick = (status: "upcoming" | "completed" | "cancelled") => {
    setTabOpen(status);
  };

  return (
    <div>
      <div className="flex items-center gap-16">
        <Tab
          label="Upcoming Appointments"
          status="upcoming"
          onClick={handleTabClick}
          isActiveTab={tabOpen === "upcoming"}
        />
        <Tab
          label="Completed Appointments"
          status="completed"
          onClick={handleTabClick}
          isActiveTab={tabOpen === "completed"}
        />
        <Tab
          label="Cancelled Appointments"
          status="cancelled"
          onClick={handleTabClick}
          isActiveTab={tabOpen === "cancelled"}
        />
      </div>
      <div>{components[tabOpen]}</div>
    </div>
  );
};

export default Appointments;
