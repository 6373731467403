import { BookAppointment } from "components"

const BookAppointmentView = () => {
  return (
    <div className={`md:py-[2%] md:px-[5%]`}>
        <BookAppointment />
    </div>
  )
}

export default BookAppointmentView