const TrendsToday = () => {
  const TrendingToday = [
    {
      id: 1,
      title: "Healing through art",
      posts: "106 posts"
    },
    {
      id: 2,
      title: "Building Supportive Communities",
      posts: "103 posts"
    },
    {
      id: 3,
      title: "Self-Love and Body Positivity",
      posts: "98 posts"
    },
    {
      id: 4,
      title: "Mindfulness and Meditation",
      posts: "68 posts"
    },
    {
      id: 5,
      title: "Self-Care for Black Mental Health",
      posts: "48 posts"
    }
  ];
  return (
    <div>
      <div className="mt-8 mb-4 p-4 bg-white border-[0.04em] border-[#E7E7E7] rounded-[8px]">
        <div className="flex justify-between items-center pb-4">
          <h3 className="font-[600] text-[#005028]">Today Trending</h3>
          <button className="font-[500] text-[12px] text-[#005028]">See more</button>
        </div>
        {TrendingToday.map((item) => (
          <div className="py-1" key={item.id}>
            <div className="flex items-center gap-2">
              <div className="w-[5px] h-[5px] bg-[#3C4E40] rounded-full"></div>
              <p className="font-[500] text-[14px] text-[#000]">{item.title}</p>
            </div>
            <p className="text-[10px] text-[#78827A] font-[500] pl-3">{item.posts}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendsToday;
