import {
  shareArrow,
  shareDiscord,
  shareFb,
  shareLinkedin,
  sharePin,
  shareText,
  shareTwitter,
  shareWhatsapp
} from "assets/icons";
import { Edit, shareLink } from "assets/images";
import { Link } from "react-router-dom";
import { HOME } from "routes/CONSTANTS";

const socials = [
  { id: 1, icon: shareLink, link: "" },
  { id: 1, icon: shareFb, link: "" },
  { id: 1, icon: shareTwitter, link: "" },
  { id: 1, icon: shareWhatsapp, link: "" },
  { id: 1, icon: sharePin, link: "" },
  { id: 1, icon: shareArrow, link: "" },
  { id: 1, icon: shareText, link: "" },
  { id: 1, icon: shareDiscord, link: "" }
];

const Share = () => {
  return (
    <div>
      <h2 className="font-[700] text-[24px] text-[#3C4E40]">Share the referral link </h2>
      <div className="flex items-start gap-1">
        <div className="basis-[66%]">
          <p className="text-base text-[#3C4E40]">
            You can also share to friends on social media or copy and share link on other platforms
          </p>
          <div>
            <div className="flex items-center gap-4 py-4">
              {socials.map((item) => (
                <a key={item.id} href={item.link}>
                  <img src={item.icon} />
                </a>
              ))}
            </div>
            <div>
              <div className="flex items-center gap-2">
                <p className="bg-[#FFFBE8] rounded-[5px] max-w-[600px] mb-1">
                  Hey, check out this Black Mental Health Matters platform I found. It's awesome.
                </p>
                <img src={Edit} />
              </div>
              <p className="bg-[#FFFBE8] rounded-[5px] max-w-[600px] mb-1">
                You should join this Black Mental Health Matters platform. It's helped me a lot.
              </p>
              <p className="bg-[#FFFBE8] rounded-[5px] max-w-[600px] mb-1">
                I think you'd really like this Black Mental Health Matters platform. Give it a try!{" "}
              </p>
              <p className="bg-[#FFFBE8] rounded-[5px] max-w-[600px] mb-1">
                I wanted to share this amazing Black Mental Health Matters platform with you.
              </p>
              <p className="bg-[#FFFBE8] rounded-[5px] max-w-[600px] mb-1">
                If you're looking for a supportive Black Mental Health Matters community, join
              </p>
            </div>
          </div>
        </div>
        <div className="basis-1/3">
          <div className="rounded-[4px] border border-[#ECE8E8] shadow-lg shadow-[#ECE8E8]">
            <div className="flex justify-between py-1 px-4  items-center bg-[#ECE8E8]">
              <div className="flex items-center gap-2">
                <p>New Invite</p>
                <img src={shareLinkedin} />
              </div>
              <div className="flex items-center gap-1">
                <button>-</button>
                <button>X</button>
              </div>
            </div>
            <p className="px-4 py-2">
              Hey, check out this Black Mental Health Matters platform I found. It's awesome. I
            </p>
            <Link to={HOME} className="px-4 text-[#135382] font-bold underline">
              Black Mental Health Matters Link
            </Link>
            <div className="flex justify-end pr-4 pt-10 pb-4">
              <button className="bg-[#FF9C00] rounded-[4px] text-white font-bold text-base p-1">
                Send
              </button>
            </div>
          </div>
          <p className="px-4 py-8 text-[24px] text-[#FF9C00] font-bold">
            Invite sent successfully!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Share;
