import { Invite, Referrals, Share } from "components";

const ReferAFriendView = () => {
  return (
    <div className="bg-[#ffffff] p-8">
      <Referrals />
      <Invite />
      <Share />
    </div>
  );
};

export default ReferAFriendView;
