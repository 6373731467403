import { Therapist } from "./ProfileSummary";

interface Props {
  therapist: Therapist;
}

const Specialization = ({ therapist }: Props) => {
  const { approaches, specialties } = therapist;
  return (
    <section className="my-2 px-5 pt-10 pb-20 bg-green text-white grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20 rounded-b-lg shadow-xl">
      <div className="">
        <h2 className="mb-4 font-semibold">Therapeutic Approach</h2>
        {approaches.map((approach, index) => (
          <li className="mt-3 min-w-[300px] text-xs leading-[24px]" key={index}>
            {approach}
          </li>
        ))}
      </div>

      <div className="">
        <h2 className="mb-4 font-semibold">Issues I Treat</h2>
        {specialties.map((specialty, index) => (
          <li className="mt-3 text-xs leading-[24px]" key={index}>
            {specialty}
          </li>
        ))}
      </div>
    </section>
  );
};

export default Specialization;
