import { SVGProps } from "react";
/* eslint-disable prettier/prettier */
type Props = {
  size?: number;
  color?: string;
  className?: string;
} & SVGProps<SVGSVGElement>;

const BMaudiocasts = ({ size, color, className, ...props }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M11.9928 23.8352C5.37313 23.8209 0 18.1039 0 11.0543C0 10.7248 0.25791 10.4525 0.573134 10.4525C0.888358 10.4525 1.14627 10.7248 1.14627 11.0543C1.14627 17.4448 6.00358 22.6173 12.0072 22.6173C17.7528 22.9039 22.6101 18.1612 22.8824 12.0573C22.8967 11.7278 22.8967 11.3839 22.8824 11.0543C22.8824 10.7248 23.1403 10.4525 23.4555 10.4525C23.7707 10.4525 24.0287 10.7248 24.0287 11.0543C24.0287 18.5624 19.0854 23.8209 12.0215 23.8352H11.9928Z"
        fill="#005028"
      />
      <path d="M6.1755 10.5528H17.8674C18.2113 10.5958 18.4692 10.9254 18.4262 11.2979C18.4262 11.2979 18.4262 11.3122 18.4262 11.3266C18.111 15.4818 15.36 18.7487 11.9928 18.7487C8.62565 18.7487 5.87461 15.4818 5.57371 11.3266C5.53073 10.954 5.75998 10.6102 6.10386 10.5528C6.13252 10.5528 6.14685 10.5528 6.1755 10.5528Z" />
      <path d="M6.1755 8.97672H17.8674C18.2113 8.93373 18.4692 8.57552 18.4262 8.17433V8.14567C18.111 3.68956 15.36 0.179108 11.9928 0.179108C8.62565 0.179108 5.87461 3.67523 5.57371 8.13135C5.53073 8.53254 5.75998 8.90508 6.10386 8.96239C6.13252 8.96239 6.14685 8.96239 6.1755 8.96239V8.97672Z" />
    </svg>
  );
};

BMaudiocasts.defaultProps = {
  size: 16,
  color: "currentColor"
};

export default BMaudiocasts;
