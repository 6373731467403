import { Therapist } from "./ProfileSummary";

interface Props {
  therapist: Therapist;
}

const About = ({ therapist }: Props) => {

  const { about, background } = therapist;
  
  return (
    <section className="my-3 p-5 bg-white shadow-lg">
      <div className="mb-[10%]">
        <h2 className="text-black font-semibold mb-3">About My Client</h2>
        <p className="text-sm text-overlay leading-[30px] sm:leading-[40px]">{about}</p>
      </div>
      <div className="mb-3">
        <h2 className="text-black font-semibold mb-3">My Background and Approach</h2>
        <p className="text-sm text-overlay leading-[30px] sm:leading-[40px]">{background}</p>
      </div>
    </section>
  );
};

export default About;
