import { DashboardWrapper } from "components";
import CreateLiveFormView from "./StartLiveFormView";


export const CreateLiveFormContainer = () => {
  return (
    <DashboardWrapper>
      <CreateLiveFormView />
    </DashboardWrapper>
  );
};
