import { showAll } from "assets/icons";

const AboutGroup = () => {
  return (
    <div>
      <div className="bg-[#F6F9FB] h-[447px] p-4 rounded-[8px] my-4">
        <h4 className="font-bold text-[24px] text-[#4A4A4A]">About this group</h4>
        <div className="text-[16px] text-[#667085] py-4">
          <p>
            Lorem ipsum dolor sit amet consectetur. Sapien faucibus quisque egestas laoreet turpis
            scelerisque quis semper Viverra purus at mattis purus tempus. Elit pellentesque
            hendrerit sed nisl massa vestibulum integer.{" "}
          </p>
          <p className="pt-4">
            Orci phasellus odio sagittis in aliquet a sed id. Nisi et auctor sed netus leo. Ut
            mollis molestie egestas sollicitudin facilisis ut amet facilisi diam. Et mollis in
            malesuada sed...
          </p>
        </div>
        <div className="w-full bg-[#ECECEC] h-1 my-6"></div>
        <button className="text-[#005028] text-[24px] font-bold flex items-center gap-2">
          Show All <img src={showAll} />
        </button>
      </div>
    </div>
  );
};

export default AboutGroup;
