import type { ReactNode } from "react";
import TherapistDashboardSidebar from "./TherapistDashboardSidebar";
import TherapistDashboardHeader from "./TherapistDashboardHeader";

interface Props {
  children: ReactNode;
}

const TherapistDashboardWrapper = ({ children }: Props) => {
  return (
    <div className="bg-[#F6F9FB] flex ">
      <TherapistDashboardSidebar />

      <div className="md:w-2/3 md:ml-[33.33%]  lg:w-3/4 lg:ml-[25%] xl:w-4/5 xl:ml-[20%]">
        <TherapistDashboardHeader />
        <div className="p-8 min-h-screen mt-[80px]">{children}</div>
      </div>
    </div>
  );
};

export default TherapistDashboardWrapper;
