import { Heart,greenPlay, whitePlay } from "assets/icons";
import { therapy, therapy2 } from "assets/images";


const TrendingAudiocasts = () => {
  const Trending = [
    {
      id: 1,
      image: therapy,
      date:"March 25th, 2023",
      title:"Unfiltered Conversations: Navigating Life's Journey",
      author:"By Alex Thompson",
      time:"1h 36min",
      playImage:greenPlay,
    },
    {
      id: 2,
      image: therapy2,
      date: "March 18th, 2023",
      title:"Embracing the Unseen: The Power of Vulnerability",
      author:"By Maya Richards",
      time:"2h 5min",
      playImage:whitePlay,
    },
    
  ];
  return (
    <div>
        <div className=" flex justify-between  items-center  pt-4">
          <h1 className="font-[500] text-[26px]">Trending Audiocasts</h1>
          <button className="text-[15px] text-[#005028]">show all</button>
        </div>
        <div className="flex flex-col gap-4 justify-between py-5 rounded-xl ">
        {Trending.map((Trend) => (
          <div key={Trend.id} >

          <div className={ `${Trend.id%2 === 0 ? "flex justify-between items-center py-[21px] px-[25px] rounded-[10px] bg-[#FFF]" : "flex justify-between items-center py-[21px] px-[18px] rounded-[10px]  bg-[#FBDCAC]" } `}  > 
            <div className="lg:pl-12"><img src={Trend.image} /></div>
            <div>
              <div className=" flex justify-between items-center">
              <p className="font-[500] text-[10px] text-[#474747] ">{Trend.date}</p>
              <button><img src={Heart} /></button>
              </div>
              <p className="font-[600] text-[14px] text-[#4A4A4A] w-[330px] ">{Trend.title}</p>
              <div className=" flex justify-between items-center">
              <div className="font-[500] text-[10px] text-[#474747] ">
              <p>{Trend.author}</p>
              <p>{Trend.time}</p>
              </div>
              <button><img src={Trend.playImage} /></button>
              </div>
            </div>
          </div>
          </div>
          ))}
        </div>
    </div>
  );
};

export default TrendingAudiocasts;
