import { SuccessIcon, NegativeIcon } from "assets/icons";
import { BOOKTHERAPIST } from "routes/CONSTANTS";

interface Props {
  status: boolean;
  className?: string;
}

const SuccessStatus = ({ status, className }: Props) => {
  const messageObject = {
    icon: NegativeIcon,
    message: "Oops something went wrong and the appointment was not booked",
    linkText: "Retry",
    to: BOOKTHERAPIST
  };

  if (status) {
    messageObject.icon = SuccessIcon;
    messageObject.message = "Your Appointment Has Been Booked";
    messageObject.linkText = "Ok Thanks";
    messageObject.to = "/";
  }

  const updatedMessage = (
    <span>
      {messageObject.message}
      {status && <span className="text-[#00A552] font-bold"> Successfully</span>}
    </span>
  );

  return (
    <div
      className={`w-[40%] bg-white  border-b-[20px] rounded-lg flex flex-col items-center ${
        status ? "border-b-[#00A552]" : "border-b-[#FF5630]"
      } ${className ?? ""}`}
    >
      <div className="mt-[-16%] md:mt-[-8%] xl:mt-[-5%]">
        <img src={messageObject.icon} alt="A positive or negative Icon" />
      </div>
      <p className="max-w-[250px] px-2 my-10 text-center">{updatedMessage}</p>
      <a
        className={`my-3 font-bold ${status ? "text-[#00A552]" : "text-[#FF5630]"}`}
        href={messageObject.to}
      >
        <p>{messageObject.linkText}</p>
      </a>
    </div>
  );
};

export default SuccessStatus;
