import { cloud, download, lock, person, right } from "assets/icons";
import { useState } from "react";
import Information from "./Information/Information";
import Deactivate from "./Deactivate/Deactivate";
import Password from "./Password/Password";
import Download from "./Download/Download";

const Accounts = () => {
  const [tabOpen, setTabOpen] = useState("");
  return (
    <div>
      <div
        className={`flex flex-col gap-12 ${
          tabOpen === "info" ||
          tabOpen === "password" ||
          tabOpen === "download" ||
          tabOpen === "deactivate"
            ? "hidden"
            : ""
        }`}
      >
        <div className="text-black font-bold text-lg">Your Account</div>
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setTabOpen("info")}
        >
          <div className="flex items-center gap-2">
            <img src={person} />
            <span>
              <p className="text-green text-lg">Account Information</p>
              <p className="text-gray-200 text-sm">
                See your account information like your phone number and email address
              </p>
            </span>
          </div>
          <img src={right} />
        </div>

        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setTabOpen("password")}
        >
          <div className="flex items-center gap-2">
            <img src={lock} />
            <span>
              <p className="text-green text-lg">Change your password</p>
              <p className="text-gray-200 text-sm">Change your password at any time</p>
            </span>
          </div>
          <img src={right} />
        </div>

        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setTabOpen("download")}
        >
          <div className="flex items-center gap-2">
            <img src={download} />
            <span>
              <p className="text-green text-lg">Download and archive of your data</p>
              <p className="text-gray-200 text-sm">
                Get insights into the type of information stared for your account
              </p>
            </span>
          </div>
          <img src={right} />
        </div>

        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setTabOpen("deactivate")}
        >
          <div className="flex items-center gap-2">
            <img src={cloud} />
            <span>
              <p className="text-green text-lg">Deactive your account</p>
              <p className="text-gray-200 text-sm">Find out how you can deactive your account</p>
            </span>
          </div>
          <img src={right} />
        </div>
      </div>

      <div>
        {tabOpen === "info" && <Information />}
        {tabOpen === "password" && <Password />}
        {tabOpen === "download" && <Download />}
        {tabOpen === "deactivate" && <Deactivate />}
      </div>
    </div>
  );
};

export default Accounts;
