import { Routes, Route } from "react-router-dom";
import {
  ErrorPage,
  Appointments,
  Audiocasts,
  Trends,
  Groups,
  Events,
  Settings,
  ReferAFriend,
  DashboardHome,
  Chats,
  TherDashboard,
  TherLiveshow,
  TherLogout,
  TherSupport,
  TherRefer,
  TherGroups,
  TherChat,
  TherAudiocasts,
  TherTrends,
  TherAppointments,
  Logout,
  Survey,
  LiveShow,
  MyProfile,
  DailyQuestions,
  TherapistProfileToUsers,
  BookTherapist,
  AudiocastLive,
  ScheduleAudiocast,
  CreateLiveForm,
  LiveAudiocasts,
  // CreateLiveForm
} from "pages";
import {
  APPOINTMENTS,
  DASHBOARD,
  AUDIOCASTS,
  TRENDS,
  GROUPS,
  EVENTS,
  SETTINGS,
  REFER_A_FRIEND,
  QUIZ,
  CHATS,
  THER_DASHBOARD,
  THER_CHATS,
  THER_AUDIOCASTS,
  THER_GROUPS,
  THER_TRENDS,
  THER_APPOINTMENTS,
  THER_SETTINGS,
  THER_REFER,
  THER_LIVESHOW,
  THER_LOGOUT,
  THER_SUPPORT,
  LOGOUT,
  USER_SURVEY,
  LIVE_SHOW,
  MYPROFILE,
  DAILY_CHECK_IN_QUESTIONS,
  MY_THER,
  DAILY_CHECK_IN_QUESTIONS_HOME,
  BOOKTHERAPIST,
  AUDIOCASTLIVE,
  SCHEDULEAUDIOCAST,
  CREATELIVEFORM,
  LIVEAUDIOCASTS,
  // CREATELIVEFORM
} from "routes/CONSTANTS";

import { DailyQuestionsHome, ProtectedRoutes, PublicRoute } from "components";

import { QuizContainer } from "pages/Quiz/QuizContainer";

const RouterConfig = () => {
  return (
    <div>
      <Routes>
        {/* user dashboard routes should be placed in here */}
        <Route path={DASHBOARD} element={<DashboardHome />} />
        <Route path={APPOINTMENTS} element={<Appointments />} />
        <Route path={CHATS} element={<Chats />} />
        <Route path={LIVE_SHOW} element={<LiveShow />} />
        <Route path={AUDIOCASTS} element={<Audiocasts />} />
        <Route path={TRENDS} element={<Trends />} />
        <Route path={GROUPS} element={<Groups />} />
        <Route path={EVENTS} element={<Events />} />
        <Route path={SETTINGS} element={<Settings />} />
        <Route path={REFER_A_FRIEND} element={<ReferAFriend />} />
        <Route path={QUIZ} element={<QuizContainer />} />
        <Route path={USER_SURVEY} element={<Survey />} />
        <Route path={LOGOUT} element={<Logout />} />
        <Route path="/" element={<PublicRoute />}>
          <Route path={MYPROFILE} element={<MyProfile />} />
          <Route path={MY_THER} element={<TherapistProfileToUsers />} />
          <Route path={BOOKTHERAPIST} element={<BookTherapist />} />

          {/* Auth pages */}
        </Route>

        {/* therapist dashboard routes should be placed in here */}
        <Route path={THER_DASHBOARD} element={<TherDashboard />} />
        <Route path={THER_APPOINTMENTS} element={<TherAppointments />} />
        <Route path={THER_AUDIOCASTS} element={<TherAudiocasts />} />
        <Route path={THER_CHATS} element={<TherChat />} />
        <Route path={THER_GROUPS} element={<TherGroups />} />
        <Route path={THER_LIVESHOW} element={<TherLiveshow />} />
        <Route path={THER_LOGOUT} element={<TherLogout />} />
        <Route path={THER_REFER} element={<TherRefer />} />
        <Route path={THER_SETTINGS} element={<Settings />} />
        <Route path={THER_SUPPORT} element={<TherSupport />} />
        <Route path={THER_TRENDS} element={<TherTrends />} />

        {/* liveshow dashboard routes should be placed in here */}
        <Route path={AUDIOCASTLIVE} element={<AudiocastLive />} />
        <Route path={SCHEDULEAUDIOCAST} element={<ScheduleAudiocast />} />
        <Route path={CREATELIVEFORM} element={<CreateLiveForm />} />
        <Route path={LIVEAUDIOCASTS} element={<LiveAudiocasts />} />

        {/* Daily check in questions routes should be placed in here */}
        <Route path={DAILY_CHECK_IN_QUESTIONS_HOME} element={<DailyQuestionsHome />} />
        <Route path={DAILY_CHECK_IN_QUESTIONS} element={<DailyQuestions />} />

        <Route path="/" element={<ProtectedRoutes />}>
          {/* Protected routes should be placed in here */}
        </Route>

        {/* 404 page */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
};

export default RouterConfig;
