import DailyCheckInQuestionsView from "./DailyCheckInQuestionsView";
import { DashboardWrapper } from "components";

export const DailyCheckInQuestionsContainer = () => {
  return (
    <DashboardWrapper>
      <DailyCheckInQuestionsView />
    </DashboardWrapper>
  );
};
