import { ReactNode, useState, useEffect, useRef } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import MenuIcon from "./MenuIcon";
import { dashboardlogo } from "assets/images";

interface Props {
  children: ReactNode;
}

const DashboardWrapper = ({ children }: Props) => {
  const [isSidebarVisible, setSidebarVisible] = useState(window.innerWidth > 768);
  const [isMobileDisplay, setMobileDisplay] = useState(window.innerWidth < 768);

  const handleSidebarToggle = () => {
    setSidebarVisible((prev) => !prev); // Toggle the sidebar state
  };

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setMobileDisplay(window.innerWidth < 768);
      setSidebarVisible(window.innerWidth > 768);
    };

    const handleClickOutsideSidebar = (event: MouseEvent) => {
      if (isSidebarVisible && sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setSidebarVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("click", handleClickOutsideSidebar);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleClickOutsideSidebar);
    };
  }, [isSidebarVisible]);

  return (
    <div className="bg-[#F6F9FB] flex h-screen">
      {(!isMobileDisplay || isSidebarVisible) && (
        <div
          style={{ zIndex: 2 }}
          className={`${
            isMobileDisplay && isSidebarVisible
              ? "w-[40%] absolute inset-y-0 left-0 transform translate-x-0 transition-transform"
              : "w-[22%]"
          }`}
        >
          <DashboardSidebar />
        </div>
      )}

      <div className={`w-${isMobileDisplay ? "full" : "[78%]"} h-[100vh]`}>
        <div className={`h-[10vh] hidden md:block`}>
          <DashboardHeader />
        </div>
        <div
          className={`p-4 h-[90vh] overflow-y-auto scrollbar-thumb-[#F6F9FB] scrollbar-track-[#F6F9FB] scrollbar-thin`}
        >
          <div ref={sidebarRef} className="mb-5 pb-2 h-[10vh] border-b-[3px] border-[#EFEFEF] md:hidden flex justify-between items-center shadow-sm">
            <div className="flex">
              <img src={dashboardlogo} alt="dashboardlogo" />
            </div>
            <MenuIcon
              onClick={() => handleSidebarToggle()}
              // className={isSidebarVisible ? "menu-icon active" : "menu-icon"}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardWrapper;
