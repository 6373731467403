export const  Appointment =[
{
    id: 1,
    day : "Tue",
    date: "19",
    details: "Mental Health Class",
    duration: "09:00-10:00",
    location: "Zoom Meeting"
},
{
    id: 2,
    day : "Wed",
    date: "20",
    details: "Health Symposium",
    duration: "09:00-11:00",
    location: "Google Meet"
},
{
    id: 3,
    day : "Wed",
    date: "21",
    details: "Health discussion",
    duration: "09:00-11:00",
    location: "Google Meet"
},
{
    id: 4,
    day : "Wed",
    date: "21",
    details: "Health discussion",
    duration: "09:00-11:00",
    location: "Google Meet"
}
]