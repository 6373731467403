const Hashtags = () => {
  const Hashtag = [
    {
      id: 1,
      title: "Healing through art"
    },
    {
      id: 2,
      title: "Building Supportive Communities"
    },
    {
      id: 3,
      title: "Self-Love and Body Positivity"
    },
    {
      id: 4,
      title: "Mindfulness and Meditation"
    },
    {
      id: 5,
      title: "Self-Care for Black Mental Health"
    }
  ];
  return (
    <div>
      <div className="mb-4 p-4 bg-white border-[0.04em] border-[#E7E7E7] rounded-[8px]">
        <div className="flex justify-between items-center pb-4">
          <h3 className="font-[600] text-[#005028]">Followed Hashtags</h3>
          <button className="font-[500] text-[12px] text-[#005028]">See more</button>
        </div>
        {Hashtag.map((item) => (
          <div className="py-1" key={item.id}>
            <div className="flex items-center gap-2">
              <div className="w-[5px] h-[5px] bg-[#3C4E40] rounded-full"></div>
              <p className="font-[500] text-[14px] text-[#000]">{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hashtags;
