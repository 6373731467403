import { useState } from "react";
import useSound from "use-sound"; // for handling the sound
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai"; // icons for play and pause
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi"; // icons for next and previous track
import { IconContext } from "react-icons";
import { continueListening } from "assets/images";

const AudiocastPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [play, { pause }] = useSound("");

  const playingButton = () => {
    if (isPlaying) {
      pause(); // this will pause the audio
      setIsPlaying(false);
    } else {
      play(); // this will play the audio
      setIsPlaying(true);
    }
  };

  return (
    <div>
      <div className="bg-[#E7EEE8] rounded-[15px] py-4 flex flex-col items-center gap-4 mt-8">
        <h2 className="font-[500] text-[26px] text-[#000] ">Continue Listening</h2>
        <div className="pt-2">
          <img className="musicCover" src={continueListening} />
        </div>
        <div>
          <h3 className="text-[12px] font-[600] text-center text-[#000] w-[226px]">
            Rhythms of Resilience: Music’s Impact on Mental Health{" "}
          </h3>
          <p className="text-[12px] text-[#000] text-center"> Monet Poppett</p>
        </div>

        <div className="flex gap-2 items-center">
          <span className="time current">12:15</span>
          <input type="range" className=" accent-white bg-[#A1A1A1] h-1" />
          <span className="time">15:03</span>
        </div>
        <div>
          <button className="bg-none border-none">
            <IconContext.Provider value={{ size: "3em", color: "#005028" }}>
              <BiSkipPrevious />
            </IconContext.Provider>
          </button>
          {!isPlaying ? (
            <button className="bg-none border-none" onClick={playingButton}>
              <IconContext.Provider value={{ size: "3em", color: "#005028" }}>
                <AiFillPlayCircle />
              </IconContext.Provider>
            </button>
          ) : (
            <button className="bg-none border-none" onClick={playingButton}>
              <IconContext.Provider value={{ size: "3em", color: "#005028" }}>
                <AiFillPauseCircle />
              </IconContext.Provider>
            </button>
          )}
          <button className="bg-none border-none">
            <IconContext.Provider value={{ size: "3em", color: "#005028" }}>
              <BiSkipNext />
            </IconContext.Provider>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudiocastPlayer;
