import { DottedLine, Navigation, User } from "assets/icons";

const Referrals = () => {
  return (
    <div>
      <div>
        <h2 className="font-[700] text-[24px] text-[#3C4E40]">Referrals</h2>
        <p className="text-base text-[#3C4E40]">
          Encourage your family and friends to seek help to join Black Mental Health Matters
          platform by sending invites to them.
        </p>
      </div>
      <div className="flex justify-center py-16">
        <div className="flex items-stretch">
          <div className="w-[180px]">
            <div className="flex justify-end">
              <img src={Navigation} className="p-8 mt-4 rounded-full bg-[#E0EAE5]" />
            </div>
            <div className="py-4">
              <h3 className="font-bold text-base text-[#3C4E40] text-end">Send invitation</h3>
              <p className="text-[12px] text-[#3C4E40] text-end ">
                Send referral link to friends and tell them about the platform
              </p>
            </div>
          </div>
          <div>
            {" "}
            <img src={DottedLine} className=" px-2" />
          </div>
          <div className="w-[150px]">
            <div>
              <img src={User} className="p-8 mt-4 rounded-full bg-[#E0EAE5]" />
            </div>
            <div className="py-4">
              <h3 className="font-[700] text-base text-[#3C4E40]">Registration</h3>
              <p className="text-[12px] text-[#3C4E40]">
                Let them register using your referral link
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
