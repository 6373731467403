import React from 'react'

function Breathe() {
    return (
        <div className='bg-[#EBF2F1] flex flex-col justify-center items-center rounded-[10px] h-[300px] w-full'>
            <h1>Some Icon</h1>
            <p className="text-[24px] text-[#3C4E40] text-center ">Breathe</p>
            <p className="text-[15px] text-[#3C4E40] text-center py-2">Relax, focus and clear your mind</p>
        </div>
    )
}

export default Breathe
