export const calendarStyles= {width:"100%",
"& .MuiDayCalendar-header":{
  width: "100%",
  display:"flex",
  justifyContent:"space-between",
  paddingX: "10px",
  
},
"& .MuiPickersCalendarHeader-label" : {
fontSize:"22px",
},
"& .MuiDayCalendar-weekDayLabel ":{
  display: "inline-block",
  color: "#005028",
  fontWeight : "700"
},

"& .MuiDayCalendar-weekContainer":{
  display: "flex",
  justifyContent : "space-between",
  paddingX: "10px"
},
"& .MuiPickersArrowSwitcher-root" : {
fontSize : "22px"
},
"& .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected " : {
 bgcolor: "#005028",
 color: "#fff"

},
'& .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled:not(.Mui-selected)':{
  fontSize: "16px",
  fontWeight: "500",
  color: "#000"
},
"& .MuiTypography-root.MuiTypography-caption ": {
  fontSize :"16px",
  textTransform:"uppercase"
}}