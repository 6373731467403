interface upcomingAppointmentsProps {
  id: string;
  topic: string;
  startTime: string;
  endTime: string;
  meetingType: string;
  day: string;
  date: string;
}

const upcomingAppointments = [
  {
    id: "1",
    topic: "Mental Health Class",
    startTime: "09:00",
    endTime: "10:00",
    meetingType: "Zoom Meeting",
    day: "Tue",
    date: "JUN"
  },
  {
    id: "2",
    topic: "Mental Health Class",
    startTime: "09:00",
    endTime: "10:00",
    meetingType: "Zoom Meeting",
    day: "Tue",
    date: "JUN"
  },
  {
    id: "3",
    topic: "Session With Alex Starnes",
    startTime: "09:00",
    endTime: "10:00",
    meetingType: "Zoom Meeting",
    day: "Tue",
    date: "JUN"
  }
];

const CompletedAppointments = () => {
  return (
    <div className="p-16">
      <div className="flex flex-col gap-12">
        {upcomingAppointments.map((ap: upcomingAppointmentsProps) => (
          <div key={ap.id} className="flex items-center gap-4">
            <div className="w-16 flex flex-col">
              <p className="text-green text-lg">{ap.day}</p>
              <p className="text-green font-bold text-2xl">{ap.date}</p>
            </div>

            <div className="h-14 w-2 bg-green"></div>

            <div className="w-96">
              <p className="text-green text-3xl font-bold">{ap.topic}</p>
              <span className="text-lg flex items-center gap-2 text-green">
                <p>
                  {ap.startTime} - {ap.endTime} - {ap.meetingType}
                </p>
              </span>
            </div>

            <div className="flex flex-col items-center justify-center w-36 gap-2 pl-16">
              <div className="text-sm text-green cursor-pointer hover:text-green-100">RESCHEDULE</div>
              <div className="text-sm text-[#FF5630] cursor-pointer ">CANCEL</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompletedAppointments;
