import { DashboardWrapper } from "components";
import LiveAudiocastsView from "./LiveAudiocastsView";

export const LiveAudiocastsContainer = () => {
  return (
    <DashboardWrapper>
      <LiveAudiocastsView />
    </DashboardWrapper>
  );
};
