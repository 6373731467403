import { profilePic, upcoming1, upcoming2 } from "assets/images";

const UpcomingAudiocast = () => {
  const upcoming = [
    {
      id: 1,
      image: upcoming1,
      topic: "Channeling Your Inner Peace: ",
      title: "The Benefits of Yoga on Stress and Anxiety",
      date: "March 27th, 2023 @ 1:00pm",
      profile: profilePic,
      hosted: "Imani Mwangi"
    },
    {
      id: 2,
      image:upcoming2,
      topic: "Food For Thought: ",
      title: "Nourishing Your Mental Well-Being Through Food",
      date: "March 29th, 2023 @ 10:00am",
      profile: profilePic,
      hosted: "Dr. Melissa Johnson"
    },
    
  ];

  return (
    <div>
      <div className=" flex justify-between  items-center  pt-4">
          <h1 className="font-[500] text-[26px]">Upcoming Audiocasts</h1>
          <button className="text-[15px] text-[#005028]">show all</button>
        </div>
      <div className="grid grid-cols-2 gap-6 py-4 rounded-lg  items-center">
          {upcoming.map((item) => (
             <div className="flex flex-col items-center" key={item.id}>
             <div>
                <div><img src={item.image}/></div>
                <p className="text-[14px] py-2"><span className="font-bold">{item.topic} </span>{item.title}</p>
                <p className="font-bold text-[12px] text-[#4A4A4A] pb-2 ">{item.date}</p>
                <div className="flex gap-2 items-center">
                    <div><img src={item.profile} /></div>
                    <p className="text-[12px] text-[#4A4A4A]"><span className="font-bold text-[#000] ">Hosted By: </span><br /> {item.hosted} </p>
                </div>
             </div>
             <p className="text-[12px] font-medium">{}</p>
           </div>
          ))}
        </div>
      </div>
  );
};

export default UpcomingAudiocast;
