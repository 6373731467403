import { Checklist, GoBack, GoForward, Journal, Moodboards } from "assets/images";
import { Link } from "react-router-dom";
import { USER_SURVEY } from "routes/CONSTANTS";

// import { trendingPosts } from "utils/TrendingPosts";
const DashboardTrend = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="bg-white rounded-[10px] p-5">
        <div>
          <div className="flex items-center justify-between text-[#3c4e40] text-[24px] py-4">
            <p> Journal</p>
            <div className="flex gap-2 items-center">
              <button>
                <img src={GoBack} />
              </button>
              <button>
                <img src={GoForward} />
              </button>
            </div>
          </div>
          <div className="rounded-[12px] bg-[#fffbe8] p-4">
            <div className="flex items-center justify-between text-[#005028] ">
              <p className="#ff9c00 text-[14px]"> 20/11/2022</p>
              <button>
                <img src={Journal} className="w-[22px] h-[22px]" />
              </button>
            </div>
            <p className="text-[14px] py-2">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
              officia consequat ...
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between text-[#3c4e40] text-[24px] py-6">
            <p> Moodboards</p>
            <div className="flex gap-2 items-center">
              <button>
                <img src={GoBack} />
              </button>
              <button>
                <img src={GoForward} />
              </button>
            </div>
          </div>
          <div className="rounded-[12px]">
            <img src={Moodboards} className="w-full" />
          </div>
        </div>
        <div>
          {/* <div className="flex items-center justify-between text-[#3c4e40] text-[24px] py-6">
            <p> Trending Posts</p>
            <div className="flex gap-2 items-center">
              <button>
                <img src={GoBack} />
              </button>
              <button>
                <img src={GoForward} />
              </button>
            </div>
          </div> */}
          {/* <div className="rounded-[12px] bg-[#eef3f7] p-4">
          {trendingPosts.slice(0, 1).map((post, id) => (
            <>
              <div key={id} className="flex items-center gap-2 text-[#005028] ">
                <img src={TrendingPost} className="w-[60px] h-[60px]" />
                <div>
                  <div className="flex items-center gap-2">
                    <p className="text-[#667085] text-[14px]"> {post.author}</p>
                    <img src={dashCertified} />
                  </div>
                  <p className="text-[#707070] text-[14px]">{post.timePosted}</p>
                </div>
              </div>
              <p className="text-[14px] text-[#3c4e40] py-2">
                {post.details.slice(0, 200) + "..."}
              </p>
            </>
          ))}
        </div> */}
        </div>
      </div>
      <div className="px-8 pt-5 bg-[#E9EFEC] rounded-[12px]">
        <p className="text-[20px] text-[#3C4E40] font-[600]">Find Your Perfect Therapist</p>
        <p className="text-base text-[#3C4E40] min-w-236px h-[50px]">
          Begin your survey now and unlock the path to personalized mental health support!
        </p>
        <div className="flex justify-between gap-2 items-center ">
          <div>
        <Link to={USER_SURVEY} className="bg-[#FCBD5B] hover:bg-orange text-base font-medium px-[10px] py-[9px] rounded-md shadow-md">
          Take Survey
        </Link>
          </div>
        <img src={Checklist} />
        </div>
      </div>
    </div>
  );
};

export default DashboardTrend;
