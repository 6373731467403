import SurveyForm from "./SurveyForm";

const Survey = () => {
  return (
    <div>
      <div className="flex flex-col gap-2 items-center justify-center">
        <p className="text-green text-4xl font-bold">Mental Health Professional Matching Survey</p>
        <p className="text-black text-lg text-center">
          By completing this survey, you will be matched to therapists that may be a good fit for
          you and your mental health needs. If you do not wish to complete the survey, feel free to
          browse our lists of trained professionals.
        </p>
      </div>
      <SurveyForm />
    </div>
  );
};

export default Survey;
