import PageNotFound from "components/layout/ErrorPage/PageNotFound";

const ErrorPageView = () => {
  return (
    <div>
      <PageNotFound />
    </div>
  );
};

export default ErrorPageView;
