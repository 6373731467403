export function getDate () {
    // Wednesday, March 1, 2023
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; 
    const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date().getDate()
    const day = dayNames[new Date().getDay()]
    const month = new Date().getMonth()
    const year = new Date().getFullYear()
    const realMonth = monthArr[month]

    return `${day}, ${realMonth} ${date}, ${year}`;
    
}