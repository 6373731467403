import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const OnboardingLayout = ({ children }: Props) => {
  return (
    <div className="bg-white w-full shadow-lg rounded-3xl p-5">
      <div className="p-8">{children}</div>
    </div>
  );
};

export default OnboardingLayout;
