import { Survey } from "components";

const SurveyView = () => {
  return (
    <div>
      <Survey />
    </div>
  );
};

export default SurveyView;
