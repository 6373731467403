import { Moodboard } from "assets/images";
import { Link } from "react-router-dom";
import { MY_THER } from "routes/CONSTANTS";

const MoodBoard = () => {
  return (
    <Link to={MY_THER}>
      <div className="bg-[#EDF9FE] rounded-[10px] lg:min-w-[258px] min-h-[110px] cursor-pointer">
        <div>
          <div className="flex justify-center items-center gap-2 py-4 ">
            <img src={Moodboard} />
            <p className="text-[24px] text-[#3C4E40] text-center ">Moodboard</p>
          </div>
          <p className="text-[15px] text-[#3C4E40] text-center">
            Book an appointment with our Professional Therapists
          </p>
        </div>
      </div>
    </Link>
  );
};

export default MoodBoard;
