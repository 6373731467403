import { TherapistDashboardWrapper } from "components";
import TherChatView from "./TherChatView";

export const TherChatContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherChatView />
    </TherapistDashboardWrapper>
  );
};
