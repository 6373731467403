import { ResponsiveDashboardWrapper } from "components";
import TherProfileViewToUsers from "./TherProfileToUsersView";

export const TherProfileContainer = () => {
  return (
    <ResponsiveDashboardWrapper>
      <TherProfileViewToUsers />
    </ResponsiveDashboardWrapper>
  );
};
