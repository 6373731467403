import { LiveShow } from "components";

const LiveShowView = () => {
  return (
    <div>
      <LiveShow />
    </div>
  );
};

export default LiveShowView;
