import AudiocastHeader from "./AudiocastHeader";
import AudiocastPlayer from "./AudiocastPlayer";
import TopHost from "./TopHost";
import TrendingAudiocasts from "./TrendingAudiocasts";
import UpcomingAudiocast from "./UpcomingAudiocast";

const AudioCards = () => {

  return (
    <div>
<div className="lg:flex gap-12">
      <div className="basis-[60%]" >
        <AudiocastHeader />
        <TrendingAudiocasts />
        <UpcomingAudiocast />
      </div>

        <div className="basis-[35%]">
          <TopHost/>
          <AudiocastPlayer />
        </div>
      </div>
      
    </div>
  );
};

export default AudioCards;
