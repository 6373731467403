import { TherapistDashboardWrapper } from "components";
import TherDashboardView from "./TherDashboardView";

export const TherDashboardContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherDashboardView />
    </TherapistDashboardWrapper>
  );
};
