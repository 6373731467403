import { dashboardlogo } from "assets/images";
import { Link, useLocation } from "react-router-dom";

import {
  DASHBOARD,
  APPOINTMENTS,
  AUDIOCASTS,
  TRENDS,
  CHATS,
  GROUPS,
  EVENTS,
  REFER_A_FRIEND,
  SETTINGS,
  LOGOUT,
  LIVE_SHOW
} from "routes/CONSTANTS";
import {
  BMappointments,
  BMaudiocasts,
  BMevents,
  BMgroups,
  BMhome,
  BMrefer,
  BMsettings,
  BMtrends,
  BMchats,
  BMlogout
} from "./icons";
import BMliveshow from "./icons/BMliveshow";
import { Quiz } from "components/layout";

const sidebar = [
  {
    Icon: BMhome,
    name: "Home",
    to: DASHBOARD
  },
  {
    Icon: BMappointments,
    name: "Appointments",
    to: APPOINTMENTS
  },
  {
    Icon: BMchats,
    name: "Chats",
    to: CHATS
  },
  {
    Icon: BMliveshow,
    name: "Liveshow",
    to: LIVE_SHOW
  },
  {
    Icon: BMaudiocasts,
    name: "Audio casts",
    to: AUDIOCASTS
  },
  {
    Icon: BMtrends,
    name: "Trends",
    to: TRENDS
  },
  {
    Icon: BMgroups,
    name: "Groups",
    to: GROUPS
  },
  {
    Icon: BMevents,
    name: "Events",
    to: EVENTS
  },
  {
    Icon: BMrefer,
    name: "Refer a Friend",
    to: REFER_A_FRIEND
  },
  {
    Icon: BMsettings,
    name: "Settings",
    to: SETTINGS
  },
  {
    Icon: BMlogout,
    name: "Logout",
    to: LOGOUT
  }
];

const DashboardSidebar = () => {
  const { pathname } = useLocation();

  return (
    <div className="md:pt-4 px-3 md:px-8 flex flex-col gap-4 md:gap-6 h-screen bg-white shadow-lg">
      <div className="">
        <img src={dashboardlogo} alt="dashboardlogo" />
      </div>
      {sidebar.map(({ Icon, name, to }, key) => (
        <Link
          key={key}
          to={to}
          className={`${
            pathname === to
              ? "rounded-md text-orange  flex items-center gap-2"
              : "text-green  flex items-center gap-2"
          } flex items-center p-[5px] my-[-8px] md:my-[-14px] transition-all duration-300 hover:text-orange  `}
        >
          <div className="w-5">
            <Icon size={16} />
          </div>
          <div className="w-full">
            <span className="text-base">{name}</span>
          </div>
        </Link>
      ))}

      <div className="flex justify-center items-center">
        <Quiz />
      </div>
    </div>
  );
};

export default DashboardSidebar;
