/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ArrowRight, AudioGuest, AudioPrivate, AudioPublic, AudioTime, Info } from "assets/icons";
import { Card1, Card2, Card3 } from "assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Anxiety = () => {
  const AudioAnxiety = [
    {
      id: 1,
      image: Card1,
      title: "Mind Over Matter: Coping With Anxiety",
      hosts: "Zoe Brooks",
      speakers: " Amara Onyango",
      schedule: "11:00AM EST",
      state: "Public",
      guest: 12,
      time: "1hr"
    },
    {
      id: 2,
      image: Card2,
      title: "Anxiety and Sleep: Restoring Harmony",
      hosts: "Ava Wilson, Maya Roberts, Olivia Mitchell ",
      speakers: " Maya Mbeki, Jabari Akintola, Samuel Brown",
      schedule: "1:00PM EST",
      state: "Private",
      guest: 3,
      time: "45m"
    },
    {
      id: 3,
      image: Card3,
      title: "Breaking Free: Overcoming Your Fears",
      hosts: "Linda Roberts",
      speakers: "  Joe Hutchins, Leroy Peaks",
      schedule: "9:00AM EST",
      state: "Private",
      guest: 27,
      time: "1hr15m"
    },
    {
      id: 4,
      image: Card1,
      title: "Mind Over Matter: Coping With Anxiety",
      hosts: "Zoe Brooks",
      speakers: " Amara Onyango",
      schedule: "11:00AM EST",
      state: "Public",
      guest: 12,
      time: "1hr"
    },
    {
      id: 5,
      image: Card2,
      title: "Anxiety and Sleep: Restoring Harmony",
      hosts: "Ava Wilson, Maya Roberts, Olivia Mitchell ",
      speakers: " Maya Mbeki, Jabari Akintola, Samuel Brown",
      schedule: "1:00PM EST",
      state: "Private",
      guest: 3,
      time: "45m"
    },
    {
      id: 6,
      image: Card3,
      title: "Breaking Free: Overcoming Your Fears",
      hosts: "Linda Roberts",
      speakers: "  Joe Hutchins, Leroy Peaks",
      schedule: "9:00AM EST",
      state: "Private",
      guest: 27,
      time: "1hr15m"
    }
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div>
      <p className="pb-2 text-[18px] font-[600]">
        Anxiety <span>(8)</span>
      </p>
      <Carousel
        showDots={true}
        arrows={true}
        swipeable
        draggable
        slidesToSlide={1}
        responsive={responsive}
        dotListClass=""
        containerClass="py-12"
      >
        {AudioAnxiety.map((item) => (
          <div
            className="max-w-[285px] rounded-[5px] bg-[#FFF] shadow-lg shadow-[#00000040]"
            key={item.id}
          >
            <div>
              <div
                className=" bg-center bg-no-repeat bg-cover rounded-tr-[5px] rounded-tl-[5px] flex flex-col justify-center h-[120px] "
                style={{ backgroundImage: `url(${item.image})` }}
              >
                <div className="flex justify-end pt-4 pr-4 ">
                  <button>
                    <img src={Info} />
                  </button>
                </div>
                <div className=" justify-center items-center p-4 w-full">
                  <p className="font-[600] text-[18px] text-[#FFF]">{item.title}</p>
                </div>
              </div>
              <div className="p-4">
                <div className="font-[500] text-[10px]">
                  <p>
                    <span className="text-[#005028]">Hosts:</span>
                    {item.hosts}
                  </p>
                  <p>
                    <span className="text-[#005028]">Speakers:</span> {item.speakers}
                  </p>
                  <p>
                    <span className="text-[#005028]">Scheduled Time:</span> {item.schedule}
                  </p>
                </div>
                <div className="flex items-center justify-between pt-8 ">
                  <div className="flex items-center gap-2 ">
                    <div className="flex items-center gap-2 ">
                      <div>
                        <img src={item.state === "Public" ? AudioPublic : AudioPrivate} />
                      </div>
                      <p className="text-[10px]">
                        {item.state === "Public" ? "Public" : "Private"}
                      </p>
                    </div>
                    <div className="flex items-center gap-2 ">
                      <div>
                        <img src={AudioGuest} />
                      </div>
                      <p className="text-[10px]">{item.guest} Guests</p>
                    </div>
                    <div className="flex items-center gap-2 ">
                      <div>
                        <img src={AudioTime} />
                      </div>
                      <p className="text-[10px]">{item.time}</p>
                    </div>
                  </div>
                  <div>
                    <button className="bg-[#005028] hover:bg-green-100 font-[500] text-[10px] text-[#FFF] py-1 px-2 flex items-center gap-2 rounded-[5px]">
                      <p>Join</p>
                      <img src={ArrowRight} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Anxiety;
