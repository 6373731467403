import { useState } from "react";
import ChatStatus from "./ChatStatus";
import ChatSession from "./ChatSession";
import ChatHeader from "./ChatHeader";
import { TrendingPost } from "assets/images";

export interface User {
  id: string;
  name: string;
  image?: string;
  status?: string;
  chats?: string;
}

interface Message {
  id: string;
  senderId: string;
  receiverId: string;
  content: string;
  time: Date;
}

const users: User[] = [
  { id: "1", name: "Sandra M.", image: TrendingPost, status: "Active", chats: "2" },
  { id: "2", name: "Jane", image: TrendingPost, status: "Offline" },
  { id: "3", name: "Bob", image: TrendingPost, status: "Offline" },
  { id: "4", name: "James", image: TrendingPost, status: "Offline" },
  { id: "5", name: "Debby", image: TrendingPost, status: "Offline" },
  { id: "6", name: "Bobby", image: TrendingPost, status: "Offline" },
  { id: "7", name: "Sandy", image: TrendingPost, status: "Offline" },
  { id: "8", name: "Ariel", image: TrendingPost, status: "Offline" }
];

const messages: Message[] = [
  {
    id: "1",
    senderId: "1",
    receiverId: "2",
    content: "Hi Jane, how are you?",
    time: new Date()
  },
  {
    id: "2",
    senderId: "2",
    receiverId: "1",
    content: "I'm doing well, thanks. How about you?",
    time: new Date()
  },
  {
    id: "1",
    senderId: "1",
    receiverId: "2",
    content: "Hi Jane, how are you?",
    time: new Date()
  },

  {
    id: "1",
    senderId: "1",
    receiverId: "2",
    content: "Hi Jane, how are you?",
    time: new Date()
  },
  {
    id: "1",
    senderId: "1",
    receiverId: "2",
    content: "I'm doing well, thanks. How about you?",
    time: new Date()
  }
];

const Chat = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedName, setSelectedName] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const [newMessage, setNewMessage] = useState<string>("");

  const handleUserSelect = (user: User) => {
    setSelectedStatus(user.status);
    setSelectedUserId(user.id);
    setSelectedName(user.name);
    setSelectedImage(user.image);
  };

  const handleNewMessageChange = (value: string) => {
    setNewMessage(value);
  };

  const handleSendMessage = () => {
    const message: Message = {
      id: `${messages.length + 1}`,
      senderId: "1",
      receiverId: selectedUserId ?? "",
      content: newMessage,
      time: new Date()
    };
    setNewMessage("");
    messages.push(message);
  };

  return (
    <div className="flex flex-col sm:flex-row gap-6 h-full">
      <ChatStatus users={users} onUserSelect={handleUserSelect} selectedUserId={selectedUserId} />

      <div className="sm:basis-[72%] relative bg-[#FFFBE8] rounded-[20px] px-8 ">
        <ChatHeader
          id={selectedUserId}
          name={selectedName}
          status={selectedStatus}
          image={selectedImage}
        />
        <ChatSession
          status={selectedStatus}
          image={selectedImage}
          name={selectedName}
          messages={messages}
          selectedUserId={selectedUserId}
          newMessage={newMessage}
          onNewMessageChange={handleNewMessageChange}
          onSendMessage={handleSendMessage}
        />
      </div>
    </div>
  );
};

export default Chat;
