interface SelectInputProps {
  nameAndId: string;
  label: string | string[];
  value: string;
  options: string[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  required?: boolean;
}

function SelectInput({
  options,
  nameAndId,
  label,
  value,
  onChange,
  className,
  required = true
}: SelectInputProps) {
  return (
    <div className="my-3">
      {Array.isArray(label) ? (
        <>
          <span>{label[0]}</span>
          <span className="text-[#FF5630]">{label[1]}</span>{" "}
        </>
      ) : (
        <span>{label}</span>
      )}
      <select
        name={nameAndId}
        id={nameAndId}
        value={value}
        onChange={onChange}
        className={className ?? `w-full mt-2 p-2 h-11 text-sm border border-green rounded-lg`}
        required={required}
        style={{outline: "none"}}
      >
        <option className="" value="" disabled>
          Select {Array.isArray(label) ? label[0].toLowerCase() : label.toLowerCase()}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectInput;
