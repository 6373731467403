import { DashboardWrapper } from "components";
import DashboardHomeView from "./DashboardHomeView";

export const DashboardHomeContainer = () => {
  return (
    <DashboardWrapper>
      <DashboardHomeView />
    </DashboardWrapper>
  );
};
