import { TrendingPost } from "assets/images"
export const Message = [
    {
        sender : "Sandra M.",
        count : 3,
        image: TrendingPost
    },
    {
        sender : "Sandra M.",
        count : 2,
        image: TrendingPost
    },
    {
        sender : "Sandra M.",
        count : 5,
        image: TrendingPost
    }
]