
import { TrendingPost } from "assets/images";
import BMchats from "./icons/BMchats"
interface ProviderProps {
    name : string
}
function Provider({ name}: ProviderProps) {
    return (
        <div className="flex justify-between items-center bg-white shadow-lg mb-3 px-2 py-2 w-full rounded-[10px]">
        <div className="flex gap-2 items-center">
          <div className="w-[35px] relative ">
            <img src={TrendingPost} className="w-full" alt="Therapist's image" />
            <span className="bg-[#00A552] left-[26px] bottom-[2px] block w-[10px] h-[10px] rounded-full absolute"></span>
          </div>
          <p className="text-[15px] text-[#3C4E40]">{name}</p>
        </div>
    <BMchats/>
      </div>
    );
    
}

export default Provider
