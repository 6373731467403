import { Tega } from "assets/images";

const GroupAdmin = () => {
  return (
    <div>
      <div className="bg-[#F6F9FB] h-[447px] p-4 rounded-[8px]">
        <h4 className="font-bold text-[24px] text-[#4A4A4A]">Admin</h4>
        <div className="flex gap-2 items-start py-4">
          <img src={Tega} />
          <div>
            <h4 className="font-bold text-[34px] text-[#005028]">Tega Uwadia</h4>
            <p className="text-[#667085] bg-[#D8E2F7] py-1 px-2 w-[101px] text-[24px] font-bold">
              Owner
            </p>
            <p className="text-[16px] text-[#667085] text-justify py-2">
              A professional Therapist. With over 10 years of experience. faucibus quisque egestas
              laoreet turpis scelerisque quis semper. Viverra purus at mattis purus tempus Elit
              pellentesque hendrerit sed greyyuu hoduy polaturiu jshjskksj hhssu shhst.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupAdmin;
