import { greenArrowRight } from "assets/icons";
import { format } from "date-fns";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "./Calendar";
import { Input, SelectInput, Checkbox, RadioButton } from "components/modules/FormModules";
import SuccessStatus from "./SuccessStatus";
import "./BookAppointment.css";

const BookAppointment = () => {
  const navigate = useNavigate();
  const locations = ["Physical/In-Person", "Virtual/Online", "Home Visit"];
  const services = ["Individual", "Couples", "Family", "Child", "Adolescent/Team", "Group"];
  const timeOptions = ["8:00AM", "10:00AM", "1:00PM", "3:00PM", "5:00PM"];
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [submitState, setSubmitState] = useState(false);
  const initialFormState = {
    fullName: "",
    email: "",
    phoneNo: "",
    preferredLocation: "",
    preferredService: "",
    reasonForTherapy: "",
    emailSignUp: false,
    date: selectedDate,
    time: ""
  };
  const [formState, setFormState] = useState(initialFormState);

  const handleAnswerChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, type } = event.target;
    let newValue: string | boolean;

    if (type === "checkbox") {
      const { checked } = event.target as HTMLInputElement;
      newValue = checked;
    } else {
      const { value } = event.target;
      newValue = value;
    }

    setFormState({
      ...formState,
      [name]: newValue
    });
  };

  const handleDateChange = (date?: Date | null) => {
    if (date) {
      const formattedDate = format(date, "do MMMM, yyyy");
      setSelectedDate(formattedDate);
      setFormState({
        ...formState,
        date: formattedDate
      });
    }
  };

  const handleTimeChange = (time: string) => {
    setSelectedTime(time || "");
    setFormState({
      ...formState,
      time: time || ""
    });
  };

  const handleSubmit = () => {
    event?.preventDefault();
    console.log(formState);
    setSubmitState(true);
    // setFormState(initialFormState);
  };

  const handlePrevious = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="my-4 flex items-center gap-3">
        <button onClick={handlePrevious}>
          <img className="rotate-180" src={greenArrowRight} alt="Backward Arrow" />
        </button>
        <span className="font-bold">Book an Appointment</span>
      </div>
      <div className={`${submitState ? "overlay block" : "hidden"}`}>
        <SuccessStatus
          className={`${submitState ? "block" : "hidden"}`}
          status={!!selectedDate && !!selectedTime}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <Input
          nameAndId="fullName"
          label={["Full Name", "*"]}
          placeholder="Full name"
          onChange={handleAnswerChange}
          value={formState.fullName}
        />

        <Input
          nameAndId="email"
          label={["Email Address", "*"]}
          placeholder="Email Address"
          onChange={handleAnswerChange}
          value={formState.email}
          type="email"
        />

        <Input
          nameAndId="phoneNo"
          label={["Phone Number", "*"]}
          placeholder="Phone Number"
          onChange={handleAnswerChange}
          value={formState.phoneNo}
          type="number"
        />

        <SelectInput
          options={locations}
          nameAndId="preferredLocation"
          label="Preferred Location"
          value={formState.preferredLocation}
          onChange={handleAnswerChange}
          required={false}
        />

        <SelectInput
          options={services}
          nameAndId="preferredService"
          label={["Preferred Service", "*"]}
          value={formState.preferredService}
          onChange={handleAnswerChange}
        />

        <Input
          nameAndId="reasonForTherapy"
          label={["Reason for Therapy", "*"]}
          placeholder="Brief description of what you would like to discuss about"
          onChange={handleAnswerChange}
          value={formState.reasonForTherapy}
          field="textarea"
        />
        <Checkbox
          nameAndId="emailSignUp"
          label="Send me a confirmation email"
          checked={formState.emailSignUp}
          onChange={handleAnswerChange}
        />
        <div className="w-full p-5 lg:py-10 lg:px-20 text-white bg-green rounded-lg flex flex-col">
          <label className="text-center lg:text-xl">
            Select a date and time to book appointment
          </label>
          <div
            className={`w-full lg:px-5  mt-5 p-5 bg-white rounded-lg md:grid grid-cols-1 md:grid-cols-2 md:gap-20 lg:gap-10 items-center`}
          >
            <Calendar className="" onDateSelect={handleDateChange} />
            <RadioButton
              label={selectedDate ? `Available time for ${selectedDate}` : "Available time today"}
              options={timeOptions}
              selectedOption={selectedTime}
              onOptionChange={handleTimeChange}
            />
          </div>
          <div className="mt-6 text-sm flex flex-col md:flex-row gap-5 md:gap-20">
            <div>
              <h3>Live sessions via</h3>
              <ul className="mt-3 list-disc ml-6">
                <li className="my-2">Video Call - zoon video session</li>
                <li className="my-2">Video Call</li>
              </ul>
            </div>
            <div>
              <h3>Rate</h3>
              <ul className="mt-3 list-disc ml-6">
                <li className="my-2">$70/session</li>
              </ul>
            </div>
          </div>
        </div>
        <button className="w-full my-3 py-3 text-white rounded-md bg-[#FF9C00]" type="submit">
          Book Appointment Now
        </button>
      </form>
    </div>
  );
};

export default BookAppointment;
