import MySchedule from "./MySchedule";
import MyTherapists from "./MyTherapists";
import Overview from "./Overview";
import { useState } from "react";

const MyProfileDetails = () => {
  const [tabOpen, setTabOpen] = useState("overview");

  return (
    <div className="pl-12">
      <div className="flex flex-col md:flex-row gap-4 lg:gap-8">
        <div className="flex flex-col md:flex-row gap-4 lg:gap-8">
          <span
            className={
              tabOpen === "overview"
                ? " border-b-4 border-orange font-semibold text-green text-sm"
                : "text-sm font-semibold text-green cursor-pointer"
            }
            onClick={() => setTabOpen("overview")}
          >
            Overview
          </span>
          <span
            className={
              tabOpen === "schedule"
                ? " border-b-4 border-orange font-semibold text-green text-sm"
                : "text-sm font-semibold text-green cursor-pointer"
            }
            onClick={() => setTabOpen("schedule")}
          >
            My Schedule
          </span>
          <span
            className={
              tabOpen === "therapists"
                ? " border-b-4 border-orange font-semibold text-green text-sm"
                : "text-sm font-semibold text-green cursor-pointer"
            }
            onClick={() => setTabOpen("therapists")}
          >
            My Therapists
          </span>
        </div>
        <hr className="h-[0.1px] bg-[#B4B4B4] border-[0.5px]" />
      </div>
      {tabOpen === "overview" && <Overview />}
      {tabOpen === "schedule" && <MySchedule />}
      {tabOpen === "therapists" && <MyTherapists />}
    </div>
  );
};

export default MyProfileDetails;
