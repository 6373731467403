import { LiveAudiocasts} from "components";


const LiveAudiocastsView = () => {
  return (
    <div>
      <LiveAudiocasts />
    </div>
  );
};

export default LiveAudiocastsView;