import { MarkedMessage, Report, SharedMedia, ZoomIn, active, offline } from "assets/icons";
import { getDate } from "utils/helper";


const ChatHeader = ({id, name, status, image}: any) => {
 
  
  return (
    <div>
      <div className="lg:flex items-center gap-3 text-[#005028] pl-4 " key={id}>
        <div className="relative">
          <img src={image} />
          <img src={status === "Active" ? active : offline} className=" absolute lg:-right-1 left-10 -bottom-1" />
        </div>
        <div>
          <div className="lg:flex gap-8 items-center">
            <div className="py-4">
              
              <p className="text-[#667085] text-[24px] pl-2">{name}</p>
              <p className="text-[#707070] text-[16px] pl-2">{status}</p>
            </div>
            <p className="text-[#3C4E40] text-[16px] lg:w-[420px] ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-4  justify-center justify-items-center py-2 lg:pl-20">
        <div className="flex items-center gap-3">
          <img src={Report} />
          <p className="text-[14px]">Report</p>
        </div>
        <div className="flex items-center gap-3">
          <img src={MarkedMessage} />
          <p className="text-[14px]">Marked message</p>
        </div>
        <div className="flex items-center gap-3">
          <img src={SharedMedia} />
          <p className="text-[14px]">Shared media</p>
        </div>
        <div className="flex items-center gap-3">
          <img src={ZoomIn} />
          <p className="text-[14px]">Search</p>
        </div>
      </div>
      <div className="flex items-center px-8 py-2 gap-4">
        <span className="bg-[#C4C4C4] w-[150px] h-[1px]"></span>
        <span className="text-[16px] text-[#667085]">{getDate()}</span>
        <span className="bg-[#C4C4C4] w-[150px] h-[1px]"></span>
      </div>
    </div>
  );
};

export default ChatHeader;
