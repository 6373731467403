import { greenArrowRight, longArrowRight } from "assets/icons";
import { DailyQuestionsImage } from "assets/images";
import { format } from "date-fns";
import { DAILY_CHECK_IN_QUESTIONS } from "routes/CONSTANTS";
import { Link, useNavigate } from "react-router-dom";

const DailyQuestionsHome = () => {
  const now = new Date();
  const formattedDate = format(now, "eeee, do 'of' MMMM");
  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate(-1);
  };

  return (
    <div className="py-4 px-8">
      <div>
        <button
          className="flex gap-2 items-center text-green text-base font-bold hover:underline rounded-[4px]  mb-6"
          onClick={handlePrevious}
        >
          <img className="rotate-180" src={greenArrowRight} alt="Arrow Left Image" />
          {`Back`}
        </button>
      </div>

      <div className="h-[40vh] mt-20 flex justify-center">
        <div className="w-[36%]">
          <img src={DailyQuestionsImage} alt="A fancy image illustrating mental health" />
        </div>

        <div className="w-[40%] h-[40vh] ml-8 flex flex-col justify-end">
          <div>
            <p>{formattedDate}</p>
            <h3 className="text-2xl font-bold my-3">Mental Health Daily Check-in</h3>
            <p className="font-bold my-3">Just 10 Questions to Care For Yourself</p>
          </div>
          <div>
            <Link to={DAILY_CHECK_IN_QUESTIONS}>
              <button className="px-6 py-3 my-2 flex gap-2 items-center text-white text-base bg-green-100 hover:bg-green rounded-full ">
                {`Check-in Now`}
                <img src={longArrowRight} alt="Arrow Left Image" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyQuestionsHome;
