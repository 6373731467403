import { audioCast } from "assets/images";
import { Link } from "react-router-dom";
import { AUDIOCASTS } from "routes/CONSTANTS";
const Audiocast = () => {
  return (
    <Link
      to={AUDIOCASTS}
      className="bg-[#EBF2F1] rounded-[10px] lg:min-w-[258px] min-h-[110px] cursor-pointer p-2"
    >
      <div>
        <div className="flex justify-center items-center gap-2 py-4 ">
          <img src={audioCast} />
        <p className="text-[24px] text-[#3C4E40] text-center ">Audio Casts</p>
        </div>
        <p className="text-[15px] text-[#3C4E40] text-center">
          What’s Happening? You can write about it.
        </p>
      </div>
    </Link>
  );
};

export default Audiocast;
