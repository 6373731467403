import { useState } from "react";
import { format } from "date-fns";
import { Star, GoForward } from "assets/images";
import { Therapist } from "./ProfileSummary";

export interface ReviewsObject {
  name: string;
  date: string | Date;
  rating: number;
  text: string;
}

interface Props {
  therapist: Therapist;
  submittedReview: ReviewsObject | null;
}

const DisplayReviews = ({ therapist, submittedReview }: Props) => {
  const { name, reviewsArray } = therapist;
  const [fetchedReviews, setFetchedReviews] = useState(2);

  const consolidatedReviewsArray = [...reviewsArray, submittedReview].filter(
    (reviewObject: ReviewsObject | null): reviewObject is ReviewsObject => reviewObject !== null
  );

  const handleLoadMoreReviews = () => {
    setFetchedReviews((prevFetchedReviews) =>
      prevFetchedReviews < reviewsArray.length ? prevFetchedReviews + 2 : 2
    );
  };

  const formatDate = (date: string | Date) => {
    const formattedDate = format(new Date(date), "MMM dd, yyyy");
    return formattedDate;
  };

  return (
    <section className="my-5">
      <h2 className="text-black font-semibold mb-8">{`What clients say about ${name}`}</h2>
      <div className="p-10 md:p-0 grid md:grid-cols-2 gap-7">
        {consolidatedReviewsArray
          .slice(fetchedReviews - 2, fetchedReviews)
          .map((reviewObject, index) => (
            <div
              className="px-5 py-5  lg:px-5 min-h-[205px] bg-white rounded-b-xl shadow-lg border border-t-0 border-3 border-[#F8F8F8] text-xs text-[#4A4A4A] leading-[28px] tracking-[0.1px]"
              key={index}
            >
              <div className="flex justify-between">
                <h3 className="">{reviewObject.name}</h3>
                <div className="flex items-center gap-1">
                  <img className="w-[16px] h-[16px]" src={Star} alt="Rating Icon" />
                  <p>{reviewObject.rating.toFixed(1)}</p>
                </div>
              </div>
              <p>{formatDate(reviewObject.date)}</p>
              <p>{reviewObject.text}</p>
            </div>
          ))}
        {consolidatedReviewsArray.length >= 2 ? (
          <button
            className="ml-auto w-[30px] h-[30px] bg-white border-4 border-[#F8F8F8] flex items-center justify-center rounded-full shadow-lg hover:transfor hover:scale-125 transition-all duration-300"
            onClick={() => handleLoadMoreReviews()}
          >
            <img className="w-[7px] h-[14px]" src={GoForward} alt="Forward Arrow Image" />
          </button>
        ) : null}
      </div>
      <div className="w-[90%] flex items-center justify-center mt-4">
        {[...Array(Math.ceil(therapist.reviewsArray.length / 2))].map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 border border-1 border-[#C4C4C4] rounded-full mx-1 ${
              fetchedReviews === index * 2 + 2 ? "bg-[#FF9C00]" : "bg-white"
            }`}
          ></div>
        ))}
      </div>
    </section>
  );
};

export default DisplayReviews;
