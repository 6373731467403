import type { ReactNode } from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";

interface Props {
  children: ReactNode;
}

const DashboardWrapper = ({ children }: Props) => {
  return (
    <div className="bg-[#F6F9FB] flex h-screen ">
      <div className="w-[20%]">
        <DashboardSidebar />
      </div>

      <div className="w-[80%] h-[100vh]">
        <div className="h-[10vh]">
          <DashboardHeader />
        </div>
        <div className="p-8 h-[90vh] overflow-y-auto scrollbar-thumb-[#F6F9FB] scrollbar-track-[#F6F9FB] scrollbar-thin">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardWrapper;
