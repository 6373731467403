import { Therapist } from "./ProfileSummary";
import { VscVerifiedFilled } from "react-icons/vsc";

interface Props {
  isVerified: boolean;
  setHoverState: () => void;
  therapist: Therapist;
  className?: string;
}

const LicenseStatus = ({ isVerified, className, therapist, setHoverState }: Props) => {
  const { name } = therapist;
  const content = {
    // icon: NegativeIcon,
    firstVerifiedHeader: "License Type",
    firstVerifiedParagraph: "Licensed Associate Professional Counsellor (LAPC)",
    secondVerifiedHeader: "License to practice Verified",
    secondVerifiedParagraph: `${name} is licensed to practice in United States and has been verified by the 
    Georgia Composite Board and BMHM as meeting the rigorous standards for 
    professional excellence and ethical conduct. He is dedicated to providing mental 
    health services, high-quality care to clients and helping them achieve their 
    therapeutic goals.`,
    firstUnverifiedParagraph: "The License to practice not verified by BMHM",
    secondUnverifiedParagraph: `${name} does not have a verified license from BMHM to practice`
  };

  let contentMarkup;

  if (isVerified) {
    contentMarkup = (
      <div className="">
        <div className="text-green-100 font-bold mb-2">{content.firstVerifiedHeader}</div>
        <div className="mb-5 text-sm text-[#3C4E40]">{content.firstVerifiedParagraph}</div>
        <div className="text-green-100 font-bold mb-2">{content.secondVerifiedHeader}</div>
        <div className="mb-5 text-sm text-[#3C4E40] flex gap-3 items-start leading-[25px] sm:leading-[30px] tracking-wide">
          <div>
            <VscVerifiedFilled size={28} color="#00A552" />
          </div>
          <div>{content.secondVerifiedParagraph}</div>
        </div>
      </div>
    );
  } else {
    contentMarkup = (
      <div className="text-[#3C4E40] flex flex-col items-center gap-8">
        <div className="w-[50px] h-[50px]">
          {/* <img src={content.icon} alt="A warning Icon" /> */}
        </div>
        <div className="">{content.firstUnverifiedParagraph}</div>
        <div className="">{content.secondUnverifiedParagraph}</div>
      </div>
    );
  }

  return (
    <div
      className={`w-[80%] p-2 lg:w-[60%] md:pl-3 lg:pl-10 lg:pr-5 lg:py-10 bg-white rounded-xl ${
        className ?? ""
      }`}
    >
      <div
        style={{ cursor: "pointer" }}
        className="lg:mt-[-2em] text-[#FF5630] flex justify-end"
        onClick={setHoverState}
      >
        <span className="text-3xl shadow-sm">&times;</span>
      </div>
      {contentMarkup}
    </div>
  );
};

export default LicenseStatus;
