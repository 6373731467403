import { ResponsiveDashboardWrapper } from "components";
import BookAppointmentView from "./BookAppointmentView";

const BookAppointmentContainer = () => {
  return (
    <ResponsiveDashboardWrapper>
        <BookAppointmentView />
    </ResponsiveDashboardWrapper>
  )
}

export default BookAppointmentContainer