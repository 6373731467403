import { cloud, lock, right } from "assets/icons";
import { useState } from "react";
import Security from "./Security/Security";
import ConnectedAccounts from "./ConnectedAccounts/ConnectedAccounts";

const Access = () => {
  const [tabOpen, setTabOpen] = useState("");
  return (
    <div className="">
      <div
        className={`flex flex-col gap-12 ${
          tabOpen === "security" || tabOpen === "connected" ? "hidden" : ""
        }`}
      >
        <div className="text-black font-bold text-lg">Security and Accounts Access</div>
        <div className="text-black text-base">
          Manage your account ‘s security and keep track account’s usage including apps that you
          have connected to your account.
        </div>
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setTabOpen("security")}
        >
          <div className="flex items-center gap-2">
            <img src={lock} />
            <span>
              <p className="text-green text-lg">Security</p>
              <p className="text-gray-200 text-sm">Manage your account’s security</p>
            </span>
          </div>
          <img src={right} />
        </div>

        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setTabOpen("connected")}
        >
          <div className="flex items-center gap-2">
            <img src={cloud} />
            <span>
              <p className="text-green text-lg">Connected accounts</p>
              <p className="text-gray-200 text-sm">
                Manage Google or Apple accounts connected to your BMHM
              </p>
            </span>
          </div>
          <img src={right} />
        </div>
      </div>

      <div>
        {tabOpen === "security" && <Security />}
        {tabOpen === "connected" && <ConnectedAccounts />}
      </div>
    </div>
  );
};

export default Access;
