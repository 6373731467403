export const QuizQuestions = [
  "What are your primary reasons for seeking therapy?",
  "Have you received therapy in the past?",
  "Are you looking for a therapist who specializes in a particular area (e.g. anxiety, depression, trauma)?",
  `Do you have any preferences for the therapist's gender or age range?`,
  "Are you comfortable with virtual therapy sessions or do you prefer in-person sessions?",
  "Do you have any specific scheduling needs or constraints?",
  "How would you rate your current physical health?",
  "How you feel about your current eating habits and overall nutrition?",
  `Would you like to tell us more about what you're looking for in a therapist, or any concerns you have about therapy?`
];

export const QuizAnswers = [
  [
    'Solve a problem causing distress',
    'Improve my mental health',
    'Learn coping skills for stress',
    'Other'
  ],
  [
    'Yes',
    'No',
    'Prefer not to say'
  ],
  [
    'Yes',
    'No',
    'Prefer not to say'
  ],
  [
    'Yes',
    'No',
    'Not sure'
  ],
  [
    'Yes',
    'No',
    'Not sure'
  ],
  [
    'Yes',
    'No',
    'Not sure'
  ],
  [
    'Good',
    'Fair',
    'Poor'
  ],
  [
    'Good',
    'Fair',
    'Poor'
  ]
]
