import { useState } from "react";
import Step from "./Step";
import { DailyAnswers, DailyQuestions } from "./DailyQ&A";
import { useNavigate } from "react-router-dom";

const DailyQuestionsSurvey = () => {
  const navigate = useNavigate();
  const [stage, setStage] = useState(1);

  const handleNext = () => {
    setStage(stage + 1);
  };

  const handlePrevious = (currentStep: number) => {
    if (currentStep !== 1) setStage(stage - 1);
    else navigate(-1);
  };
  const renderForm = () => {
    return DailyQuestions.map((question, index) => {
      if (index === stage - 1) {
        return (
          <Step
            key={index}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            currentStep={stage}
            question={question}
            answers={DailyAnswers[index]}
          />
        );
      }
      return true;
    });
  };

  return <div className="px-[5rem]">{renderForm()}</div>;
};

export default DailyQuestionsSurvey;
