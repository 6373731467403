import { LightbulbRegular } from "assets/icons";
import TrendsHeader from "./yourTrends";
import TrendsPost from "./TrendsPost";
import TrendsToday from "./TrendingToday";
import CreatorsTrends from "./TrendingCreators";
import Hashtags from "./Hashtags";

const TrendingPost = () => {
  return (
    <div>
      <div className="lg:flex gap-12">
        <div className="basis-[5%] self-center">
          <img src={LightbulbRegular} />
        </div>
        <div className="basis-[55%]">
          <TrendsHeader />
          <TrendsPost />
        </div>
        <div className="basis-[35%]">
          <div>
            <TrendsToday />
            <CreatorsTrends />
            <Hashtags />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingPost;
