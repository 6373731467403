
interface Props {
    className?: string;
    onClick: () => void;
}
const MenuIcon = ({ className, onClick }:Props) => {
  const menuStyles = {
    color: "#FF9C00",
    cursor: "pointer"
  };
  return (
    <div className={className} onClick={onClick} style={menuStyles}>&#9776;</div>
  )
}

export default MenuIcon