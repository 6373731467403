interface RadioButtonProps {
  label: string;
  options: string[];
  selectedOption: string;
  onOptionChange: (...args: any) => void;
  className?: string;
}

function RadioButton ({ options, label, selectedOption, onOptionChange, className }: RadioButtonProps) {
  return (
          <fieldset className={className ?? `text-[#4A4A4A] font-bold text-xs`}>
            <legend>{label}</legend>
            {options.map((option) => (
              <div key={option} className="my-3">
                <input
                  type="radio"
                  id={option}
                  name={option}
                  value={option}
                  onChange={() => onOptionChange(option)}
                  checked={selectedOption.trim() === option.trim()}
                />
                <label className="ml-2" htmlFor={option}>
                  {option}
                </label>
              </div>
            ))}
          </fieldset>
  );
}

export default RadioButton;
