import { AppointmentHeader1, AppointmentHeader2 } from "assets/images";

const AppointmentHeaderImages = () => {
  return (
    <section className="my-3 py-5 pl-5 pr-10 bg-white rounded-t-lg flex gap-5 shadow-lg">
      <img className="w-[60%]" src={AppointmentHeader1} alt={AppointmentHeader1} />
      <img className="w-[40%]" src={AppointmentHeader2} alt={AppointmentHeader2} />
    </section>
  );
};

export default AppointmentHeaderImages;
