import { Zahara } from "assets/images";
import { ParticipantProps } from "types";

export const Participants: ParticipantProps[] = [
  {
    id: "1",
    image: Zahara,
    name: "Zahara N.",
    title: "Host"
  },
  {
    id: "2",
    image: Zahara,
    name: "Amara D.",
    title: "Listener"
  },
  {
    id: "3",
    image: Zahara,
    name: "Brian O.",
    title: "Speaker"
  },
  {
    id: "4",
    image: Zahara,
    name: "Mandy K.",
    title: "Listener"
  },
  {
    id: "5",
    image: Zahara,
    name: "Anwar V.",
    title: "Listener"
  },
  {
    id: "6",
    image: Zahara,
    name: "Tunde A.",
    title: "Listener"
  },
  {
    id: "7",
    image: Zahara,
    name: "Wendy S",
    title: "Listener"
  },
  {
    id: "8",
    image: Zahara,
    name: "Meeka S.",
    title: "Listener"
  },
  {
    id: "9",
    image: Zahara,
    name: "Jenn T.",
    title: "Listener"
  },
  {
    id: "10",
    image: Zahara,
    name: "Roman W.",
    title: "Listener"
  },
  {
    id: "11",
    image: Zahara,
    name: "Samuel R.",
    title: "Listener"
  },
  {
    id: "12",
    image: Zahara,
    name: "Akul L.",
    title: "Listener"
  },
  {
    id: "13",
    image: Zahara,
    name: "Jabari N.",
    title: "Listener"
  },
  {
    id: "14",
    image: Zahara,
    name: "Shivali E.",
    title: "Listener"
  },
  {
    id: "15",
    image: Zahara,
    name: "Cindy C.",
    title: "Listener"
  }
];
