import { dashboardlogo, logout } from "assets/images";
import { Link, useLocation } from "react-router-dom";

import {
  THER_DASHBOARD,
  THER_APPOINTMENTS,
  THER_CHATS,
  THER_AUDIOCASTS,
  THER_REFER,
  THER_GROUPS,
  THER_TRENDS,
  THER_SETTINGS,
  THER_SUPPORT
} from "routes/CONSTANTS";
import {
  BMappointments,
  BMaudiocasts,
  BMsupport,
  BMgroups,
  BMhome,
  BMrefer,
  BMsettings,
  BMtrends
} from "./icons";
import BMchats from "./icons/BMchats";

const sidebar = [
  {
    Icon: BMhome,
    name: "Home",
    to: THER_DASHBOARD
  },
  {
    Icon: BMappointments,
    name: "Appointments",
    to: THER_APPOINTMENTS
  },
  {
    Icon: BMchats,
    name: "Chats",
    to: THER_CHATS
  },
  {
    Icon: BMaudiocasts,
    name: "Audio casts",
    to: THER_AUDIOCASTS
  },
  {
    Icon: BMtrends,
    name: "Trends",
    to: THER_TRENDS
  },
  {
    Icon: BMgroups,
    name: "Groups",
    to: THER_GROUPS
  },
  {
    Icon: BMsupport,
    name: "Support",
    to: THER_SUPPORT
  },
  {
    Icon: BMrefer,
    name: "Refer a Friend",
    to: THER_REFER
  },
  {
    Icon: BMsettings,
    name: "Settings",
    to: THER_SETTINGS
  }
];

const TherapistDashboardSidebar = () => {
  const { pathname } = useLocation();

  return (
    <div className="md:w-1/3 lg:w-1/4 xl:w-1/5   md:pt-8 fixed   px-3 md:px-8 flex flex-col gap-4 md:gap-6 bg-white h-screen  shadow-lg">
      <div className="flex mt-[-25px] justify-center ">
        <img src={dashboardlogo} alt="dashboardlogo" />
      </div>
      {sidebar.map(({ Icon, name, to }, key) => (
        <Link
          key={key}
          to={to}
          className={`${
            pathname === to
              ? "rounded-md text-orange  flex items-center gap-2"
              : "text-green  flex items-center gap-2"
          } flex items-center p-[5px] my-[-8px]  `}
        >
          <div className="w-5">
            <Icon size={16} />
          </div>
          <div className="w-full">
            <span className="text-base">{name}</span>
          </div>
        </Link>
      ))}
      <div className="text-gray-200 p-2 flex items-center gap-2 cursor-pointer">
        <div className="w-full  hidden md:block items-center">
          <span className="inline-block">
            <img src={logout} />
          </span>
          <span className="text-base inline-block">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default TherapistDashboardSidebar;
