import { X, invite } from "assets/icons";

const Invite = () => {
  return (
    <div>
      <div>
        <h2 className="font-[700] text-[24px] text-[#3C4E40]">Invite your friends</h2>
        <p className="text-base text-[#3C4E40]">
          Insert your friends addresses and send them and invite.
        </p>
      </div>
      <div className="text-[#3C4E40] rounded-[10px] px-4 py-1 my-6 flex justify-between items-center border-2 border-[#005028]">
        <div className="flex gap-4">
          <div className="bg-[#EDF3F0] rounded-[83px] px-4 py-2 flex gap-2 items-center">
            <small>john.doe@gmail.com </small>
            <button>
              <img src={X} />
            </button>
          </div>
          <input
            placeholder="Email addresses..."
            type="text"
            className="text-[#667085] text-[16px] outline-none"
          />
        </div>
        <div className="flex items-center gap-2 lg:gap-4">
          <button>
            <img src={invite} className="p-2 rounded-full bg-[#FF9C00]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Invite;
