import { DailyQuestionsSurvey, DailyQuestionsLayout } from "components";

const DailyCheckInQuestionsView = () => {
  return (
    <div>
      <DailyQuestionsLayout>
        <DailyQuestionsSurvey />
      </DailyQuestionsLayout>
    </div>
  );
};

export default DailyCheckInQuestionsView;
