import { Ellipse34, Ellipse35, Ellipse36, Ellipse37, Ellipse38 } from "assets/images";
import { showAll } from "assets/icons";

const members = [
  { id: 1, image: Ellipse34 },
  { id: 2, image: Ellipse35 },
  { id: 3, image: Ellipse36 },
  { id: 4, image: Ellipse37 },
  { id: 5, image: Ellipse38 }
];

const GroupMembers = () => {
  return (
    <div>
      <div className="bg-[#F6F9FB] h-[447px] p-4 rounded-[8px]">
        <h4 className="font-bold text-[24px] text-[#4A4A4A]">{members.length} Members</h4>
        <p className="text-[18px] text-[#667085] py-4">
          These are the total number of people available on the “Depression group with Tega”
        </p>
        <div className="flex py-4">
          {members.slice(0, 5).map((member) => (
            <img
              src={member.image}
              className="ml-[-.8rem] w-[67px] h-[67px] rounded-full"
              key={member.id}
            />
          ))}
          {members.length > 5 && (
            <div className="ml-[-.8rem] w-[67px] h-[67px] rounded-full border border-1 border-[#667085] text-[#005028] text-[24px] flex justify-center items-center bg-white">
              {members.length - 5}
            </div>
          )}
        </div>
        <button className="bg-[#FFAC2B] text-white rounded-[4px] p-4 my-2">Invite Friends</button>
        <div className="w-full bg-[#ECECEC] h-1 my-6"></div>
        <button className="text-[#005028] text-[24px] font-bold flex items-center gap-2">
          Show All <img src={showAll} />
        </button>
      </div>
    </div>
  );
};

export default GroupMembers;
