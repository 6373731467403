import { longArrowRight, greenArrowRight } from "assets/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  handleNext: () => void;
  handlePrevious: (currentStep: number) => void;
  currentStep: number;
  question: string;
  answers: string[];
}

const Step = ({ handleNext, handlePrevious, question, answers, currentStep }: Props) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number>();
  const [textAnswer, setTextAnswer] = useState<string[]>(Array(3).fill(""));
  const navigate = useNavigate();

  useEffect(() => {
    const aggregatedAnswers = getAggregatedAnswersFromStorage();
    const storedAnswer = aggregatedAnswers[currentStep];

    if (storedAnswer) {
      const index = answers?.findIndex((answer) => answer === String(storedAnswer));
      setSelectedAnswerIndex(index);
      setTextAnswer(storedAnswer);
    }
  }, []);

  const getAggregatedAnswersFromStorage = () => {
    const storedAnswers = localStorage.getItem("aggregatedAnswers");
    return storedAnswers ? JSON.parse(storedAnswers) : {};
  };

  const handleSelectedAnswerChange = (currentSelection: number) => {
    if (selectedAnswerIndex === currentSelection) {
      return;
    }

    const answer = answers[currentSelection];
    setSelectedAnswerIndex(currentSelection);

    const updatedAggregatedAnswers = {
      ...getAggregatedAnswersFromStorage(),
      [currentStep]: answer
    };
    localStorage.setItem("aggregatedAnswers", JSON.stringify(updatedAggregatedAnswers));
  };

  const handleTextAnswerChange = (
    currentStep: number,
    arrayIndexToUpdate: number,
    value: string
  ) => {
    const updatedTextAnswer = [...textAnswer];
    updatedTextAnswer[arrayIndexToUpdate] = value;
    setTextAnswer(updatedTextAnswer);

    const updatedAggregatedAnswers = {
      ...getAggregatedAnswersFromStorage(),
      [currentStep]: currentStep === 3 ? updatedTextAnswer : [value]
    };
    localStorage.setItem("aggregatedAnswers", JSON.stringify(updatedAggregatedAnswers));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const aggregatedAnswers = getAggregatedAnswersFromStorage();
    console.log(aggregatedAnswers);

    navigate("/");
    localStorage.clear();
  };

  return (
    <>
      <button
        className="flex gap-2 items-center text-green text-base font-bold bg-transparent hover:underline rounded-[4px]  mb-6"
        onClick={() => handlePrevious(currentStep)}
      >
        <img
          className="rotate-180"
          style={{ fill: "red" }}
          src={greenArrowRight}
          alt="Arrow Left Image"
        />
        {`Back`}
      </button>

      <p className="text-[#4A4A4A] text-lg">{currentStep}/10:</p>
      <div className="mt-3">
        <p className="text-green text-xl mb-8">{question}</p>
      </div>
      <form onSubmit={handleSubmit}>
        {/* Renders Questions 1 & 4 */}
        {[1, 4].includes(currentStep) &&
          answers?.map((answer, index) => (
            <div
              key={index}
              className="w-[50%] my-2 rounded bg-[#E9EFEC] flex items-center cursor-pointer"
              style={{ pointerEvents: "auto" }}
              onClick={() => handleSelectedAnswerChange(index)}
            >
              <input
                type="radio"
                id={`radio-input-${answer}`}
                name="radio-group"
                value={answer}
                checked={selectedAnswerIndex === index}
                onChange={() => {}}
                className="ml-2 w-5 h-5"
                style={{ accentColor: "#005028" }}
              />
              <label htmlFor={`radio-input-${answer}`} className="ml-2 cursor-pointer">
                <div className="py-[10px] px-[20px] text-base text-[#141414] text-opacity-75">
                  {answer}
                </div>
              </label>
            </div>
          ))}

        {currentStep === 2 && (
          <div className="flex">
            {answers?.map((answer, index) => (
              <button
                key={index}
                type="button"
                id={`button-input-${answer}`}
                className={`py-2 px-3 w-[48px] h-[48px] border border-[#005028] text-base rounded-full my-2 mr-2 transition-colors duration-200 ${
                  selectedAnswerIndex === index ? "bg-green text-white" : ""
                }`}
                onClick={() => handleSelectedAnswerChange(index)}
              >
                {answer}
              </button>
            ))}
          </div>
        )}

        {currentStep === 3 && (
          <div className="flex flex-col gap-2 my-8">
            {[0, 1, 2].map((index) => (
              <input
                key={index}
                type="text"
                className="w-[50%] h-10 px-3 mt-2 border border-green-800 border-opacity-100 rounded-md focus:border-green-500 placeholder-gray-350 opacity-75"
                placeholder={`Write the ${
                  index === 0 ? "first" : index === 1 ? "second" : "third"
                } thing here...`}
                value={textAnswer[index]}
                onChange={(e) => handleTextAnswerChange(currentStep, index, e.target.value)}
              />
            ))}
          </div>
        )}

        {currentStep >= 5 && (
          <textarea
            className="w-full h-28 p-4 bg-[#F7F7F7] border border-green my-2 rounded-md placeholder-[#C4C4C4] placeholder-opacity-75"
            value={textAnswer[0]}
            onChange={(e) => handleTextAnswerChange(currentStep, 0, e.target.value)}
            placeholder="Type your answer here..."
          />
        )}

        <button
          className="px-6 py-3 flex gap-2 items-center text-white text-base bg-green hover:bg-green-100 rounded-[4px]  mt-8"
          type={currentStep === 10 ? "submit" : "button"}
          onClick={currentStep !== 10 ? handleNext : undefined}
        >
          {currentStep === 10 ? "Submit" : "Next"}
          <img src={longArrowRight} alt="An image of a right arrow" />
        </button>
      </form>
    </>
  );
};

export default Step;
