import Anxiety from "./Anxiety";
import HealthWellness from "./HealthWellness";
import MusicRythm from "./MusicRythm";
const LiveAudiocasts = () => {
  return (
    <div>
      <h1 className="font-[500] text-[26px] py-4">Live Audiocasts</h1>
      <Anxiety />
      <MusicRythm />
      <HealthWellness />
    </div>
  );
};

export default LiveAudiocasts;
