import { right } from "assets/icons";
import { useState } from "react";
import Accounts from "./Accounts/Accounts";
import Notifications from "./Notifications/Notifications";
import Accessibility from "./Accessibility/Accessibility";
import Access from "./Access/Access";

const SettingsMenu = () => {
  const [tabOpen, setTabOpen] = useState("accounts");
  return (
    <div className="flex gap-12">
      <div className="flex flex-col gap-6 h-screen w-96 bg-white px-4 py-12">
        <div className="text-3xl font-bold text-center">Settings</div>
        <span
          onClick={() => setTabOpen("accounts")}
          className={
            tabOpen === "account"
              ? "text-green-100 text-lg px-2 py-4 w-full cursor-pointer flex justify-between items-center"
              : "text-black text-lg px-2 py-4 w-full cursor-pointer flex justify-between items-center"
          }
        >
          Your Account <img src={right} />
        </span>
        <span
          onClick={() => setTabOpen("access")}
          className={
            tabOpen === "access"
              ? "text-green-100 text-lg px-2 py-4 w-full cursor-pointer flex justify-between items-center"
              : "text-black text-lg px-2 py-4 w-full cursor-pointer flex justify-between items-center"
          }
        >
          Security and Account Access <img src={right} />
        </span>
        <span
          onClick={() => setTabOpen("notifications")}
          className={
            tabOpen === "notifications"
              ? "text-green-100 text-lg px-2 py-4 w-full cursor-pointer flex justify-between items-center"
              : "text-black text-lg px-2 py-4 w-full cursor-pointer flex justify-between items-center"
          }
        >
          Notifications <img src={right} />
        </span>
        <span
          onClick={() => setTabOpen("accessibility")}
          className={
            tabOpen === "accessibility"
              ? "text-green-100 text-lg px-2 py-4 w-full cursor-pointer flex justify-between items-center"
              : "text-black text-lg px-2 py-4 w-full cursor-pointer flex justify-between items-center"
          }
        >
          Accessibility, Display and Languages <img src={right} />
        </span>
      </div>

      <div className="w-[700px] h-screen py-12">
        {tabOpen === "accounts" && <Accounts />}
        {tabOpen === "access" && <Access />}
        {tabOpen === "accessibility" && <Accessibility />}
        {tabOpen === "notifications" && <Notifications />}
      </div>
    </div>
  );
};

export default SettingsMenu;
