import { StartLiveForm } from "components";


const CreateLiveFormView = () => {
  return (
    <div>
      <StartLiveForm />
    </div>
  );
};

export default CreateLiveFormView;