// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SVG_PATH = "/assets/svg";
export const eye = `${SVG_PATH}/eye.svg`;
export const edit = `${SVG_PATH}/edit.svg`;
export const trash = `${SVG_PATH}/trash.svg`;
export const boxup = `${SVG_PATH}/boxup.svg`;
export const lightbulb = `${SVG_PATH}/lightbulb.svg`;
export const close = `${SVG_PATH}/close.svg`;
export const right = `${SVG_PATH}/right.svg`;
export const left = `${SVG_PATH}/left.svg`;
export const forwardArrow = `${SVG_PATH}/forwardArrow.svg`;

// Settings
export const arrowLeft = `${SVG_PATH}/arrowLeft.svg`;
export const download = `${SVG_PATH}/download.svg`;
export const lock = `${SVG_PATH}/lock.svg`;
export const cloud = `${SVG_PATH}/cloud.svg`;
export const person = `${SVG_PATH}/person.svg`;

// Notifications
export const filter = `${SVG_PATH}/filter.svg`;
export const globe = `${SVG_PATH}/globe.svg`;
export const active = `${SVG_PATH}/online.svg`;
export const offline = `${SVG_PATH}/offline-chat.svg`;
export const Report = `${SVG_PATH}/Report.svg`;
export const MarkedMessage = `${SVG_PATH}/Marked-message.svg`;
export const SharedMedia = `${SVG_PATH}/Shared-media.svg`;
export const ZoomIn = `${SVG_PATH}/zoom-in.svg`;
export const Add = `${SVG_PATH}/Add.svg`;
export const Meh = `${SVG_PATH}/meh.svg`;
export const Mic = `${SVG_PATH}/mic.svg`;
export const longArrowRight = `${SVG_PATH}/longArrowRight.svg`;
export const greenArrowRight = `${SVG_PATH}/greenArrowRight.svg`;

// Referral
export const User = `${SVG_PATH}/user.svg`;
export const Navigation = `${SVG_PATH}/navigation.svg`;
export const DottedLine = `${SVG_PATH}/dotted-line.svg`;
export const invite = `${SVG_PATH}/invite-send.svg`;
export const X = `${SVG_PATH}/X.svg`;
export const shareLinkedin = `${SVG_PATH}/share-linkedIn.svg`;
export const shareFb = `${SVG_PATH}/share-fb.svg`;
export const shareTwitter = `${SVG_PATH}/share-twitter.svg`;
export const shareWhatsapp = `${SVG_PATH}/share-whatsapp.svg`;
export const sharePin = `${SVG_PATH}/share-pin.svg`;
export const shareArrow = `${SVG_PATH}/share-arrow.svg`;
export const shareText = `${SVG_PATH}/share-text.svg`;
export const shareDiscord = `${SVG_PATH}/share-discord.svg`;

// audiocasts
export const microphone = `${SVG_PATH}/microphone.svg`;
export const search = `${SVG_PATH}/search.svg`;
export const home = `${SVG_PATH}/home.svg`;
export const cloudy = `${SVG_PATH}/cloudy.svg`;
export const music = `${SVG_PATH}/music.svg`;
export const message = `${SVG_PATH}/message.svg`;
export const book = `${SVG_PATH}/book.svg`;
export const coffee = `${SVG_PATH}/coffee.svg`;
export const BMHMLive = `${SVG_PATH}/BMHMLive.svg`;
export const LifeTalk = `${SVG_PATH}/LifeTalk.svg`;
export const whitePlay= `${SVG_PATH}/whitePlay.svg`;
export const greenPlay= `${SVG_PATH}/greenPlay.svg`;


// Live Show
export const slmic = `${SVG_PATH}/SLMic.svg`;
export const slchat = `${SVG_PATH}/SLChat.svg`;
export const slmaximize = `${SVG_PATH}/SLMaximize.svg`;
export const slcc = `${SVG_PATH}/SLCC.svg`;
export const slpen = `${SVG_PATH}/SLPen.svg`;
export const slvideo = `${SVG_PATH}/SLVideo.svg`;
export const slsettings = `${SVG_PATH}/SLSettings.svg`;
export const slairplay = `${SVG_PATH}/airplay.svg`;

// Groups page
export const ForwardGroup = `${SVG_PATH}/ForwardGroup.svg`;
export const DotsGroup = `${SVG_PATH}/DotsGroup.svg`;
export const BellGroup = `${SVG_PATH}/BellGroup.svg`;
export const Groups = `${SVG_PATH}/Groups.svg`;
export const GroupPicture = `${SVG_PATH}/GroupPicture.svg`;
export const GroupDoc = `${SVG_PATH}/GroupDoc.svg`;
export const GroupVideo = `${SVG_PATH}/GroupVideo.svg`;
export const GroupPoll = `${SVG_PATH}/GroupPoll.svg`;
export const Like = `${SVG_PATH}/Like.svg`;
export const Comment = `${SVG_PATH}/Comment.svg`;
export const showAll = `${SVG_PATH}/showAll.svg`;

// Trending post
export const TrendAdd = `${SVG_PATH}/plus-solid.svg`;
export const Love = `${SVG_PATH}/Love.svg`;
export const Heart = `${SVG_PATH}/Heart.svg`;
export const LightbulbTrends = `${SVG_PATH}/Lightbulb-trends.svg`;
export const LightbulbRegular = `${SVG_PATH}/Lightbulb-regular.svg`;
export const TrendsLike = `${SVG_PATH}/trends-like.svg`;
export const Handshake = `${SVG_PATH}/handshake.svg`;
export const MessageCircle = `${SVG_PATH}/message-circle.svg`;

// Audiocast live
export const AudioLive = `${SVG_PATH}/audio-live.svg`;
export const AudioMic = `${SVG_PATH}/audio-mic.svg`;
export const AudioMute = `${SVG_PATH}/audio-mute.svg`;
export const AudioRaisehands = `${SVG_PATH}/audio-raisehand.svg`;
export const AudioReactions = `${SVG_PATH}/audio-reactions.svg`;
export const AudioChat = `${SVG_PATH}/audio-chat.svg`;
export const AudioShare = `${SVG_PATH}/audio-share.svg`;
export const AudioForward = `${SVG_PATH}/audio-forward.svg`;
export const AudioLove = `${SVG_PATH}/audio-love.svg`;
export const AudioSend = `${SVG_PATH}/audio-send.svg`;
export const AudioBell = `${SVG_PATH}/audio-bell.svg`;
export const AudioImage = `${SVG_PATH}/audio-image.svg`;
export const Info = `${SVG_PATH}/info-icon.svg`;
export const AudioPublic = `${SVG_PATH}/audio-public.svg`;
export const AudioPrivate = `${SVG_PATH}/audio-private.svg`;
export const AudioGuest = `${SVG_PATH}/audio-guest.svg`;
export const AudioTime = `${SVG_PATH}/audio-time.svg`;
export const ArrowRight = `${SVG_PATH}/arrow-right.svg`;
export const smilePlus = `${SVG_PATH}/smile-plus.svg`;

// Book Therapist
export const SuccessIcon = `${SVG_PATH}/sucessIcon.svg`;
export const NegativeIcon = `${SVG_PATH}/negativeIcon.svg`;
