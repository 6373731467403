import React, { useState } from "react";
import { SVCheckbox } from "components/modules";
import { Form, Formik } from "formik";

interface StepOneProps {
  onNextStep: (values: any) => void;
}

const StepOne: React.FC<StepOneProps> = ({ onNextStep }) => {
  const [selectedOptions] = useState<string[]>([]);

  const initialValues = {
    anxiety: false,
    eatingDisorder: false,
    psychoticDisorder: false,
    attentionDeficitDisorder: false,
    kleptomania: false,
    schizophrenia: false,
    autism: false,
    narcissisticPersonalityDisorder: false,
    sleepDisorder: false,
    bipolarDisorder: false,
    obsessiveCompusliveDisorder: false,
    substanceUse: false,
    borderlinePersonalityDisorder: false,
    panicDisorder: false,
    touretteSyndrome: false,
    depression: false,
    paranoidPersonalityDisorder: false,
    other: false,
    dissociativeDisorder: false,
    phobias: false,
    notSure: false,
    downSyndrome: false,
    postTraumaticStressDisorder: false,
    preferNotToSay: false
  };

  const handleSubmit = (values: any) => {
    // Prepare the data to be sent to the next step or the parent component
    const stepOneData = {
      selectedOptions: [...selectedOptions],
      ...values
    };

    // Invoke the callback function passed from the parent component
    onNextStep(stepOneData);
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <div className="mt-8">
          <p className="font-bold text-lg">
            1. What area(s) are you seeking support in? (Select all that apply)
          </p>
          <div className="grid grid-cols-3 gap-4 mt-8 ml-6">
            <SVCheckbox nameAndId="anxiety" label="Anxiety" />
            <SVCheckbox nameAndId="eatingDisorder" label="Eating Disorder" />
            <SVCheckbox nameAndId="psychoticDisorder" label="Psychotic Disorder" />
            <SVCheckbox nameAndId="attentionDeficitDisorder" label="Attention Deficit Disorder" />
            <SVCheckbox nameAndId="kleptomania" label="Kleptomania" />
            <SVCheckbox nameAndId="schizophrenia" label="Schizophrenia" />
            <SVCheckbox nameAndId="autism" label="Autism" />
            <SVCheckbox
              nameAndId="narcissisticPersonalityDisorder"
              label="Narcissistic Personality Disorder"
            />
            <SVCheckbox nameAndId="sleepDisorder" label="Sleep Disorder" />
            <SVCheckbox nameAndId="bipolarDisorder" label="Bipolar Disorder" />
            <SVCheckbox
              nameAndId="obsessiveCompusliveDisorder"
              label="Onsessive Compulsive Disorder"
            />
            <SVCheckbox nameAndId="substanceUse" label="Substance Use" />
            <SVCheckbox
              nameAndId="borderlinePersonalityDisorder"
              label="Borderline Personality Disorder"
            />
            <SVCheckbox nameAndId="panicDisorder" label="Panic Disorder" />
            <SVCheckbox nameAndId="touretteSyndrome" label="Tourette Syndrome" />
            <SVCheckbox nameAndId="depression" label="Depression" />
            <SVCheckbox
              nameAndId="paranoidPersonalityDisorder"
              label="Paranoid Personality Disorder"
            />
            <SVCheckbox nameAndId="other" label="Other: __________" />
            <SVCheckbox nameAndId="dissociativeDisorder" label="Dissociative Disorder" />
            <SVCheckbox nameAndId="phobias" label="Phobias" />
            <SVCheckbox nameAndId="notSure" label="Not Sure" />
            <SVCheckbox nameAndId="downSyndrome" label="Down Syndrome" />
            <SVCheckbox
              nameAndId="postTraumaticStressDisorder"
              label="Post-Traumatic Stress Disorder"
            />
            <SVCheckbox nameAndId="preferNotToSay" label="Prefer Not To Say" />
          </div>
        </div>

        <div className="mt-16">
          <p className="font-bold text-lg">
            2. Which approaches to therapy are you interested in or prefer? (Select all that apply)
          </p>
          <div className="grid grid-cols-2 gap-4 mt-8 ml-6">
            <SVCheckbox nameAndId="artTherapy" label="Art Therapy" />
            <SVCheckbox nameAndId="mindfulnessBasedTherapy" label="Mindfulness Therapy" />
            <SVCheckbox
              nameAndId="cognitiveBehavioralTherapy"
              label="Cognitive Behavioral Therapy"
            />
            <SVCheckbox nameAndId="psychodynamicTherapy" label="Psycho-Dynamic Therapy" />
            <SVCheckbox nameAndId="familySystemsTherapy" label="Family Systems Therapy" />
            <SVCheckbox nameAndId="solutionFocusedBriefTherapy" label="Solution Focused Therapy" />
            <SVCheckbox nameAndId="humanisticTherapy" label="Humanistic Therapy" />
            <SVCheckbox nameAndId="other" label="Other:__________" />
            <SVCheckbox nameAndId="narrativeTherapy" label="Narrative Therapy" />
            <SVCheckbox nameAndId="notSure2" label="Not Sure" />
          </div>
        </div>
        <div className="flex justify-end mt-16">
          <button type="submit" className="bg-orange text-white p-2 rounded-md w-36">
            Next (1/2)
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default StepOne;
