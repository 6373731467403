import { Field } from "formik";

interface FormInputProps {
  nameAndId: string;
  label: string;
  type: "checkbox";
}

function DBCheckbox({ nameAndId, label, type }: FormInputProps) {
  return (
    <div className="flex items-center justify-between cursor-pointer">
      <label className="text-lg text-green md:text-xl" htmlFor={nameAndId}>
        <span className="">{label}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        type={type}
        className="bg-white accent-green-100 h-4 w-4"
      />
    </div>
  );
}

export default DBCheckbox;
