
interface CheckboxProps {
  nameAndId: string;
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

function Checkbox({ nameAndId, label, checked, onChange, className }: CheckboxProps) {
  return (
    <div className={`my-4 flex items-center gap-2`}>
      <input
        type="checkbox"
        id={nameAndId}
        name={nameAndId}
        checked={checked}
        onChange={onChange}
        className={
        className ?? `w-6 h-6 border border-green rounded-lg`}
      />
      <label htmlFor={nameAndId}>{label}</label>
    </div>
  );
}

export default Checkbox;
