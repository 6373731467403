const TrendsHeader = () => {
  return (
    <div>
      <div className="lg:flex items-center justify-between py-8">
        <h1 className="text-[#3C4E40] text-[24px] font-[500]">Trending Posts</h1>
        <ul className="grid grid-cols-3 gap-4 items-center text-[14px] text-center">
          <li className="bg-[#005028] text-white border-[0.6px] py-[7px] px-10px md:px-[19px] rounded-[20px] w-[89px] md:w-[67px]">
            New
          </li>
          <li className="bg-white text-[#3C4E40] border-[#3C4E40] border-[0.6px] py-[7px] px-10px md:px-[19px] rounded-[20px] w-[89px]">
            Popular
          </li>
          <li className="bg-white text-[#3C4E40] border-[#3C4E40] border-[0.6px] py-[7px] px-10px md:px-[19px] rounded-[20px] w-[89px]">
            Saved
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TrendsHeader;
