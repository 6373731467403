
import NotificationCount from "./NotificationCount";
import { Message } from "utils/Message";
function Messages() {
  return (
    <>
    { 
      Message.slice(0,2).map((arr,id)=>(
        // eslint-disable-next-line react/jsx-key
        <div className="flex justify-between items-center bg-white shadow-lg mb-3 px-2 py-2 w-full rounded-[10px]">
        <div key={id} className="flex gap-2 items-center">
        <div className="w-[35px] relative ">
          <img src={arr.image} className="w-full" alt="Therapist's image" />
          <span className="bg-[#00A552] left-[26px] bottom-[2px] block w-[10px] h-[10px] rounded-full absolute"></span>
        </div>
        <p className="text-[15px] text-[#3C4E40]">{arr.sender}</p>
      </div>
      <NotificationCount count={arr.count} />
      </div>
      ))
    }
  </>
  );
}

export default Messages;
