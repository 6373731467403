export const DailyQuestions = [
  "How did you sleep last night?",
  "On a scale of 1 - 10, how are you feeling today?",
  "What are three things you are grateful for today?",
  "Did you engage in any physical activity today?",
  "What was one highlight of your day so far?",
  "What was one thing you learnt today that you didn't know before?",
  "What was one thing you accomplished today that you are proud of?",
  "Did you take any breaks or engage in any self-care activities today?",
  "Did you experience any stress or anxiety today? If so, what triggered it?",
  "What is the one thing you are looking forward to in the coming days or weeks?"
];

export const DailyAnswers = [
  [
    "I slept pretty well.",
    "I didn't sleep very well.",
    "Last night's sleep was okay.",
    "I couldn't fall asleep at all."
  ],
  ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  [],
  ["Yes", "No", "Not sure"]
];
