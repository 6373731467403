import { DashboardWrapper } from "components";
import ScheduleAudiocastView from "./ScheduleAudiocastView";

export const ScheduleAudiocastContainer = () => {
  return (
    <DashboardWrapper>
      <ScheduleAudiocastView />
    </DashboardWrapper>
  );
};
