import { ErrorMessage, Field } from "formik";

interface FormInputProps {
  nameAndId: string;
  label: string;
  placeholder: string;
  type?: "text" | "number" | "email" | "password" | "tel" | "file";
  className?: string;
  labelClassName?: string;
}

function DBInput({
  nameAndId,
  label,
  placeholder,
  type,
  className,
  labelClassName
}: FormInputProps) {
  return (
    <div className="flex flex-col">
      <label
        className={`text-white md:text-2xl text-base font-bold pb-3 ${labelClassName ?? ""}`}
        htmlFor={nameAndId}
      >
        <span className="">{label}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        placeholder={placeholder}
        type={type ?? "text"}
        className={
          className ??
          "bg-[#F6F9FB] placeholder-gray text-white md:text-2xl text-base font-normal p-1 md:h-[60px] focus:ring-0 border-2 border-green rounded-xl outline-none"
        }
      />

      <ErrorMessage component="label" name={nameAndId} className="text-sm w-full text-[#FF0000]" />
    </div>
  );
}

export default DBInput;
