import { DashboardWrapper } from "components";
import AudiocastLiveView from "./AudiocastLiveView";

export const AudiocastLiveContainer = () => {
  return (
    <DashboardWrapper>
      <AudiocastLiveView />
    </DashboardWrapper>
  );
};
