import { useState } from "react";
import StartLive from "./StartLive";
import ScheduleLive from "./ScheduleLive";
import UploadVideo from "./UploadVideo";

const Liveshow = () => {
  const [tabOpen, setTabOpen] = useState("startLive");

  return (
    <div className="bg-white px-[1rem] lg:px-[3rem] min-h-[20rem] md:min-h-[10rem] lg:min-h-[8rem] ">
      <div className="flex flex-col lg:flex-row lg:items-center gap-4 py-4 ">
        <div className="flex flex-col gap-4 w-full">
          <div className="flex justify-between w-full">
            <div className="flex gap-2 items-center">
              <h1 className="font-bold text-xl">LIVESHOW</h1>
              <span
                className={
                  tabOpen === "startLive"
                    ? "rounded-[20px] text-[#FECA00] md:text-[#000000] md:bg-[#FECA00] p-1 flex items-center text-[15px] cursor-pointer"
                    : "text-[#000000] text-[15px] cursor-pointer"
                }
                onClick={() => setTabOpen("startLive")}
              >
                Start Live
              </span>
              <span
                className={
                  tabOpen === "scheduleLive"
                    ? "rounded-[20px] text-[#FECA00] md:text-[#000000] md:bg-[#FECA00] p-1 flex items-center text-[15px] cursor-pointer"
                    : "text-[#000000] text-[15px] cursor-pointer"
                }
                onClick={() => setTabOpen("scheduleLive")}
              >
                Schedule a Live
              </span>
              <span
                className={
                  tabOpen === "uploadVideo"
                    ? "rounded-[20px] text-[#FECA00] md:text-[#000000] md:bg-[#FECA00] p-1 flex items-center text-[15px] cursor-pointer"
                    : "text-[#000000] text-[15px] cursor-pointer"
                }
                onClick={() => setTabOpen("uploadVideo")}
              >
                Upload a Video
              </span>
            </div>

            <div className="flex items-center gap-2">
              <span className="rounded-[20px] text-[#FECA00] md:text-[#000000] md:bg-[#FECA00] py-1 px-2 flex items-center gap-2">
                Audience
              </span>
              <span className="rounded-[20px] text-[#FECA00] md:text-[#000000] md:bg-[#FECA00] py-1 px-2 flex items-center gap-2">
                Public
              </span>
              <span className="rounded-[20px] text-[#FECA00] md:text-[#000000] md:bg-[#FECA00] py-1 px-2 flex items-center gap-2">
                Private
              </span>
              <span className="rounded-[20px] text-[#FECA00] md:text-[#000000] md:bg-[#FECA00] py-1 px-2 flex items-center gap-2">
                Group
              </span>
            </div>
          </div>
          {tabOpen === "startLive" && <StartLive />}
          {tabOpen === "scheduleLive" && <ScheduleLive />}
          {tabOpen === "uploadVideo" && <UploadVideo />}
        </div>
      </div>
      <div className="w-full h-[0.5px] bg-[#979797]"></div>
    </div>
  );
};

export default Liveshow;
