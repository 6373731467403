interface Participant {
  id: string;
  name: string;
  image: string;
  isMuted?: boolean;
  toggleMute?: () => void;
}

interface ParticipantListProps {
  participants: Participant[];
}

const AudiocastParticipants = ({ participants }: ParticipantListProps) => {
  return (
    <div className="flex flex-col gap-4">
      {participants.map((participant) => (
        <div key={participant.id} className="flex items-center gap-2 mb-2">
          <img src={participant.image} alt={participant.name} className="w-8 h-8 rounded-full" />
          <span className="font-medium">{participant.name}</span>
          <button
            className={`ml-2 p-1 rounded-full ${participant.isMuted ? "bg-[#D80B0B]" : "bg-green"}`}
            onClick={participant.toggleMute}
          >
            {participant.isMuted ? "Unmute" : "Mute"}
          </button>
        </div>
      ))}
    </div>
  );
};

export default AudiocastParticipants;
