import { TherapistDashboardWrapper } from "components";
import TherReferView from "./TherReferView";

export const TherReferContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherReferView />
    </TherapistDashboardWrapper>
  );
};
