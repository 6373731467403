import { SettingsMenu } from "components";

const SettingsView = () => {
  return (
    <div>
      <SettingsMenu />
    </div>
  );
};

export default SettingsView;
