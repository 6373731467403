import { AudioBell, AudioImage } from "assets/icons";
import { GoBack } from "assets/images";
import { Link } from "react-router-dom";
import { AUDIOCASTS } from "routes/CONSTANTS";

const ScheduleAudiocast = () => {


  return (
    <div className="">
      <Link to={AUDIOCASTS}>
        <img src={GoBack} className="py-4" />
      </Link>
      <h1 className="font-[500] text-[20px] md:text-[26px] pb-1 whitespace-nowrap">
        Create an Audiocast
      </h1>
      <div className="bg-[#E2E2E2] rounded-[10px] min-h-[550px] p-4">
        <form>
          <div className="lg:flex items-start gap-5">
            <div>
              <div>
                <label className="font-[500] text-[14px]">1. Audiocast Title</label>
                <input
                  className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797]"
                  placeholder="Ex: Food For Thought: Nourishing Your Mind and Body"
                />
              </div>
              <div className="py-2">
                <label className="font-[500] text-[14px]">2. Brief Description</label>
                <textarea
                  className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797] "
                  placeholder="Ex: Discussing the struggles of mental health in the Black/African community and how to find resources to overcome stigma."
                ></textarea>
              </div>
              <div className="py-1">
                <label className="font-[500] text-[14px]">3. Select Topics</label>
                <input
                  className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797]"
                  placeholder="Ex: Anxiety, Depression, OCD, etc."
                />
              </div>
              <div className="py-1">
                <label className="font-[500] text-[14px]">4. Add Speakers</label>
                <input className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797]" />
              </div>
              <div className="py-1">
                <label className="font-[500] text-[14px]">5. Add Hosts</label>
                <input className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797]" />
              </div>
              <div className="py-1">
                <label className="font-[500] text-[14px]">6. Add Guests</label>
                <textarea className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797] "></textarea>
              </div>
              <div className="py-2">
                <label className="font-[500] text-[14px]">
                  7. Is this a private or public audiocast?
                </label>
                <div className="flex items-center gap-2 mb-4">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-4 h-4 bg-[#005028] border-[#E2E2E2] accent-green "
                  />
                  <label htmlFor="default-radio-1">Private</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-4 h-4 bg-[#005028] border-[#E2E2E2] accent-green "
                  />
                  <label htmlFor="default-radio-1">Public</label>
                </div>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="start" className="font-[500] text-[14px]">
                  8. Select Date and Time
                </label>
                <br />
                <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-1 pt-1">
                  <div className="flex items-center gap-1">
                    <input
                      type="date"
                      name="begin"
                      placeholder="DD/MM/YY"
                      className=" text-[11px] p-1 outline-white"
                    />
                    <input type="time" id="time1" className="text-[11px]  p-1 outline-white" />
                  </div>
                  <span className="text-[11px]">to</span>
                  <div className="flex items-center gap-1">
                    <input
                      type="date"
                      name="end"
                      placeholder="MM/DD/YY"
                      className=" text-[11px]  p-1 outline-white"
                    />
                    <input type="time" id="time2" className=" text-[11px]  p-1 outline-white" />
                  </div>
                  <div>
                    <select name="Location" className=" text-[13px] p-1 outline-white">
                      <option value="Est">Est</option>
                    </select>
                  </div>
                </div>
                <div>
                  <label className="flex flex-col lg:flex-row gap-2 lg:items-center py-2">
                    <div>
                      <img src={AudioBell} />
                    </div>
                    <p className="text-[14px]">Send reminder</p>
                    <input
                      type="number"
                      value={30}
                      className="w-[40px] h-[20px] outline-white p-1"
                    />
                    <select name="time" className="outline-white">
                      <option value="min">minutes</option>
                      <option value="hour">hours</option>
                      <option value="sec">seconds</option>
                    </select>
                    <p className="text-[14px]">before</p>
                  </label>
                </div>
                <div className="py-3">
                  <button className="font-[600] text-[14px] text-[#FFF] py-[8px] px-[20px] lg:px-[25px] bg-[#005028] sm:whitespace-nowrap">
                    Add to Google Calendar
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="file-upload" className="font-[500] text-[14px] custom-file-upload">
                  9. Upload Thumbnail Photo
                  <br />
                  <input type="file" id="file-upload" className="hidden" />
                  <div className="bg-white p-4 mt-2 flex items-center justify-center ">
                    <div className="border-2 border-dotted p-4">
                      <div className="flex justify-center p-2">
                        <img src={AudioImage} />
                      </div>
                      <p className="text-[14px] py-2">
                        Drag your image here or{" "}
                        <Link to="https://www.google.com/" target="_blank">
                          <span className="text-[#1472FF] text-[14px]">browse</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                </label>
              </div>
              <div className="py-3 flex justify-center items-center mt-10">
                <button className="font-[600] text-[14px] text-[#FFF] py-[8px] px-[20px] bg-[#005028] whitespace-nowrap">
                  Schedule Audiocast
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScheduleAudiocast;
