import { useState } from "react";
import DisplayReviews, { ReviewsObject } from "./DisplayReviews";
import InputReview from "./InputReview";
import { Therapist } from "./ProfileSummary";

interface Props {
  therapist: Therapist;
  onReviewSubmit: (number: number) => void;
}

const Reviews = ({ therapist, onReviewSubmit }: Props) => {
  const [submittedReview, setSubmittedReview] = useState<ReviewsObject | null>(null);

  const handleReviewSubmit = (review: ReviewsObject) => {
    setSubmittedReview(review);
    onReviewSubmit(1);

    // Update the data base

    // if database update fails, set submitted reviews back to null and pass -1 to onReviewSubmit. See below
    // setSubmittedReview(null)
    // onReviewSubmit(-1)
  };

  return (
    <section>
      <DisplayReviews therapist={therapist} submittedReview={submittedReview} />
      <InputReview onReviewSubmit={handleReviewSubmit} />
    </section>
  );
};

export default Reviews;
