import { Appointments } from "components";

const AppointmentsView = () => {
  return (
    <div>
      <Appointments />
    </div>
  );
};

export default AppointmentsView;
