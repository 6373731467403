import { coffee, message, book, music, cloudy, LifeTalk, BMHMLive } from "assets/icons";
const AudiocastHeader = () => {
  const categories = [
    { id: 1, image: BMHMLive, label: "BMHM Live" },
    { id: 1, image: LifeTalk, label: "Life Talk" },
    { id: 2, image: coffee, label: "Mindfulness" },
    { id: 3, image: message, label: "Strategy" },
    { id: 4, image: music, label: "Music" },
    { id: 5, image: cloudy, label: "Meditation" },
    { id: 6, image: book, label: "Book" }
  ];
  return (
    <div>
      
      <h1 className="font-[500] text-[26px] py-2">Discover</h1>
      <div className="grid grid-cols-3  md:grid-cols-5 lg:grid-cols-7 rounded-lg  items-center py-2">
          {categories.map((category) => (
            <div key={category.id}>
              <div className="relative ">
                <img src={category.image}  className={`${ category.id === 0 || category.id === 1 ?  "flex justify-center w-[63px] h-[63px] rounded-full cursor-pointer" : "bg-[#F1F1F1] flex justify-center w-[63px] h-[63px] p-2 rounded-full cursor-pointer "   }`} />
                <p className={`${ category.image === BMHMLive ? "bg-[#E31D1C] text-[#FFF]  border border-1  border-[#E31D1C] rounded-[20px] absolute  px-3 -bottom-2 top-12 left-1": "hidden"}`}>Live</p>
              </div>
              <p className="text-[12px] font-medium px-3 pt-4">{category.label}</p>
            </div>
          ))}
        </div>
    </div>
  );
};

export default AudiocastHeader;
