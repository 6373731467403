import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const DailyQuestionsLayout = ({ children }: Props) => {
  return (
    <div className="bg-white h-[90vh] shadow-lg rounded-2xl overflow-hidden">
      <div className="py-8">{children}</div>
    </div>
  );
};

export default DailyQuestionsLayout;
