import { AudiocastLive } from "components";


const AudiocastLiveView = () => {
  return (
    <div>
      <AudiocastLive />
    </div>
  );
};

export default AudiocastLiveView;
