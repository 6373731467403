import { Add, Meh, Mic, active, offline } from "assets/icons";

interface Message {
  id: string;
  senderId: string;
  receiverId: string;
  content: string;
  time: Date;
}

interface MessagesWindowProps {
  status: string;
  image: string;
  name: string;
  messages: Message[];
  selectedUserId: string | null;
  newMessage: string;
  onNewMessageChange: (value: string) => void;
  onSendMessage: () => void;
}

const ChatSession = ({
  name,
  status,
  image,
  messages,
  selectedUserId,
  newMessage,
  onNewMessageChange,
  onSendMessage
}: MessagesWindowProps) => {
  const messagesToShowSender = messages.filter(
    (message) => message.senderId === "1" && message.receiverId === selectedUserId
  );

  const messagesToShowReceiver = messages.filter(
    (message) => message.receiverId === "1" && selectedUserId === message.senderId
  );

  const newMessages = [...messagesToShowSender, ...messagesToShowReceiver].sort(
    (a: any, b: any) => new Date(a.time).valueOf() - new Date(b.time).valueOf()
  );

  return (
    <div className=" overflow-y-auto scrollbar-thumb-[#FFFBE8] scrollbar-track-[#FFFBE8] scrollbar-thin ">
      {selectedUserId ? (
        <div>
          <div>
            <div>
              {newMessages.map((message) =>
                message.senderId === "1" ? (
                  <div className="flex text-[#005028] pl-4 " key={message.id}>
                    <div className="p-4">
                      <p className="text-[#AEB3BE] text-[16px] flex justify-end ">
                        {message.time.toLocaleTimeString()}
                      </p>
                      <p className="text-[#3C4E40] text-[16px] bg-[#FEF4F2] rounded-[20px] px-6 py-1">
                        {message.content}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-end items-center gap-5" key={message.id}>
                    <div className="relative">
                      <img src={image} />
                      <img
                        src={status === "Active" ? active : offline}
                        className=" absolute -right-1 -bottom-1"
                      />
                    </div>
                    <div>
                      <div className="py-4">
                        <p className="text-[#AEB3BE] text-[16px] ">
                          {name} {message.time.toLocaleTimeString()}
                        </p>
                        <p className="text-[#3C4E40] text-[16px] bg-[#F3F5F8] rounded-[20px] px-6 py-1">
                          {message.content}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="flex justify-center items-center py-4">
              <p className="text-[#667085] text-[16px]">
                9:03 am <br /> 1h 15m
              </p>
            </div>
          </div>

          <div className="absolute bottom-4 right-2">
            <div className="flex items-center gap-5 text-[#005028] pl-4 ">
              <div>
                <img src={Add} />
              </div>
              <div>
                <div className="text-[#3C4E40] bg-[#F3F5F8] rounded-[20px] px-4 py-2 flex justify-between items-center lg:w-[630px] ">
                  <input
                    placeholder="Start typing a message"
                    type="text"
                    className="text-[#667085] text-[16px] bg-[#F3F5F8] outline-none"
                    value={newMessage}
                    onChange={(e) => onNewMessageChange(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        onSendMessage();
                      }
                    }}
                  />
                  <div className="flex items-center gap-2 lg:gap-4">
                    <button onClick={onSendMessage}>Send</button>
                    <img src={Mic} />
                    <img src={Meh} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="flex justify-center items-center py-32 font-bold text-xl">
          Select a user to start chatting.
        </p>
      )}
    </div>
  );
};

export default ChatSession;
