import { ScheduleAudiocast } from "components";


const ScheduleAudiocastView = () => {
  return (
    <div>
      <ScheduleAudiocast />
    </div>
  );
};

export default ScheduleAudiocastView;