import { quiz } from "assets/images";
import { Link } from "react-router-dom";
import { QUIZ } from "routes/CONSTANTS";

const Quiz = () => {
  return (
    <div className="bg-[#F6F9FB] px-1 py-1 rounded-lg max-w-[170px]">
      <div className=" flex justify-center text-center">
        <img src={quiz} alt="quiz-img" />
      </div>
      <p className="text-[12px]  text-center">
        Take a quiz to access your knowledge of mental health
      </p>
      <div className="mt-2 flex w-[100%]">
        <Link to={QUIZ} className="bg-white w-full text-xs text-center text-green p-2 rounded-lg">
          Take Quiz
        </Link>
      </div>
    </div>
  );
};

export default Quiz;
