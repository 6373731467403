import { AudioImage } from "assets/icons";
import { GoBack } from "assets/images";
import { Link } from "react-router-dom";
import { AUDIOCASTS } from "routes/CONSTANTS";

const StartLiveForm = () => {
  return (
    <div className="">
      <Link to={AUDIOCASTS}>
        <img src={GoBack} className="py-4" />
      </Link>
      <h1 className="font-[500] text-[20px] md:text-[26px] pb-1 whitespace-nowrap">
        Create an Audiocast
      </h1>
      <div className="bg-[#E2E2E2] rounded-[10px] min-h-[500px] px-8 py-4">
        <form>
          <div className="lg:flex items-start gap-24">
            <div>
              <div>
                <label className="font-[500] text-[14px]">1. Audiocast Title</label>
                <input
                  className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797]"
                  placeholder="Ex: Food For Thought: Nourishing Your Mind and Body"
                />
              </div>
              <div className="py-2">
                <label className="font-[500] text-[14px]">2. Brief Description</label>
                <textarea
                  className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797] "
                  placeholder="Ex: Discussing the struggles of mental health in the Black/African community and how to find resources to overcome stigma."
                ></textarea>
              </div>
              <div className="py-1">
                <label className="font-[500] text-[14px]">3. Select Topics</label>
                <input
                  className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797]"
                  placeholder="Ex: Anxiety, Depression, OCD, etc."
                />
              </div>
              <div className="py-1">
                <label className="font-[500] text-[14px]">4. Add Speakers</label>
                <input className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797]" />
              </div>
              <div className="py-1">
                <label className="font-[500] text-[14px]">5. Add Hosts</label>
                <input className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797]" />
              </div>
              <div className="py-1">
                <label className="font-[500] text-[14px]">6. Add Guests</label>
                <textarea className="text-[#979797] text-[12px] bg-[#FFF] w-full lg:min-w-[412px] outline-[#FFF] px-4 block my-1 py-1 placeholder-[#979797] "></textarea>
              </div>
            </div>
            <div>
              <div className="pb-2">
                <label className="font-[500] text-[14px]">
                  7. Is this a private or public audiocast?
                </label>
                <div className="flex items-center gap-2 pl-4 ">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-4 h-4 bg-[#005028] border-[#E2E2E2] "
                  />
                  <label htmlFor="default-radio-1">Private</label>
                </div>
                <div className="flex items-center gap-2 pl-4">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value=""
                    name="default-radio"
                    className="w-4 h-4 bg-[#005028] border-[#E2E2E2] "
                  />
                  <label htmlFor="default-radio-1">Public</label>
                </div>
                <div className="md:pl-4 py-2">
                  <label className="font-[500] text-[14px]">Invitation Code:</label>
                  <br />
                  <input
                    type="text"
                    value="K3GTY1LQDM"
                    id="inviteCode"
                    className="outline-[#FFF] text-[10px] sm:text-[14px] p-1"
                  />
                  <button className="font-[600] text-[14px] text-[#FFF] py-1 px-2 sm:ml-[-12px] bg-[#005028]">
                    Copy
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="file-upload" className="font-[500] text-[14px] custom-file-upload">
                  8. Upload Thumbnail Photo
                  <br />
                  <input type="file" id="file-upload" className="hidden" />
                  <div className="bg-white p-4 mt-2 flex items-center justify-center ">
                    <div className="border-2 border-dotted p-4">
                      <div className="flex justify-center p-2">
                        <img src={AudioImage} />
                      </div>
                      <p className="text-[14px] py-2">
                        Drag your image here or{" "}
                        <Link to="https://www.google.com/" target="_blank">
                          <span className="text-[#1472FF] text-[14px]">browse</span>
                        </Link>
                      </p>
                    </div>
                  </div>
                </label>
              </div>
              <div className="py-3 flex justify-center items-center mt-10">
                <button className="font-[600] text-[14px] text-[#FFF] py-[8px] px-[20px] bg-[#005028] whitespace-nowrap">
                  Start Live Audiocast
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StartLiveForm;
