import { forwardArrow } from "assets/icons";
import { userDashboard } from "assets/images";
import { Link } from "react-router-dom";
import { DAILY_CHECK_IN_QUESTIONS_HOME, QUIZ, TRENDS } from "routes/CONSTANTS";

const DashboardCheckIn = () => {
  return (
    <div className="lg:flex gap-4">
      <div className="bg-[#7A8E7E] rounded-[10px] p-4 lg:basis-[62%] max-h-[233px] flex items-center">
        <div className="basis-1/2">
          <h2 className="text-[18px] font-[600] text-[#ffffff]">Mental Health Test</h2>
          <p className="text-[12px] text-[#ffffff] py-2">
            Make a difference! Take our Mental Health Research quiz and shape the future of mental
            health care. Join us today!
          </p>
          <Link to={QUIZ}>
            <button className="py-[5px] px-[19px] my-2 rounded-[8px] text-[12px] font-[600] text-[#005028] text-center bg-[#ffffff]">
              Take A Quiz
            </button>
          </Link>
        </div>
        <div className="basis-1/2">
          <img src={userDashboard} />
        </div>
      </div>
      <div className="lg:basis-[38%]">
        <Link
          to={DAILY_CHECK_IN_QUESTIONS_HOME}
          className="flex items-center justify-between rounded-[10px] bg-[#206543] py-[17px] px-[12px] text-[14px] font-[600] text-[#ffffff] min-w-[221px] "
        >
          Daily Check-in Question <img src={forwardArrow} className="" />
        </Link>
        <Link
          to={TRENDS}
          className="flex items-center justify-between rounded-[10px] bg-[#206543] py-[17px] px-[12px] text-[14px] font-[600] text-[#ffffff] min-w-[221px]  my-2"
        >
          Discover the Trends
          <img src={forwardArrow} />
        </Link>

        <form>
          <div className="w-full border-[0.3px] border-[#8C988F] rounded-[10px] bg-[#E9EFEC]">
            <textarea
              id="comment"
              className="w-full py-2 text-[12px] text-[#6B6B6B] bg-[#E9EFEC] rounded-[10px] outline-none indent-2"
              placeholder="Start a post"
              required
            ></textarea>
            <div className="flex justify-end p-2">
              <button className="px-[10px] text-center text-[#005028] bg-[#ffffff] rounded-[8px]">
                Post
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DashboardCheckIn;
