import { BellGroup, DotsGroup, ForwardGroup, Groups } from "assets/icons";
import { DepressionGroup } from "assets/images";

const GroupHeader = () => {
  return (
    <div>
      <h4 className="bg-[#F0E2E2] font-bold text-[30px] text-[#4A4A4A] px-4 rounded-t-[8px] h-[178px] flex items-center">
        Welcome to the depression group
      </h4>
      <div className="bg-[#F6F9FB] px-4 h-[290px] rounded-b-[8px]">
        <div className="flex justify-between items-start">
          <img src={DepressionGroup} className="rounded-full h-[150px] w-[150px]" />
          <div className="flex gap-4 items-center pt-3">
            <img src={ForwardGroup} />
            <img src={BellGroup} />
            <img src={DotsGroup} />
          </div>
        </div>
        <div>
          <h4 className="text-[#005028] text-[34px] font-bold">Depression Group with Tega</h4>
          <div className="flex gap-4 items-center py-2">
            <img src={Groups} />
            <p className="text-[#667085]py-2">Listed group</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupHeader;
