import { DashboardWrapper } from "components";
import LiveShowView from "./LiveShowView";

export const LiveShowContainer = () => {
  return (
    <DashboardWrapper>
      <LiveShowView />
    </DashboardWrapper>
  );
};
