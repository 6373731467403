interface INotification {
  count: number;
}
function NotificationCount({ count }: INotification) {
  return (
    <span className="text-white text-[16px] w-[24px] rounded-full h-[24px]  bg-[#E31D1C]  flex items-center justify-center">
      {count}
    </span>
  );
}

export default NotificationCount;
