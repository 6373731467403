import { Video } from "assets/images";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { calendarStyles, formatWeekday } from "./Modules/Index";
import { Appointment } from "utils/Appointments";
function Appointments() {
  return (
    <div className="w-full flex-col lg:flex-row p-4 gap-4 flex  rounded-[10px] shadow-lg bg-[#FEF4F2]">
      <div className="basis-3/5">
        <DateCalendar
          dayOfWeekFormatter={formatWeekday}
          sx={calendarStyles}
          defaultValue={dayjs("2022-04-17")}
          disabled
        />
      </div>
      <div className="border border-r-[2px] border-[#B1A9A8] h-[full]  rounded-[20px]"></div>
      <div className="basis-2/5 gap-3 py-4 flex flex-col ">
        <p className="text-[22px] ">Upcoming</p>
        {Appointment.slice(0, 3).map((arr, id) => (
          <div
            key={id}
            className="shadow-lg flex items-center px-3 gap-3 lg:gap-6 p-1 bg-[#EEF3F7] rounded-[10px]"
          >
            <p>
              {arr.day}
              <br />
              {arr.date}
            </p>
            <div className="border border-r-[3px] border-[#005028] h-[42px] rounded-[20px]"></div>
            <div>
              <p className="text-[#005028] text-[14px] font-semiBold">{arr.details}</p>
              <p className="font-[300] text-[12px] text-[#005028]">
                {arr.duration} - {arr.location}
              </p>
            </div>
            <div className="rounded-full bg-white p-2">
              <img src={Video} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Appointments;
