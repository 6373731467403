import { useState } from "react"
import Step from "./Step"
import { QuizAnswers, QuizQuestions } from "./Q&A";

const OnboardingQuiz = () => {
  const [stage, setStage] = useState(1);

  const handleNext = () => {
    setStage(stage + 1);
  };

  const handlePrevious = () => {
    setStage(stage - 1);
  }
  const renderForm = () => {
    return (
      QuizQuestions.map((question, index) => {
        if (index === stage - 1) {
          return (
            <Step
              key={index}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              currentStep={stage}
              question={question}
              answers={QuizAnswers[index]}
            />
          )
        }
        return true
      })
    )
  }

  return (
    <div className="px-[5rem]">
      <div className="flex justify-center h-1">
        {[...Array(9)].map((_dot, i) => {
          return (
            <div key={i} className={`${i + 1 > stage ? `opacity-25` : ``} bg-green w-14 mr-5`}></div>
          )
        })}
      </div>
      <div className="px-[3rem] flex justify-center items-center flex-col py-6">
        <h1 className="text-3xl text-green text-center">Help us match you to the <strong>right therapist</strong></h1>
        <div className="flex justify-center w-100 mt-2">
          <p className="text-base text-center text-green">We understand that seeking mental health support can be overwhelming, but we're here to make the process a little easier for you. Choose the option that best resonates with you, and we'll guide you towards resources that can help you on your mental health journey. Let's get started!</p>
        </div>
      </div>
      {renderForm()}
    </div>
  )
}

export default OnboardingQuiz;
