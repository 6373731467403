import { arrowLeft, right } from "assets/icons";
import PasswordProtection from "./PasswordProtection/PasswordProtection";
import TwoFA from "./TwoFA/TwoFA";
import { useState } from "react";

const Security = () => {
  const [tabOpen, setTabOpen] = useState("protection");
  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-4">
        <span className="flex items-center gap-4 text-lg font-bold">
          <img src={arrowLeft} /> Security
        </span>
        <p className="text-black text-base">Manage your account‘s security </p>
      </div>

      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-6">
          <div className="pr-12">
            <p className="text-green text-lg">Two-factor authentication</p>
            <p className="text-gray-200 text-sm">
              Help protect your account from unauthorized access by requiring a second
              authentication method in addition to your Twitter password. You can choose a text
              message, authentication app, or security key. Learn more
            </p>
          </div>

          <div
            className="cursor-pointer flex items-end justify-between"
            onClick={() => setTabOpen("2fa")}
          >
            <p className="text-green text-lg">Two-factor authentication</p>
            <img src={right} />
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="pr-12">
            <p className="text-green text-lg">Additional password protection</p>
            <p className="text-gray-200 text-sm">
              Enabling this setting adds extra security to your account by requiring additional
              information to reset your password. If enabled, you must provide either the phone
              number or email address associated with your account in order to reset your password.
            </p>
          </div>

          <div
            className="cursor-pointer flex items-end justify-between"
            onClick={() => setTabOpen("protection")}
          >
            <p className="text-green text-lg">Additional password protection</p>
            <img src={right} />
          </div>
        </div>

        <div>
          {tabOpen === "2fa" && <TwoFA />}
          {tabOpen === "protection" && <PasswordProtection />}
        </div>
      </div>
    </div>
  );
};

export default Security;
