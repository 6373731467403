import { NotificationIcon, profilePic } from "assets/images";

function TherapistDashboardHeader() {
  return (
    <div className="bg-[#F6F9FB] z-20  py-3 px-4 fixed w-full left-[0] top-0  md:w-2/3 md:left-[33.33%]  lg:w-3/4 lg:left-[25%] xl:w-4/5 xl:left-[20%] ">
      <div className="bg-lightOrange w-full mt-2 justify-between px-6 flex  py-3 items-center rounded-[10px]">
        <p>Welcome Back Dr.</p>
        <div className="flex">
          <input
            type="text"
            placeholder="Search"
            className=" h-[2rem] hidden xl:block  w-[300px] px-10 rounded-md p-5 shadow-sm "
            style={{ background: "#EFF2F5" }}
          />
          <div className="flex justify-end gap-10 items-center  w-[30%]">
            <img src={NotificationIcon} className="cursor-pointer" />
            <img src={profilePic} className="cursor-pointer" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TherapistDashboardHeader;
