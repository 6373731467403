import { OnboardingLayout, OnboardingQuiz } from "components";

const QuizView = () => {
  return (
    <div>
      <OnboardingLayout>
        <OnboardingQuiz />
      </OnboardingLayout>
    </div>
  );
};

export default QuizView;
