import { arrowLeft } from "assets/icons";
import { DBInput } from "components/modules";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const Information = () => {
  const formikInitialValues = {
    firstname: "",
    lastname: "",
    email: "",
    contact: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: ""
  };

  const accountsInformationValidation = Yup.object({
    firstname: Yup.string().required("Please enter your First Name").min(2, "Invalid First Name"),
    lastname: Yup.string().required("Please enter your Last Name").min(2, "Invalid Last Name"),
    email: Yup.string().required("Please enter your Email Address").email("Invalid email address")
  });

  const handleSubmit = () => {};
  return (
    <div>
      <div>
        <span className="flex items-center gap-4 text-2xl font-bold">
          <img src={arrowLeft} /> Account Information
        </span>
      </div>
      <div>
        <Formik
          initialValues={formikInitialValues}
          onSubmit={handleSubmit}
          validationSchema={accountsInformationValidation}
        >
          <Form className="flex flex-col gap-4">
            <div className="rounded-full h-40 w-40 border-2 border-green text-center"></div>
            <div className="grid grid-cols-2 gap-6">
              <DBInput nameAndId="firstname" label={""} placeholder={""} />
              <DBInput nameAndId="lastname" label={""} placeholder={""} />
            </div>
            <DBInput nameAndId="email" label={""} placeholder={""} />
            <DBInput nameAndId="contact" label={""} placeholder={""} />
            <DBInput nameAndId="address" label={""} placeholder={""} />
            <div className="grid grid-cols-2 gap-6">
              <DBInput nameAndId="city" label={""} placeholder={""} />
              <DBInput nameAndId="state" label={""} placeholder={""} />
              <DBInput nameAndId="zipcode" label={""} placeholder={""} />
              <DBInput nameAndId="country" label={""} placeholder={""} />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Information;
