import { Comment, GroupDoc, GroupPicture, GroupPoll, GroupVideo, Like, Meh } from "assets/icons";
import {
  AnnBrown,
  Debra,
  GroupCommentImage,
  GroupCommentImage2,
  GroupImage,
  GroupImage2
} from "assets/images";

const GroupStory = () => {
  const GroupsProfile = [
    {
      id: 1,
      image: Debra,
      name: "Debra Jones",
      status: "Member",
      time: "52m",
      text: "Lorem ipsum dolor sit amet consectetur. Dolor ac augue donec iaculis nunc tincidunt. Velit libero id mi blandit dui a varius maecenas risus. Tindacudar varsus adalina cosetwalani. ",
      groupImage: GroupImage,
      commentImage: GroupCommentImage
    },
    {
      id: 2,
      image: AnnBrown,
      name: "Ann Brown",
      status: "Member",
      time: "Now",
      text: "Lorem ipsum dolor sit amet consectetur. Dolor ac augue donec iaculis nunc tincidunt. Velit libero id mi blandit dui a varius maecenas risus. Tindacudar varsus adalina cosetwalani. ",
      groupImage: GroupImage2,
      commentImage: GroupCommentImage2
    }
  ];
  return (
    <div>
      <div className="my-4 h-[239px] bg-[#F6F9FB] px-4 pt-8">
        <div className="flex gap-4 ">
          <img src={Debra} className="h-[79px] w-[79px] rounded-full" />
          <div className="w-full">
            <input
              placeholder="Start a post in this group"
              className="text-[#667085] text-[24px] indent-5 rounded-[100px] h-[76px] outline-none border border-1 border-[#667085] bg-[#F6F9FB] w-full"
            />
            <div className="flex justify-between items-center pt-8">
              <div className="flex gap-3 items-center">
                <img src={GroupPicture} />
                <p className="text-[#667085] text-[18px]">Photo</p>
              </div>
              <div className="flex gap-3 items-center">
                <img src={GroupDoc} />
                <p className="text-[#667085] text-[18px]">Document</p>
              </div>
              <div className="flex gap-3 items-center">
                <img src={GroupVideo} />
                <p className="text-[#667085] text-[18px]">Video</p>
              </div>
              <div className="flex gap-3 items-center">
                <img src={GroupPoll} />
                <p className="text-[#667085] text-[18px]">Poll</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <button className="bg-[#005028] text-white rounded-[30px] p-4">ALL</button>
        <button className="bg-white text-[#005028] border border-1 border-[#005028] rounded-[100px] p-4">
          Recommended
        </button>
      </div>
      {GroupsProfile.map((item) => (
        <div className="bg-[#F6F9FB] h-[800px] my-4 py-2 px-4" key={item.id}>
          <div className="flex gap-4 items-center">
            <img src={item.image} />
            <div>
              <h4 className="text-[#005028] text-[34px] font-bold">{item.name}</h4>
              <p className="text-[#667085] text-[24]">{item.status}</p>
              <small className="text-[#667085] text-[24]">{item.time}</small>
            </div>
          </div>
          <div>
            <p className="text-[18px] text-[#4A4A4A] py-4">
              {item.text}
              <button className="border-b-2 border-[#005028] text-[#005028] text-[11px] outline-none pl-4">
                ...See More
              </button>
            </p>
          </div>
          <div>
            <div>
              <img src={item.groupImage} className="py-4" />
            </div>
            <div className="flex justify-around items-center p-2">
              <button className="flex items-center gap-2">
                <img src={Like} />
                <p className="text-[20x] text-[#005028]">Like</p>
              </button>
              <button className="flex items-center gap-2">
                <img src={Comment} />
                <p className="text-[20px] text-[#005028]">Comment</p>
              </button>
            </div>
          </div>
          <div className="flex justify-end">
            {" "}
            <button className="text-[#FF9C00] border-b-2 border-[#FF9C00] text-[20px] ">
              View Analytics
            </button>
          </div>
          <div className="flex items-center gap-3 py-4">
            <img src={item.commentImage} />
            <div className="border border-1 border-[#667085] rounded-[100px] h-[56px] flex items-center justify-between px-8 w-full">
              <input placeholder="Add Comment..." className="outline-none bg-[#F6F9FB]" />
              <div className="flex items-center gap-2">
                <img src={Meh} />
                <img src={GroupPicture} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupStory;
