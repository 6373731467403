import { Calender } from "assets/images";
import { Link } from "react-router-dom";
import { APPOINTMENTS } from "routes/CONSTANTS";
const Appointment = () => {
  return (
    <Link
      to={APPOINTMENTS}
      className="bg-[#F3DEDE] rounded-[10px] lg:min-w-[258px] min-h-[110px] cursor-pointer"
    >
      <div className="flex justify-center items-center gap-2 py-4">
        <img src={Calender} />
        <p className="text-[24px] text-[#3C4E40] text-center ">Appointment</p>
      </div>
      <p className="text-[15px] text-[#3C4E40] text-center">
        Book an appointment with our Professional Therapists
      </p>
    </Link>
  );
};

export default Appointment;
