import {
  Appointment,
  DashboardAppointments,
  DashboardCheckIn,
  DashboardJournal,
  DashboardTrend,
  MoodBoard,
  Audiocast
} from "components";

const Dashboard = () => {
  return (
    <div className="flex px-[0rem] lg:px-[1.8rem] flex-col lg:flex-row gap-4 lg:h-[803px] pt-3">
      <div className="lg:basis-3/5">
        <DashboardCheckIn />

        <div className="grid lg:grid-cols-2 gap-4 mt-4 py-4 px-4 bg-white rounded-[10px] ">
          <Appointment />
          <Audiocast />
          <DashboardJournal />
          <MoodBoard />
        </div>
        <DashboardAppointments />
      </div>

      <div className="lg:basis-2/5 ">
        <DashboardTrend />
      </div>
    </div>
  );
};

export default Dashboard;
