import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "redux/store";
import RouterConfig from "./routes/RouterConfig";
import "./index.css";
import { GoToTop } from "hooks";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <div className="font-Archivo">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <BrowserRouter>
            <GoToTop />
            <RouterConfig />
          </BrowserRouter>
          <ToastContainer />
        </Provider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
