import GroupStory from "./GroupStory";
import GroupHeader from "./GroupHeader";
import GroupMembers from "./GroupMembers";
import AboutGroup from "./AboutGroup";
import GroupAdmin from "./GroupAdmin";

const GroupsPage = () => {
  return (
    <div className="bg-white">
      <div className="flex gap-4 p-3">
        <div className="basis-[57%]">
          <GroupHeader />
          <GroupStory />
        </div>
        <div className="basis-[40%]">
          <GroupMembers />
          <AboutGroup />
          <GroupAdmin />
        </div>
      </div>
    </div>
  );
};

export default GroupsPage;
