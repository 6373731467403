import { close } from "assets/icons";
import { AndrewJohn } from "assets/images";
import React from "react";

interface ProfileEditFormProps {
  Edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileEditForm = ({ Edit, setEdit }: ProfileEditFormProps) => {
  return (
    <div
      className={`bg-white w-[409px] max-h-[820px] rounded-[20px] py-[12px] px-[21px] border border-[0.4px] border-[#FFF] shadow-2xl shadow-[#D8D8D8] absolute -inset-y-16 inset-x-[30%] z-[100] dropdown-menu  ${
        Edit ? "active" : "inactive"
      }`}
    >
      <div>
        <div className="flex justify-end">
          <button
            onClick={() => {
              setEdit(!Edit);
            }}
          >
            <img src={close} />
          </button>
        </div>
        <h1 className="font-bold text-[22px] text-center py-1">Update your profile details</h1>
        <div className="flex items-center gap-4 py-2">
          <img src={AndrewJohn} className="w-[94px] h-[94px] rounded-full" />
          <div>
            <h3 className="text-[#005028] text-[14px] font-[600]">Update file</h3>
            <p className="text-[#555353] text-[9px]">Make sure the file is below 2mb</p>
          </div>
        </div>
        <div className="flex justify-center py-4">
          <form>
            <p>
              <label>Your full name*</label>
              <br />
              <input
                type="text"
                placeholder="John Andrew"
                required
                className="text-[#3C4E40] text-[16px] indent-5 h-[34] bg-[#FAFAFA] border-[0.5px] border-[#B7B7B7] py-[9px] rounded-[10px]
              min-w-[350px] outline-none my-2"
              />
            </p>
            <p>
              <label>Select Gender*</label>
              <br />
              <select className="text-[#3C4E40] text-[16px] h-[34] bg-[#FAFAFA] border-[0.5px] border-[#B7B7B7] py-[9px] rounded-[10px] indent-5 w-full outline-none my-2">
                <option>Male</option>
                <option>Female</option>
              </select>
            </p>
            <p>
              <label>Which country do you live in*</label>
              <br />
              <select className="text-[#3C4E40] text-[16px] h-[34] bg-[#FAFAFA] border-[0.5px] border-[#B7B7B7] py-[9px] rounded-[10px] indent-5 w-full outline-none my-2">
                <option> 🇳🇬 Nigeria</option>
                <option> 🇺🇸 USA</option>
              </select>
            </p>
            <p>
              <label>Bio*</label>
              <br />
              <textarea
                cols={40}
                rows={10}
                placeholder="Let me share my story of resilience and hope while navigating the challenging landscape of depression. Through my experiences, I hope to shed light on the realities of living with mental health issues and inspire understanding and the strength and resilience "
                className="rounded-[10px] border-[0.5px] border-[#B7B7B7] bg-[#FAFAFA] p-2 outline-none my-2"
              ></textarea>
            </p>
            <button className="bg-[#3D7A5C] text-white text-[15px] font-bold py-[17px] px-[108px] text-center rounded-[10px] my-2 w-full">
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditForm;
