const Overview = () => {
  return (
    <div>
      <div className="pl-12">
        <section>
          <h3 className="text-[20px] font-bold py-4">About</h3>
          <p className="text-[18px] text-[#3C4E40]">
            Let me share my story of resilience and hope while navigating the challenging landscape
            of depression. Through my experiences, I hope to shed light on the realities of living
            with mental health issues and inspire understanding and empathy. Join me on this journey
            as I uncover the complexities, triumphs, and lessons learned, ultimately showcasing the
            strength and resilience...
          </p>
          <button className="text-[#005028] text-[18px] font-[500] py-2">Show More</button>
        </section>
        <section>
          <h3 className="text-[20px] font-bold py-4">Insurance</h3>
          <p className="text-[18px] text-[#3C4E40] py-2">Molina Healthcare</p>
        </section>
      </div>
    </div>
  );
};

export default Overview;
