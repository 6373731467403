import { img4, img2, img1} from "assets/images";


const TopHost = () => {
  const Host = [
    {
      id: 1, image: img4, name: "Donald Zulu", listeners: "1.4k Monthly Listeners"
    },
    { id: 2, image: img1, name: "Janet Mills", listeners: "1.25k Monthly Listeners" },
    { id: 3, image: img2, name: "Max Hartfelt", listeners: "1.22k Monthly Listeners" }
  ];
  return (
    <div>
        <div className="py-2">
        <form>
          <label className="font-[500] text-[26px] ">Quick Join</label>
          <div className="flex items-center bg-[#EFEFEF] rounded-[15px] my-4 p-4">
            <input placeholder="Enter invitation code"className="text-[12px] text-[#4A4A4A] p-2 placeholder-[#4A4A4A] w-full" />
            <button className="bg-[#005028] text-white font-semibold text-[12px] py-2 px-4 text-center -ml-4 ">Join</button>
          </div>
        </form>
      </div>
          <div className=" flex justify-between  items-center ">
          <h1 className="font-[500] text-[26px] py-4">Top Hosts</h1>
          <button className="text-[15px] text-[#005028]">show all</button>
        </div>
        <div>
        <div className="bg-white flex flex-col justify-between px-8 py-4 rounded-lg">
        {Host.map((item) => (
          <div className=" flex gap-4 items-center" key={item.id}>
            <div className="">
              <img src={item.image} />
            </div>
            <div className="w-full py-2">
              <div className="flex justify-between items-center">
              <p className="font-[600] text-[15px]">{item.name}</p>
              <div>
              <button className="text-[#005028] hover:text-white text-[15px]"><span>+</span> {" "}
                Follow
              </button>
            </div>
              </div>
              <p className="text-[14px] text-[#4A4A4A]">{item.listeners}</p>
            </div>
          </div>
        ))}
      </div>
        </div>
        
    </div>
  );
};

export default TopHost;
