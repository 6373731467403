import { Field, FieldProps } from "formik";

interface FormInputProps {
  nameAndId: string;
  label: string;
  onChange?: (value: string) => void;
}

function SVCheckbox({ nameAndId, label, onChange }: FormInputProps) {
  return (
    <div className="flex items-center gap-2 cursor-pointer">
      <Field name={nameAndId}>
        {({ field, form }: FieldProps) => {
          const isChecked = Array.isArray(field.value) && field.value.includes(nameAndId);
          return (
            <>
              <input
                id={nameAndId}
                type="checkbox"
                checked={isChecked}
                className="bg-white accent-green-100 h-4 w-4 cursor-pointer"
                onChange={() => {
                  const selectedOptions = Array.isArray(field.value) ? field.value : [];
                  const updatedOptions = isChecked
                    ? [...selectedOptions, nameAndId]
                    : selectedOptions.filter((option) => option !== nameAndId);
                  void form.setFieldValue(nameAndId, updatedOptions);
                  onChange && onChange(isChecked ? nameAndId : "");
                }}
              />
              <label className="text-lg md:text-xl" htmlFor={nameAndId}>
                <span>{label}</span>
              </label>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default SVCheckbox;
