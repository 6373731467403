import { TrendingPost } from "components/layout/Trends";



const TrendsView = () => {
  return <div>
    <TrendingPost />
  </div>;
};

export default TrendsView;
