export const HOME = "/home";
export const LOGIN = "/auth/login";

// user Dashboard
export const DASHBOARD = "/";
export const APPOINTMENTS = "/user/appointments";
export const AUDIOCASTS = "/user/audiocasts";
export const CHATS = "/user/chats.tsx";
export const LIVE_SHOW = "/User/live-show";
export const TRENDS = "/user/trends";
export const GROUPS = "/user/groups";
export const EVENTS = "/user/events";
export const REFER_A_FRIEND = "/user/refer";
export const LOGOUT = "/logout";
export const QUIZ = "/user/quiz";
export const SETTINGS = "/user/settings";
export const SETTINGS_ACCESS = "/user/settings-access";
export const SETTINGS_ACCESSIBILITY = "/user/settings-accessibility";
export const SETTINGS_ACCOUNTS = "/user/settings-accounts";
export const SETTINGS_NOTIFICATIONS = "/user/settings-notifications";
export const SETTINGS_SECURITY = "/user/settings-security";
export const USER_SURVEY = "/user/survey";

// Therapist Dashboard
export const THER_DASHBOARD = "/therapist";
export const THER_APPOINTMENTS = "/therapist/appointments";
export const THER_AUDIOCASTS = "/therapist/audiocasts";
export const THER_CHATS = "/therapist/chats.tsx";
export const THER_GROUPS = "/therapist/groups";
export const THER_LIVESHOW = "/therapist/liveshow";
export const THER_LOGOUT = "/therapist/liveshow";
export const THER_REFER = "/therapist/refer";
export const THER_SETTINGS = "/therapist/settings";
export const THER_SUPPORT = "/therapist/support";
export const THER_TRENDS = "/therapist/trends";

// Profile
export const MYPROFILE = "/user/myprofile";

// Therapist Profile
export const MY_THER = "/user/my_therapist";
export const BOOKTHERAPIST = "/user/booktherapist";

// Daily Checkin Questions
export const DAILY_CHECK_IN_QUESTIONS_HOME = "/daily_check_in/home";
export const DAILY_CHECK_IN_QUESTIONS = "/daily_check_in/questions";

export const AUDIOCASTLIVE = "audiolive";
export const SCHEDULEAUDIOCAST = "/scheduleaudiocast";
export const CREATELIVEFORM = "/createliveform";
export const LIVEAUDIOCASTS = "/liveaudiocasts";
