import { useState } from "react";
import {
  Email,
  Instagram,
  LinkedIn,
  Location,
  NegativeTick,
  PositiveTick,
  Star,
  Youtube
} from "assets/images";
import { ReviewsObject } from "./DisplayReviews";
import { Link } from "react-router-dom";
import { BOOKTHERAPIST } from "routes/CONSTANTS";
import LicenseStatus from "./LicenseStatus";
import { AlexStarnes } from "components";
import "./ProfileSummary.css";

export interface Therapist {
  name: string;
  image: string;
  status: string;
  location: string;
  email: string;
  socials: string[];
  services: string[];
  rating: number;
  about: string;
  background: string;
  approaches: string[];
  specialties: string[];
  insurance: string[];
  checks: string[];
  reviewsArray: ReviewsObject[];
  isVerified: boolean;
}

interface Props {
  therapist: Therapist;
}

const ProfileSummary = ({ therapist }: Props) => {
  const [isCalendarOpen, setCalendarOpen] = useState(true);
  const { name, image, status, location, rating, email, socials, isVerified } = therapist;
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  return (
    <section className="pb-5">
      <div className={` ${isHovered ? "hoverOverlay" : ""}`}>
        {isHovered && (
          <LicenseStatus
            therapist={AlexStarnes}
            setHoverState={() => setHovered(false)}
            isVerified={isVerified}
          />
        )}
      </div>
      <div className="flex items-center">
        <div className="pictureDiv mb-4 md:mb-0 flex justify-center max-w-[30%] md:w-[30%] mr-5">
          <div className="md:w-[90%] md:h-[90%] w-[60px] h-[60px]">
            <img
              className="w-full min-w-[60px] mr-0 mb-5 object-contain"
              src={image}
              alt="An image of the therapist"
            />
          </div>
        </div>
        <div className="profileDiv w-[70%] ml-[-1%] md:ml-[0%]">
          <div className="pr-[5%] flex justify-between items-baseline">
            <h1 className="mb-2 text-lg leading-[14px] md:leading-[32px] md:text-3xl tracking-widest font-semibold text-[#667085]">
              {name}
            </h1>
            <div className="flex items-center">
              <img
                className="md:w-[22px] md:h-[22px] w-[15px] h-[15px] mr-1"
                src={Star}
                alt="Rating Icon"
              />
              <p className="border-b text-xs md:text-base leading-[21px]">{rating}</p>
            </div>
          </div>
          <div
            className={`px-5 py-0 md:py-2  text-sm md:text-base rounded-full my-1 text-[#3C4E40] flex items-center gap-4 
            ${isCalendarOpen ? "w-[200px]" : "w-[280px]"}
            ${isCalendarOpen ? "bg-[#B2ECC4]" : "bg-[#C4C4C4]"}`}
          >
            <img
              className="w-[15px] h-[11px]"
              src={isCalendarOpen ? PositiveTick : NegativeTick}
              alt="Tick Icon"
            />
            {isCalendarOpen ? "Accepting Clients" : "No Longer Accepting Clients"}
          </div>
          <p
            onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            style={{ cursor: "pointer" }}
            className="my-0 md:my-3 text-xs md:text-base text-[#3C4E40]"
          >
            {status}
          </p>
          <div className="text-xs md:text-base">
            <div className="flex items-end my-1 md:my-4">
              <img className="w-[14px] h-[16px]" src={Location} alt="Location Icon" />
              <p className="ml-4 text-[#667085] leading-[17px]">{location}</p>
            </div>
            <div className=" flex items-end my-1 md:my-4">
              <img className="w-[15px] h-[15px]" src={Email} alt="Location Icon" />
              <p className="ml-4 text-[#3C4E40] leading-[17px]">{email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="specialDiv flex flex-col md:flex-row md4gap-4">
        <Link to={BOOKTHERAPIST} className="w-full md:w-[30%] md:order-1 order-2">
          <button
            className={`my-3 md:my-0 w-full py-2 md:py-4 px-2 text-[10px] md:text-sm lg:text-base lg:py-5 text-white rounded-lg cursor-pointer ${
              isCalendarOpen ? "bg-green-100" : "bg-[#E7E8E9]"
            }`}
            onClick={() => setCalendarOpen(!isCalendarOpen)}
          >
            Book an Appointment
          </button>
        </Link>
        <div className="socialDiv ml-[76.5px] sm:ml-[75.5px] md:w-[70%] md:ml-5 flex gap-3 items-start md:order-2 order-1 md:border-b border-b-whiteLight">
          <a href={socials[0]}>
            <img className="md:w-6 w-4" src={LinkedIn} alt="LinkedIn Icon" />
          </a>
          <a href={socials[1]}>
            <img className="md:w-6 w-4" src={Instagram} alt="Instagram Icon" />
          </a>
          <a href={socials[2]}>
            <img className="md:w-8 w-6" src={Youtube} alt="Youtube Icon" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default ProfileSummary;
