import Services from "./Services";
import About from "./About";
import Specialization from "./Specialization";
import Appointment from "./AppointmentHeaderImages";
import InsuranceAndOtherChecks from "./InsuranceAndOtherChecks";
import { Therapist } from "./ProfileSummary";

interface Props {
  therapist: Therapist;
}

const MoreInfo = ({ therapist }: Props) => {
  return (
    <div>
      <Services therapist={therapist} />
      <About therapist={therapist} />
      <Specialization therapist={therapist} />
      <Appointment />
      <InsuranceAndOtherChecks therapist={therapist} />
    </div>
  );
};

export default MoreInfo;
