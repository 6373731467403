import React, { useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { ReviewsObject } from "./DisplayReviews";

interface Props {
  onReviewSubmit: (review: ReviewsObject) => void;
}

const InputReview = ({ onReviewSubmit }: Props) => {
  const [selectedRating, setSelectedRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);
  const maxCharacters = 130;

  const handleReviewTextChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    if(value.length <= maxCharacters){
        setReviewText(value);
    }
  };

  const handleRatingChange = (rating: number) => {
    if (!isSubmitted) {
        setSelectedRating(rating)
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedRating === 0 || reviewText === "") {
      return;
    }

    const submittedReview: ReviewsObject = {
      name: "Client",
      date: new Date(Date.now()),
      rating: selectedRating,
      text: reviewText
    };
    onReviewSubmit(submittedReview);
    setSelectedRating(0);
    setReviewText("");
    setSubmitted(true)
  };
  return (
    <section>
      <h3 className="text-black font-semibold mb-6">Write a Review and Rate</h3>
      <form className="flex flex-col">
        <textarea
          className="w-full h-28 p-4 bg-[#F7F7F7] border border-green-100 my-2 rounded-md placeholder-[#C4C4C4] placeholder-opacity-75"
          placeholder="Type in your review"
          value={reviewText}
          onChange={handleReviewTextChange}
          disabled={isSubmitted}
        />
        <div className="mb-4 flex items-center gap-4">
          <p>Add Rating: </p>
          <div className="flex gap-1">
            {[1, 2, 3, 4, 5].map((rating) => (
              <span key={rating} onClick={() => handleRatingChange(rating)}>
                {selectedRating >= rating ? (
                  <AiFillStar size={20} color="#FF9C00" />
                ) : (
                  <AiOutlineStar size={20} color="#FF9C00" />
                )}
              </span>
            ))}
          </div>
        </div>
        <button
          className="ml-auto px-32 py-4 bg-[#FF9C00] text-white rounded-md"
          type="submit"
          onClick={handleSubmit}
          disabled={isSubmitted}
        >
          Send
        </button>
      </form>
    </section>
  );
};

export default InputReview;
