import { Logout } from "components";

const LogoutView = () => {
  return (
    <div>
      <Logout />
    </div>
  );
};

export default LogoutView;
