import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

const SurveyForm: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const validationSchema = Yup.object().shape({
    // Define your validation schema here
    // Example: name: Yup.string().required('Name is required'),
  });

  const handleNextStep = (values: any) => {
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <StepOne onNextStep={handleNextStep} />;
      case 2:
        return <StepTwo onPreviousStep={handlePreviousStep} stepOneData={formData} />;
      default:
        return null;
    }
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(values) => console.log(values)} // You can handle the form submission here
    >
      <Form>{renderStep()}</Form>
    </Formik>
  );
};

export default SurveyForm;
