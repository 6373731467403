import { AlexStarnesImg } from "assets/images";

const AlexStarnes = {
  name: "Alex Starnes",
  image: AlexStarnesImg,
  status: "Licensed Associate Professional Counselor (LAPC)",
  location: "Buford, GA",
  email: "alexstarnes@healthcounselor.com",
  socials: ["/", "/", "/"],
  services: ["Individual", "Couples", "Teens", "Family"],
  rating: 4.3,
  about:
    "My name is Alex, and I am a licensed therapist with years of experience helping clients overcome emotional distress, anxiety, depression, and other mental health concerns. I specialize in working with individuals, couples, and families who are struggling with a variety of issues, including addiction, relationship problems, family conflict, trauma, and more. I also offer workshops and evidence-based skill training to help clients develop coping strategies, improve their communication skills, and build resilience.",
  background:
    "I offer a free consultation to new clients, where we can discuss your concerns, goals, and how I can help you achieve them. I believe that therapy is a collaborative process, and I will work with you to create a personalized treatment plan that is tailored to your unique needs and circumstances.",
  approaches: [
    "Cognitive Behavioural Therapy (CBT)",
    "Acceptance and Commitment Thereapy (ACT)",
    "Psychodynamic Therapy",
    "Family Therapy",
    "Narrative Therapy"
  ],
  specialties: [
    "Mental Health Issues",
    "Anger Management",
    "Trauma",
    "Self-Esteem Issues",
    "Relationship Issues"
  ],
  insurance: ["Avon HMO", "Hygeia HMO", "AXA Global healthcare"],
  checks: ["Language: English", "Session type: Online", "Rate: $70/session"],
  reviewsArray: [
    {
      name: "Client",
      date: "2022-05-02",
      rating: 5.0,
      text: "Alex's techniques have been incredibly helpful in guiding me through a difficult time in my life, allowing me to overcome loss, gain self-confidence, and live with intention."
    },
    {
      name: "Client",
      date: "2022-01-12",
      rating: 4.8,
      text: "Alex is an eceptional therapist who has helped me to overcome my deepest emotional challenges. I am grateful for his expertise, support and guidance."
    },
    {
      name: "Client",
      date: "2020-04-05",
      rating: 3.2,
      text: "Alex is an eceptional therapist who has helped me to overcome my deepest emotional challenges. I am grateful for his expertise, support and guidance."
    }
  ],
  isVerified: false
};

export default AlexStarnes;
