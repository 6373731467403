import { Therapist } from "./ProfileSummary";

interface Props {
  therapist: Therapist;
}

const Services = ({ therapist }: Props) => {
  const { services } = therapist;
  const getServiceCategoryNote = (category: string) => {
    switch (category) {
      case "Individual":
        return "For myself";
      case "Couples":
        return "For me and my partner";
      case "Teens":
        return "For my child";
      case "Family":
        return "For my family";
      default:
        return "Input a valid service category";
    }
  };

  return (
    <section className="my-2 px-5 py-5 bg-white shadow-lg">
      <h2 className="my-2 text-[#3C4E40] font-semibold">Services</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
        {services.map((service, index) => (
          <div
            className="h-[60px] py-2 text-white text-center bg-green rounded-lg shadow-lg"
            key={index}
          >
            <p className="mb-1">{service}</p>
            <p className="text-[0.5rem]">{getServiceCategoryNote(service)}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
