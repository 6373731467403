import { TherapistDashboardWrapper } from "components";
import TherAppointmentsView from "./TherAppointmentsView";

export const TherAppointmentsContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherAppointmentsView />
    </TherapistDashboardWrapper>
  );
};
