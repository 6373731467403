import {
  Handshake,
  Heart,
  LightbulbTrends,
  Love,
  MessageCircle,
  TrendAdd,
  TrendsLike
} from "assets/icons";
import { Mia, Mike, profilePic } from "assets/images";

const TrendsPost = () => {
  const TrendingPost = [
    {
      id: 1,
      image: Mike,
      name: "Mike",
      duration: "45 minutes ago",
      posts:
        "Is there something you've been hesitant to try because you believe you can't do it? Write down why you think you can't do it, and then challenge those beliefs. What steps can you take to overcome obstacles and start working towards your goal? Remember, believing in yourself is half the battle! 💕YOU are amazing and you deserve all the happiness the world has to offer, now go out there and get it!!"
    },
    {
      id: 2,
      image: Mia,
      name: "Mia109",
      duration: "2 hours ago",
      posts:
        "I’m so depressed today. It’s little things that set me off. I got scammed and lost a lot of money and it made me upset that this world we live in today people get scammed and lose money. Makes me not want to live in a world like this. I’m constantly in a state of fear and anxiety."
    }
  ];
  return (
    <div>
      {TrendingPost.map((item) => (
        <div
          className="bg-white border-[0.04em] border-[#C4C4C4] rounded-[0.5em] py-4 mb-6"
          key={item.id}
        >
          <div className="px-4 ">
            <div className="flex justify-between items-center">
              <div className="md:flex gap-4 items-center">
                <img src={item.image} className="w-[70px] h-[70px]rounded-full" />
                <div className="pl-2 md:pl-0 pt-2 md:pt-0">
                  <h3 className="font-bold text-[#3C4E40]">{item.name}</h3>
                  <p className="font-[300] text-[12px] text-[#3C4E40]">{item.duration}</p>
                </div>
              </div>
              <div className="flex gap-1 items-center">
                <img src={TrendAdd} />
                <button className="font-[500] text-[18px] text-[#005028]">Follow</button>
              </div>
            </div>
            <div>
              <p className="py-4">{item.posts}</p>
              <div className="grid grid-cols-3 lg:grid-cols-12 gap-4 py-2">
                <p className="font-[400] text-[10px] text-[#005028]">#Support</p>
                <p className="font-[400] text-[10px] text-[#005028]">#Strategy</p>
                <p className="font-[400] text-[10px] text-[#005028">#SelfBelief</p>
              </div>
            </div>
            <div className="flex justify-between items-center text-[10px]">
              <div className="flex items-center">
                <img src={Love} />
                <img src={LightbulbTrends} className="ml-[-5px]" />
                <p className="pl-1">15</p>
              </div>
              <div>
                <p>8 comments</p>
              </div>
            </div>
          </div>
          <hr className="h-px bg-[#C4C4C4] border-0" />
          <div className="p-4 font-[600] text-[12px] text-[#3C4E40] grid grid-cols-2 md:grid-cols-4 items-center gap-4">
            <div>
              <img src={Heart} />
              <p>Love</p>
            </div>
            <div>
              <img src={TrendsLike} />
              <p>Support</p>
            </div>
            <div>
              <img src={Handshake} />
              <p>Relate</p>
            </div>
            <div>
              <img src={MessageCircle} />
              <p>Comment</p>
            </div>
          </div>
        </div>
      ))}
      <div className="flex gap-4">
        <img src={profilePic} className="h-[54px] w-[50px] rounded-full" />
        <div className="w-full">
          <input
            placeholder="Start a post"
            className="text-[#000] text-[16px] indent-5 rounded-[35px] h-[52px] outline-none border border-1 border-[#272727] bg-white w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default TrendsPost;
