interface upcomingAppointmentsProps {
  id: string;
  topic: string;
  startTime: string;
  endTime: string;
  meetingType: string;
  day: string;
  date: string;
}

const upcomingAppointments = [
  {
    id: "1",
    topic: "Mental Health Class",
    startTime: "09:00",
    endTime: "10:00",
    meetingType: "Zoom Meeting",
    day: "Tue",
    date: "JUN"
  },
  {
    id: "2",
    topic: "Mental Health Class",
    startTime: "09:00",
    endTime: "10:00",
    meetingType: "Zoom Meeting",
    day: "Tue",
    date: "JUN"
  },
  {
    id: "3",
    topic: "Session With Alex Starnes",
    startTime: "09:00",
    endTime: "10:00",
    meetingType: "Zoom Meeting",
    day: "Tue",
    date: "JUN"
  }
];

const CancelledAppointments = () => {
  return (
    <div className="p-16">
      <div className="flex flex-col gap-12">
        {upcomingAppointments.map((ap: upcomingAppointmentsProps) => (
          <div key={ap.id} className="flex items-center gap-4 bg-[#F1F1F1] rounded-lg p-2 w-[655px]">
            <div className="w-16 h-16 flex flex-col items-center justify-center bg-orange rounded-lg">
              <p className="text-white text-base">{ap.day}</p>
              <p className="text-white font-bold text-lg">{ap.date}</p>
            </div>

            <div className="w-96">
              <p className="text-green text-3xl font-bold">{ap.topic}</p>
              <span className="text-lg flex items-center gap-2 text-green">
                <p>
                  {ap.startTime} - {ap.endTime} - {ap.meetingType}
                </p>
              </span>
            </div>

            <div className="flex flex-col items-center justify-center w-36 gap-2 pl-16">
              <button className="text-sm text-green bg-[#d6f9e7] cursor-pointer hover:text-green-100 p-1 rounded-md w-28">
                RESCHEDULE
              </button>
              <button className="text-sm text-[#FF5630] bg-[#fbe5df] cursor-pointer p-1 rounded-md w-28">DELETE</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CancelledAppointments;
