import AudiocastLiveChat from "./AudiocastLiveChat";
import AudiocastLiveSession from "./AudiocastLiveSession";

const AudiocastLive = () => {
  return (
    <div className="p-8 mt-1 h-[90vh] rounded-2xl bg-white flex gap-2">
      <div className="basis-[70%]">
        <AudiocastLiveSession />
      </div>
      <div className="basis-[30%]">
        <AudiocastLiveChat />
      </div>
    </div>
  );
};

export default AudiocastLive;
