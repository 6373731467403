import { Dashboard } from "components";

const DashboardHomeView = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default DashboardHomeView;
