import { MyProfile } from "components";

const MyProfileView = () => {
    return (
        <div>
          <MyProfile />    
        </div>
      );
  
  };
  
  export default MyProfileView;
  