import { AndrewJohn, BackArrow, ProfileEdit } from "assets/images";
import { useState } from "react";
import "components/modules/dashboardWrapper/DashboardHeader.css";
import ProfileEditForm from "./ProfileEditForm";
import MyProfileDetails from "./MyProfileDetails";
// import { useSelector } from "react-redux";

const MyProfile = () => {
  // const user = useSelector((state: any) => state.auth.user.user);
  const [Edit, setEdit] = useState(false);
  return (
    <div>
      <div className="min-h-[800px] max-w-[929px] rounded-[10px] m-8 p-8 bg-[#fff] relative">
        <div className="flex gap-8 items-center">
          <div>
            <img src={BackArrow} />
          </div>
          <h1>My Profile</h1>
        </div>
        <div className="pl-12 flex gap-8 py-8">
          <div>
            <div className="relative">
              <img src={AndrewJohn} className="w-[94px] h-[94px] rounded-full" />
              <button>
                <img
                  src={ProfileEdit}
                  className=" absolute right-[2px] bottom-[28px] w-[21px] h-[21px] rounded-full"
                />
              </button>
            </div>
          </div>
          <div>
            <h2 className="text-[#3C4E40] font-bold text-[18px]">
              John Andrew{" "}
              <span className="text-[#494949] font-normal text-[12px]">(He/Him)</span>
            </h2>
            <p className="text-[#494949] py-2 text-[14px]">Nigeria</p>
            <div
              onClick={() => {
                setEdit(!Edit);
              }}
            >
              <button className="text-sm font-[600] bg-white border border-1 border-gray rounded-[5px] px-4 py-2 hover:bg-gray">
                Edit Profile
              </button>
            </div>
          </div>
          <ProfileEditForm Edit={Edit} setEdit={setEdit} />
        </div>
        <MyProfileDetails />
      </div>
    </div>
  );
};

export default MyProfile;
