import AudiocastLiveHeader from "./AudiocastliveHeader";
import {
  AudioChat,
  AudioForward,
  AudioMic,
  AudioMute,
  AudioRaisehands,
  AudioReactions,
  AudioShare
} from "assets/icons";
import { Participants } from "utils/Participants";

const AudiocastLiveSession = () => {
  return (
    <div>
      <AudiocastLiveHeader />
      <div>
        <div className="flex gap-8 w-full">
          <div>
            <div className="px-4 py-8 grid grid-cols-5 gap-12">
              {Participants.slice(0, 15).map((item) => (
                <div key={item.id}>
                  <img src={item.image} className="w-[65px] h-[65px] rounded-full" />
                  <p className="text-[#005028]">{item.name}.</p>
                  <div className="flex items-center gap-2">
                    <button>
                      <img src={AudioMic} />
                    </button>
                    <p className="text-[#979797]">{item.title === "Listener" ? "" : item.title}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-wrap items-center justify-between py-4 px-8 bg-[#F0F0F0] rounded-[10px] mt-8">
              <div className="flex flex-col items-center">
                <button>
                  <img src={AudioMute} />
                </button>
                <p>Mute</p>
              </div>
              <div className="flex flex-col items-center">
                <button>
                  <img src={AudioRaisehands} />
                </button>
                <p>Raise hands</p>
              </div>
              <div className="flex flex-col items-center">
                <button>
                  <img src={AudioReactions} />
                </button>
                <p>Reactions</p>
              </div>
              <div className="flex flex-col items-center">
                <button>
                  <img src={AudioChat} />
                </button>
                <p>Chat</p>
              </div>
              <div className="flex flex-col items-center">
                <button>
                  <img src={AudioShare} />
                </button>
                <p>share</p>
              </div>
              <div className="bg-[#D80B0B] rounded-[10px] py-2 px-4 text-white font-[500]">
                <button>Leave</button>
              </div>
            </div>
          </div>
          <div className="flex items-center ">
            <div className="flex flex-col items-center gap-2">
              <button>
                <img src={AudioForward} />
              </button>
              <p>(1/2)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudiocastLiveSession;
