import {
  slairplay,
  slcc,
  slchat,
  slmaximize,
  slmic,
  slpen,
  slsettings,
  slvideo
} from "assets/icons";
import { VideoIcon } from "assets/images";

const StartLive = () => {
  return (
    <div className="bg-[#000000] mx-[3rem] min-h-[60vh]">
      <div className="flex flex-col  justify-center items-center h-[50vh]">
        <button>
          <img src={VideoIcon} />
        </button>
        <p className="text-white text-center text-[16px]">
          Create a Live Stream
          <br />
          Please allow access to your webcam to start
        </p>
        <button className="bg-[#FECA00] py-1 px-2 rounded-[20px] mt-3">Start lIve</button>
      </div>
      <div className="flex justify-center">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 items-center bg-[#005028] rounded-[20px] m-3 py-4 md:py-2 px-4 gap-4 lg:gap-8">
          <button>
            <img src={slmic} />
          </button>
          <button>
            <img src={slvideo} />
          </button>
          <button>
            <img src={slpen} />
          </button>
          <button>
            <img src={slairplay} />
          </button>
          <button>
            <img src={slchat} />
          </button>
          <button>
            <img src={slcc} />
          </button>
          <button>
            <img src={slsettings} />
          </button>
          <button>
            <img src={slmaximize} />
          </button>
        </div>
      </div>
    </div>
  );
};
export default StartLive;
