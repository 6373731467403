import { TherapistDashboardWrapper } from "components";
import TherTrendsView from "./TherTrendsView";

export const TherTrendsContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherTrendsView />
    </TherapistDashboardWrapper>
  );
};
