import { dashsearchIcon } from "assets/images";

interface User {
  id: string;
  name: string;
  image?: string;
  status?: string;
  chats?: string;
}

interface UsersListProps {
  users: User[];
  onUserSelect: (user: User) => void;
  selectedUserId: string | null;
}
const ChatStatus = ({ users, onUserSelect, selectedUserId }: UsersListProps) => {
  return (
    <div className="sm:basis-[28%] bg-[#FFFAF2] rounded-[20px] h-full">
      <div className="flex gap-2 items-center lg:max-w-[245px] rounded-[8px] bg-[#F3F5F8] m-3 pl-4 ">
        <img src={dashsearchIcon} />
        <input
          type="text"
          placeholder="Search"
          className=" bg-[#F3F5F8] outline-none text-[#9EA8B7] text-[16px] p-2"
        />
      </div>
      <div className="my-4 overflow-y-auto scrollbar-thumb-[#FFFAF2] scrollbar-track-[#FFFAF2] scrollbar-thin md:h-[520px]">
        {users.map((user) => (
          <div
            key={user.id}
            onClick={() => onUserSelect(user)}
            className={`flex items-center gap-3 text-[#005028] pl-4 py-4 cursor-pointer ${
              selectedUserId === user.id ? "selected" : ""
            }`}
          >
            <div className="relative">
              <img src={user.image} />
              {user.status === "Active" ? (
                <div className=" absolute right-1 bottom-1 h-3 w-3 rounded-full bg-green-100"></div>
              ) : (
                <div className=" absolute right-1 bottom-1 h-3 w-3 rounded-full bg-[#FF0000]"></div>
              )}
            </div>
            <div>
              <p className="">{user.name}</p>
              <div className="flex items-center gap-24">
                <p className="text-[#707070] text-[16px] pl-2">{user.status}</p>
                {user.status === "Active" && (
                  <p className="text-[9px] text-center p-1 text-white w-[19px] h-[19px] rounded-full bg-[#FF0000]">
                    {user.chats}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatStatus;
