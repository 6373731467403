import { arrowLeft } from "assets/icons";
import { DBInput } from "components/modules";
import { Formik, Form } from "formik";

const Password = () => {
  const formikInitialValues = {
    currentPwd: "",
    newPwd: "",
    confirmPwd: ""
  };

  const handleSubmit = () => {};
  return (
    <div>
      <div className="flex gap-4 items-center">
        <img src={arrowLeft} />
        <p className="text-lg font-bold">Change Your Password</p>
      </div>
      <Formik initialValues={formikInitialValues} onSubmit={handleSubmit}>
        <Form className="flex flex-col gap-12 pt-12">
          <div>
            <DBInput nameAndId="currentpwd" label={""} placeholder="Current Password" />
            <p className="text-green-100 text-sm">Forgot Password?</p>
          </div>

          <div>
            <DBInput nameAndId="newPwd" label={""} placeholder="New Password" />
            <DBInput nameAndId="confirmPwd" label={""} placeholder="Confirm Password" />
          </div>
          <p className="text-sm text-black">
            Changing your password will log you out of all your active BHHM sessions except the one
            you’re using at this time.
          </p>
          <div className="flex justify-end">
            <button type="submit" className="text-white bg-green p-4 w-36">
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Password;
