const IMAGES_PATH = "/assets/images";
// Dashboard
export const GoForward = `${IMAGES_PATH}/goforward.png`;
export const GoBack = `${IMAGES_PATH}/goback.png`;
export const Calender = `${IMAGES_PATH}/calender-icon.png`;
export const Journal = `${IMAGES_PATH}/journal-icon.png`;
export const Moodboard = `${IMAGES_PATH}/moodboard.png`;
export const Moodboards = `${IMAGES_PATH}/moodboards.png`;
export const Video = `${IMAGES_PATH}/video.png`;
export const TrendingPost = `${IMAGES_PATH}/trendingPost.png`;
export const dashCertified = `${IMAGES_PATH}/dashCertified.png`;
export const userDashboard = `${IMAGES_PATH}/userDashboard.png`;
export const Checklist = `${IMAGES_PATH}/Checklist.png`;
export const audioCast = `${IMAGES_PATH}/audioCast.png`;

// Dashboard dashboardlogo
export const dashboardimg = `${IMAGES_PATH}/dashboardimg.png`;
export const dashboardlogo = `${IMAGES_PATH}/dashboardlogo.png`;
export const NotificationIcon = `${IMAGES_PATH}/NotificationIcon.png`;
export const profilePic = `${IMAGES_PATH}/profilePic.png`;
export const dashsearchIcon = `${IMAGES_PATH}/dashsearchIcon.png`;
export const logout = `${IMAGES_PATH}/logout.png`;
export const activity = `${IMAGES_PATH}/activity.png`;
export const quiz = `${IMAGES_PATH}/quiz.png`;

// error page
export const BackArrow = `${IMAGES_PATH}/back-arrow.png`;
export const CatPhoto = `${IMAGES_PATH}/cat-photo.png`;

// Referral
export const Edit = `${IMAGES_PATH}/edit.png`;
export const shareLink = `${IMAGES_PATH}/sharedLink.png`;


// audiocasts
export const audio = `${IMAGES_PATH}/audio.png`;
export const live = `${IMAGES_PATH}/live.png`;
export const schedule = `${IMAGES_PATH}/schedule.png`;
export const img3 = `${IMAGES_PATH}/img3.png`;
export const img2 = `${IMAGES_PATH}/img2.png`;
export const img4 = `${IMAGES_PATH}/img4.png`;
export const img1 = `${IMAGES_PATH}/img1.png`;
export const listener1 = `${IMAGES_PATH}/listener1.png`;
export const listener2= `${IMAGES_PATH}/listener2.png`;
export const listener3 = `${IMAGES_PATH}/listener3.png`;
export const therapy= `${IMAGES_PATH}/therapy.png`;
export const therapy2= `${IMAGES_PATH}/therapy2.png`;
export const upcoming1= `${IMAGES_PATH}/upcoming1.png`;
export const upcoming2= `${IMAGES_PATH}/upcoming2.png`;
export const continueListening= `${IMAGES_PATH}/continue-listening.png`;

// Live Show
export const VideoIcon = `${IMAGES_PATH}/Liveshow.png`;

// Groups Page
export const DepressionGroup = `${IMAGES_PATH}/DepressionGroup.png`;
export const Debra = `${IMAGES_PATH}/Debra.png`;
export const GroupImage = `${IMAGES_PATH}/GroupImage.png`;
export const GroupCommentImage = `${IMAGES_PATH}/GroupCommentImage.png`;
export const AnnBrown = `${IMAGES_PATH}/AnnBrown.png`;
export const GroupImage2 = `${IMAGES_PATH}/GroupImage2.png`;
export const GroupCommentImage2 = `${IMAGES_PATH}/GroupCommentImage2.png`;
export const Tega = `${IMAGES_PATH}/Tega.png`;
export const Ellipse34 = `${IMAGES_PATH}/Ellipse-34.png`;
export const Ellipse35 = `${IMAGES_PATH}/Ellipse-35.png`;
export const Ellipse36 = `${IMAGES_PATH}/Ellipse-36.png`;
export const Ellipse37 = `${IMAGES_PATH}/Ellipse-37.png`;
export const Ellipse38 = `${IMAGES_PATH}/Ellipse-38.png`;

// Therapist Profile Page to Users
export const Star = `${IMAGES_PATH}/Star.png`;
export const Rating = `${IMAGES_PATH}/Rating.png`;
export const Email = `${IMAGES_PATH}/Email.png`;
export const Location = `${IMAGES_PATH}/Location.png`;
export const AlexStarnesImg = `${IMAGES_PATH}/AlexStarnes.png`;
export const PositiveTick = `${IMAGES_PATH}/PositiveTick.png`;
export const NegativeTick = `${IMAGES_PATH}/NegativeTick.png`;
export const AppointmentHeader1 = `${IMAGES_PATH}/TherAppointmentHeader1.png`;
export const AppointmentHeader2 = `${IMAGES_PATH}/TherAppointmentHeader2.png`;


// Dashbord wrapper
export const JohnAndrew = `${IMAGES_PATH}/JohnAndrew.png`;
export const AndrewJohn = `${IMAGES_PATH}/AndrewJohn.png`;
export const ProfileEdit = `${IMAGES_PATH}/ProfileEdit.png`;

// Daily Check-in Questions Page
export const DailyQuestionsImage = `${IMAGES_PATH}/daily_check_in_img.png`;

// Socials
export const Youtube = `${IMAGES_PATH}/Youtube.png`;
export const Instagram = `${IMAGES_PATH}/Instagram.png`;
export const LinkedIn = `${IMAGES_PATH}/LinkedIn.png`;


// Trending post
export const Mike= `${IMAGES_PATH}/Mike.png`;
export const Mia= `${IMAGES_PATH}/Mia.png`;
export const Benjamin= `${IMAGES_PATH}/Benjamin.png`;
export const Emily= `${IMAGES_PATH}/Emily.png`;
export const Samantha= `${IMAGES_PATH}/Samantha.png`;

// Audiocast Live
export const Zahara= `${IMAGES_PATH}/Zahara.png`;
export const Lisa= `${IMAGES_PATH}/Lisa.png`;
export const Card1= `${IMAGES_PATH}/card-1.png`;
export const Card2= `${IMAGES_PATH}/card-2.png`;
export const Card3= `${IMAGES_PATH}/card-3.png`;
export const Card4= `${IMAGES_PATH}/card-4.png`;
export const Card5= `${IMAGES_PATH}/card-5.png`;
export const Card6= `${IMAGES_PATH}/card-6.png`;
export const Card7= `${IMAGES_PATH}/card-7.png`;
export const Card8= `${IMAGES_PATH}/card-8.png`;
export const Card9= `${IMAGES_PATH}/card-9.png`;