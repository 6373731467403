import { Lisa } from "assets/images";
import { useState } from "react";
import AudiocastMessages from "./AudiocastMessages";
import AudiocastParticipants from "./AudiocastParticipants";

const AudiocastLiveChat = () => {
  const [tabOpen, setTabOpen] = useState("messages");
  const Participant = [
    {
      id: "1",
      image: Lisa,
      name: "Lisa D.",
      message:
        "Wow - wish I knew this information sooner! Definitely will be changing my night routine.",
      time: "11:02am"
    },
    {
      id: "2",
      image: Lisa,
      name: "Akul L.",
      message: "@Lisa D. I completely agree with you. I need to stop being on my phone before bed",
      time: "11:05am"
    },
    {
      id: "3",
      image: Lisa,
      name: "Roman W.",
      message: "Does anyone know where I can look more into this topic? Would love to learn more.",
      time: "11:20am"
    },
    {
      id: "4",
      image: Lisa,
      name: "Cindy C.",
      message: "Thank you guys so much for hosting such an amazing audio cast!",
      time: "11:53am"
    }
  ];
  return (
    <div>
      <div className="bg-[#F5F5F5] rounded-[10px] pb-2 px-4 w-[300px] h-[700px] ">
        <div className="rounded-tl-[10px] rounded-tr-[10px]  border-b-2 border-b-[#C7C7C7] flex items-center justify-between py-2 mb-12 w-full">
          <p className="text-xl text-[#005028] font-medium">Chat</p>
          <span
            className={`${
              tabOpen === "messages"
                ? "bg-[#D8E7DC] text-xs text-[#005028] medium rounded-2xl"
                : "text-xs text-[#005028] medium"
            } cursor-pointer p-2`}
            onClick={() => setTabOpen("messages")}
          >
            Messages
          </span>
          <span
            className={`${
              tabOpen === "participants"
                ? "bg-[#D8E7DC] text-xs text-[#005028] medium rounded-2xl"
                : "text-xs text-[#005028] font-medium"
            } cursor-pointer p-2`}
            onClick={() => setTabOpen("participants")}
          >
            Participants
          </span>
        </div>
        {/* <div>
          {AudioChat.map((item) => (
            <div key={item.id}>
              <div className="flex gap-4 px-4">
                <div className="w-[34px] h-[34px] rounded-full flex item-center mt-7">
                  <img src={item.image} />
                </div>
                <div>
                  <p>{item.name}</p>
                  <p className="text-[11px] bg-white p-2 rounded-[10px] w-[163px] relative">
                    {item.message}
                    <img src={AudioLove} className="absolute -right-1 -bottom-1" />
                  </p>
                  <div className="flex items-center justify-between py-2">
                    <img src={AudioReactions} />
                    <p>{item.time}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */}
        <div>
          <span></span>

          {tabOpen === "messages" && (
            <AudiocastMessages participants={Participant} currentParticipantId="" />
          )}
          {tabOpen === "participants" && <AudiocastParticipants participants={Participant} />}
        </div>
      </div>
    </div>
  );
};

export default AudiocastLiveChat;
