import { DashboardWrapper } from "components";
import SurveyView from "./SurveyView";

export const SurveyContainer = () => {
  return (
    <DashboardWrapper>
      <SurveyView />
    </DashboardWrapper>
  );
};
