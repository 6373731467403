import { Benjamin, Emily, Samantha } from "assets/images";

const CreatorsTrends = () => {
  const TrendingCreators = [
    {
      id: 1,
      image: Benjamin,
      name: "Benjamin Anderson"
    },
    {
      id: 2,
      image: Emily,
      name: "Emily Thompson"
    },
    {
      id: 3,
      image: Samantha,
      name: "Samantha Williams"
    }
  ];
  return (
    <div>
      <div className="mb-4 p-4 bg-white border-[0.04em] border-[#E7E7E7] rounded-[8px]">
        <div className="flex justify-between items-center pb-2">
          <h3 className="font-[600] text-[#005028]">Trending Creators</h3>
          <button className="font-[500] text-[12px] text-[#005028]">See more</button>
        </div>
        {TrendingCreators.map((item) => (
          <div className="py-1" key={item.id}>
            <div className="flex justify-between items-center">
              <img src={item.image} className="w-[42px] h-[42x]rounded-full" />
              <p className="font-[500] text-[12px] text-[#3C4E40]">{item.name}</p>
              <button className="font-[500] text-[12px] text-[#3C4E40]  border border-1 border-[#000] py-[5px] px-[19px] rounded-[20px] text-center">
                Follow
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreatorsTrends;
