import { Video } from "assets/images";
import { Appointment } from "utils/Appointments";

const DashboardAppointments = () => {
  return (
    <div>
      <div className="flex items-center gap-12 lg:gap-4 pl-4 lg:pl-0 py-8">
        <p className="text-[#3C4E40] text-[14px] lg:text-[24px]">Upcoming Appointments</p>
        <p className="text-[#FF9C00] text-[12px] font-semiBold">see all</p>
      </div>
      <div className="grid grid-cols-2 gap-2 justify-between">
        {Appointment.slice(0, 4).map((item) => (
          <div
            className="flex items-center justify-between py-2 px-4 bg-[#EEF3F7] rounded-[10px]"
            key={item.id}
          >
            <p>
              {item.day}
              <br />
              {item.date}
            </p>
            <div className="border border-r-[3px] border-[#005028] h-[42px] rounded-[20px]"></div>
            <div>
              <p className="text-[#005028] text-[14px] font-semiBold">{item.details}</p>
              <p className="font-[300] text-[12px] text-[#005028]">
                {item.duration} - {item.location}
              </p>
            </div>
            <div className="rounded-full bg-white p-2">
              <img src={Video} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardAppointments;
