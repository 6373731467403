import { TherapistDashboardWrapper } from "components";
import TherLogoutView from "./TherLogoutView";

export const TherLogoutContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherLogoutView />
    </TherapistDashboardWrapper>
  );
};
