import { arrowLeft } from "assets/icons";
import { DBCheckbox } from "components/modules";
import { Form, Formik } from "formik";

// interface FiltersProps {
//   onBackClick: () => void;
// };

const Filters = () => {
  const formikInitialValues = {
    filter: false
  };

  const handleSubmit = () => {};
  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-4">
        <span
          className="flex items-center gap-4 text-lg font-bold cursor-pointer"
          //   onClick={onBackClick}
        >
          <img src={arrowLeft} /> Filters
        </span>
      </div>
      <Formik initialValues={formikInitialValues} onSubmit={handleSubmit}>
        <Form className="flex flex-col gap-12">
          <div>
            <span>
              <DBCheckbox nameAndId="filter" label="Quality Filter" type={"checkbox"} />
            </span>
            <p className="text-sm text-gray-200 pr-12">
              Choose to filter out content such as duplicate or automated Post. This doesn’t apply
              to notifications from accounts you follow or have interacted with recently
            </p>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Filters;
