import { boxup, close, edit, eye, lightbulb, trash } from "assets/icons";
import { useState } from "react";
// import moment from "moment";

// interface JournalNoteProps {
//   closeModal: () => void;
// }

const JournalNote = (props: any) => {
  const [note, setNote] = useState("");

  const { closeme } = props;

  const handleChange = (e: any) => {
    setNote(e.target.value);
  };

  const handleSave = () => {
    // const currentDate = moment().format("YYYY-MM-DD");
    // TODO: Save the note to the calendar on the currentDate
    setNote("");
  };

  // const handleClose = () => {
  //   closeModal();
  // };

  return (
    <div className="fixed inset-0 bg-[#000] bg-opacity-70 z-50 flex items-center justify-center p-5">
      <div className="flex flex-col items-center justify-center py-4 px-4 text-center sm:block sm:p-0 h-[653px] w-[990px] rounded-lg bg-[#FFFCF1]">
        <div className="h-[50px] border-b-2 border-b-green flex items-center justify-between px-12">
          <div>space</div>
          <div className="text-2xl text-green">Today</div>
          <button onClick={() => closeme(false)}>
            <img src={close} alt="" />
          </button>
        </div>
        <div className="w-full h-[603px] p-12 flex flex-col gap-4 justify-between">
          {/* <p>{currentDate}</p> */}
          <input
            className="text-black text-3xl font-bold bg-[#FFFCF1] placeholder-green"
            placeholder="Title"
          />
          <textarea
            value={note}
            onChange={handleChange}
            placeholder="Details..."
            className="h-[500px] w-full bg-[#FFFCF1] text-black text-2xl font-normal placeholder-green"
          />
          <div className="flex gap-6 items-center justify-end">
            <img src={eye} alt="" />
            <img src={lightbulb} alt="" />
            <img src={boxup} alt="" onClick={handleSave} />
            <img src={edit} alt="" />
            <img src={trash} alt="" />
          </div>
          {/* <button onClick={handleSave}>Save Note</button> */}
        </div>
      </div>
    </div>
  );
};

export default JournalNote;
