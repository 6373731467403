import {
  MoodBoard,
  DashboardJournal,
  DashboardTrend,
  Breathe,
  NotificationCount,
  Messages,
  Provider,
  TherapistAppointments
} from "components";

function TherDashboardView() {
  return (
    <div className="min-h-[100vh] ">
      <section className="flex px-[0rem] lg:px-[1.8rem] flex-col lg:flex-row gap-4 ">
        <div className="lg:basis-[3/5] bg-white rounded-[10px] p-4">
          <div className="flex flex-col lg:flex-row gap-4 mb-4">
            <div className="lg:w-[236px] ">
              <h2 className="flex items-center gap-2 mb-4">
                <p className="text-[24px]"> New Message</p>
                <NotificationCount count={5} />
              </h2>
              <Messages />

              <div className="w-full">
                <h2 className="text-[24px] mb-4">Providers Network</h2>

                <Provider name="Koby L." />
                <Provider name="Abby D." />
                <Provider name="Kenny S.." />
              </div>
            </div>

            <div className="flex flex-col gap-4 ">
              <MoodBoard />

              <DashboardJournal />
            </div>
          </div>
          <Breathe />
        </div>

        <div className="lg:basis-[2/5] ">
          <DashboardTrend />
        </div>
      </section>
      <section className="  px-[0rem] lg:px-[1.8rem] ">
        <div className="p-4">
          <p className="text-[#3C4E40] text-[14px] lg:text-[24px]">Upcoming Appointments</p>
          <TherapistAppointments />
        </div>
      </section>
    </div>
  );
}

export default TherDashboardView;
