import { ProfileSummary, MoreInfo, Reviews, AlexStarnes } from "components";
import { useState } from "react";

const TherProfileView = () => {
  const therapistToBeDisplayed = AlexStarnes;
  const [isReviewsOpen, setReviewsOpen] = useState(false);
  const [totalReviews, setTotalReviews] = useState(therapistToBeDisplayed.reviewsArray.length);

  const handleReviewSubmit = (number: number) => {
    setTotalReviews((prevTotal) => prevTotal + number);
  };
  
  return (
    <div className={`md:py-[5%] md:px-[5%]`}>
      <ProfileSummary therapist={therapistToBeDisplayed} />
      <div className="my-6 w-full flex gap-5">
        <button
          className={`w-[50%] border-green-100 font-semibold tracking-[3px] ${
            !isReviewsOpen ? "border-b-4 text-green-100" : "text-gray-100"
          }`}
          onClick={() => setReviewsOpen(!isReviewsOpen)}
        >
          INFO
        </button>
        <button
          className={`w-[50%] border-green-100 font-semibold tracking-[3px] ${
            !isReviewsOpen ? "text-gray-100" : "border-b-4 text-green-100"
          }`}
          onClick={() => setReviewsOpen(!isReviewsOpen)}
        >
          <>
            <span>REVIEWS</span>
            <span className="ml-1">({totalReviews})</span>
          </>
        </button>
      </div>
      {!isReviewsOpen && <MoreInfo therapist={therapistToBeDisplayed} />}
      {isReviewsOpen && (
        <Reviews therapist={therapistToBeDisplayed} onReviewSubmit={handleReviewSubmit} />
      )}
    </div>
  );
};

export default TherProfileView;
