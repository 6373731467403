import { TherapistDashboardWrapper } from "components";
import TherSupportView from "./TherSupportView";

export const TherSupportContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherSupportView />
    </TherapistDashboardWrapper>
  );
};
