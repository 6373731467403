import { Chat } from "components";

const ChatsView = () => {
  return (
    <div>
      <Chat />
    </div>
  );
};

export default ChatsView;
