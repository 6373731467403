import { AudioSend, smilePlus } from "assets/icons";
import { Lisa } from "assets/images";
import { useState } from "react";
// import defaultProfileImage from "./default-profile-image.png";

interface Participant {
  id: string;
  name: string;
  image: string;
}

interface Message {
  senderId: string;
  content: string;
  timestamp: Date;
}

interface ChatProps {
  participants: Participant[];
  currentParticipantId: string;
}

const AudiocastMessages = ({ participants, currentParticipantId }: ChatProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      const message: Message = {
        senderId: currentParticipantId,
        content: newMessage,
        timestamp: new Date()
      };

      setMessages((prevMessages) => [...prevMessages, message]);
      setNewMessage("");
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto scrollbar-thumb-[#F6F9FB] scrollbar-track-[#F6F9FB] scrollbar-thin justify-start">
        {messages.map((message, index) => {
          const sender = participants.find((participant) => participant.id === message.senderId);
          const isCurrentUser = message.senderId === currentParticipantId;

          return (
            <div
              key={index}
              className={`flex items-start mb-4 ${isCurrentUser ? "justify-end" : "justify-start"}`}
            >
              {!isCurrentUser && (
                <img
                  src={sender?.image ?? Lisa}
                  alt="Profile"
                  className="w-10 h-10 rounded-full mr-2"
                />
              )}
              <div className={`${isCurrentUser ? "text-right" : "text-left"} flex flex-col`}>
                <span className="font-medium">{isCurrentUser ? "You" : sender?.name}</span>
                <span>{message.content}</span>
                <span className="text-xs text-gray-500">{message.timestamp.toLocaleString()}</span>
              </div>
              {isCurrentUser && (
                <img
                  src={sender?.image ?? Lisa}
                  alt="Profile"
                  className="w-10 h-10 rounded-full ml-2"
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="flex flex-col justify-between p-2 bg-white rounded-md">
        <p className="text-xs">Type your message here...</p>
        <div className="flex items-center justify-between">
          <button>
            <img src={smilePlus} />
          </button>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="bg-[#FFF] text-black w-full indent-5 rounded-[10px] p-2 outline-none focus:outline-none"
          />
          <button onClick={handleSendMessage}>
            <img src={AudioSend} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudiocastMessages;
