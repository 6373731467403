import { arrowLeft } from "assets/icons";
import { DBCheckbox } from "components/modules";
import { Form, Formik } from "formik";

const TwoFA = () => {
  const formikInitialValues = {
    message: false,
    authenticator: false,
    key: false
  };

  const handleSubmit = () => {};
  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-4">
        <span className="flex items-center gap-4 text-lg font-bold">
          <img src={arrowLeft} /> Two-Factor Authentication
        </span>
      </div>
      <Formik initialValues={formikInitialValues} onSubmit={handleSubmit}>
        <Form className="flex flex-col gap-12">
          <div>
            <span>
              <DBCheckbox nameAndId="message" label="Text Message" type={"checkbox"} />
            </span>
            <p className="text-sm text-gray-200 pr-12">
              Use your mobile phone to receive a text message with an authentication code to enter
              when you log in to BMHM.
            </p>
          </div>

          <div>
            <span>
              <DBCheckbox nameAndId="authenticator" label="Authentication App" type={"checkbox"} />
            </span>
            <p className="text-sm text-gray-200 pr-12">
              Use a mobile authentication app to get a verification code to enter every time you log
              in to BMHM.
            </p>
          </div>

          <div>
            <span>
              <DBCheckbox nameAndId="key" label="Security Key" type={"checkbox"} />
            </span>
            <p className="text-sm text-gray-200 pr-12">
              Use a security key that inserts into your computer or syncs to your mobile device when
              you log in to Twitter. You’ll need to use a supported mobile device or web browser.
              Learn more
            </p>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default TwoFA;
