interface FormInputProps{
  nameAndId: string;
  label: string | string[];
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: "text" | "number" | "email" | "password" | "tel" | "file";
  field?: "input" | "textarea";
  className?: string;
  labelClassName?: string;
  required?: boolean;
}

function Input({
  nameAndId,
  label,
  placeholder,
  value,
  onChange,
  type,
  field,
  className,
  labelClassName,
  required = true
}: FormInputProps) {
  
  const Field = field === "textarea" ? "textarea" : "input";
  const fieldHeight = field === "textarea" ? "h-28" : "h-10";

  return (
    <div className="my-3 flex flex-col gap-2">
      <label
        className={`flex items-center ${labelClassName ?? ""}`}
        htmlFor={nameAndId}
      >
        {Array.isArray(label) ? (
          <>
            <span>{label[0]}</span>
            <span className="text-[#FF5630]">{label[1]}</span>{" "}
          </>
        ) : (
          <span>{label}</span>
        )}
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type ?? "text"}
        className={
          className ??
          `w-full p-5 ${fieldHeight} text-sm border border-green rounded-lg placeholder-[#C4C4C4] placeholder-opacity-75`
        }
        rows={field === "textarea" ? 4 : undefined} 
        required={required}
        style={{outline: "none"}}
      />
    </div>
  );
}

export default Input;
