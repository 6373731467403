import {
  dashboardimg,
  NotificationIcon,
  dashsearchIcon,
  profilePic,
  JohnAndrew
} from "assets/images";
import "./DashboardHeader.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MYPROFILE, SETTINGS } from "routes/CONSTANTS";
// import { useSelector } from "react-redux";

function DashboardHeader() {
  // const user = useSelector((state: any) => state.auth.user.user);

  const [open, setOpen] = useState(false);
  return (
    <div
      className="bg-[#F6F9FB] fixed w-[80%] left-[20%] top-0   h-[6rem]  items-center flex  px-[1rem] lg:px-[3rem] py-2 "
      style={{ borderBottom: "2px solid #EFEFEF", borderLeft: "2px solid #EFEFEF" }}
    >
      <div className="flex  items-center gap-1 w-[70%]">
        <img src={dashboardimg} alt="dashboardimg" />
        <div className="">
          <span className="text-[16px] lg:text-[18px]">Good Morning </span>
          <span className="text-[16px] lg:text-[18px] lg:text-orange ">Shallom</span>
          <p className="text-[16px] lg:text-[18px]">How are you feeling today?</p>
        </div>
        <img src={dashsearchIcon} className="relative hidden xl:block  left-[38px]" />
        <input
          type="text"
          placeholder="Search"
          className=" h-[2rem] hidden xl:block  w-[300px] px-10 rounded-md p-5 shadow-sm "
          style={{ background: "#EFF2F5" }}
        />
      </div>
      <div className="flex justify-end gap-10 items-center  w-[30%]">
        <img src={NotificationIcon} className="cursor-pointer" />
        <div>
          <div
            onClick={() => {
              setOpen(!open);
            }}
          >
            <img src={profilePic} className="cursor-pointer" />
          </div>
          <div
            className={`bg-white w-[219px] rounded-[10px] py-4 px-[21px] border-[0.5px] border-[#fff] shadow-md shadow-[#EFEFEF] z-50 absolute top-[70%] right-[6%] dropdown-menu  ${
              open ? "active" : "inactive"
            }`}
          >
            <div className="flex justify-center">
              <img src={JohnAndrew} alt="John Andrew" className="w-[35px] h-[35px] rounded-full" />
            </div>
            <h3 className="text-center py-2 text-[14px] font-bold">John Andrew</h3>
            <ul className="text-sm flex flex-col gap-3 mt-2">
              <li>
                <Link to={MYPROFILE} className="hover:text-green">
                  My Profile
                </Link>
              </li>
              <li>
                <Link to={SETTINGS} className="hover:text-green">
                  Settings
                </Link>
              </li>
            </ul>
            <hr className="my-4 h-[1px] bg-[#EFEFEF] border-0 " />
            <p className="text-sm text-alerts-error-color cursor-pointer">
              <a>Sign out</a>
            </p>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default DashboardHeader;
