import { AudioCards } from "components/layout/Audiocasts";

const AudiocastsView = () => {
  return (
    <div>
      <AudioCards />
    </div>
  );
};

export default AudiocastsView;
