import { DashboardWrapper} from "components";
import MyProfileView from "./MyProfileView";

export const MyProfileContainer = () => {
  return (
    <DashboardWrapper>
      <MyProfileView />
    </DashboardWrapper>
  );
};


