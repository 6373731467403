import { TherapistDashboardWrapper } from "components";
import TherGroupsView from "./TherGroupsView";

export const TherGroupsContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherGroupsView />
    </TherapistDashboardWrapper>
  );
};
