import { useState } from "react";
import { CalendarComponent, ChangeEventArgs } from "@syncfusion/ej2-react-calendars";
import "@syncfusion/ej2-react-calendars/styles/bootstrap.css";
import "@syncfusion/ej2-base/styles/bootstrap.css";
import { registerLicense } from "@syncfusion/ej2-base";

interface Props {
  onDateSelect: (date: Date | null) => void;
  className?: string;
}

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NGaF1cVWhAYVJ2WmFZfV1gd19FaFZVTGYuP1ZhSXxQdk1iWH9ddHJRQmheVEQ="
);

const Calendar = ({ onDateSelect, className }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const handleDateSelect = (date: ChangeEventArgs) => {
    const selectedDateValue = date.value as Date;
    setSelectedDate(selectedDateValue);

    if (selectedDateValue) {
      onDateSelect(selectedDateValue);
    } else {
      onDateSelect(null);
    }
  };

  const customStyles = {
    border: "none",
    width: "50px"
  };

  return (
    <div className={className}>
      <CalendarComponent
        className="custom-calendar"
        style={customStyles}
        onChange={handleDateSelect}
        value={selectedDate}
      />
    </div>
  );
};

export default Calendar;
