import { DashboardWrapper } from "components";
import AudiocastsView from "./AudiocastsView";

export const AudiocastsContainer = () => {
  return (
    <DashboardWrapper>
      <AudiocastsView />
    </DashboardWrapper>
  );
};
