import { AudioLive } from "assets/icons";

const AudiocastLiveHeader = () => {
  return (
    <div>
      <div className="flex items-center justify-between px-4  pb-2">
        <h2 className="text-[20px] font-[500] text-[#005028]">
          Mindfulness and Sleep: How Sleep Effects Our Mental Health
        </h2>
        <div className="text-white flex items-center gap-1 border-1 rounded-[20px] bg-[#E31D1C] py-1 px-2 font-[500] text-[14px]">
          <img src={AudioLive} />
          <p>LIVE</p>
        </div>
      </div>
      <div className="flex items-center justify-between px-4 pb-4 font-[500] ">
        <div className="flex items-center gap-1">
          <span className="block w-[7px] h-[7px] bg-[#F40000] rounded-full"></span> <p>Recording</p>
        </div>
        <p>24.05</p>
      </div>
      <hr className="border-1 h-px bg-[#979797] px-4 " />
    </div>
  );
};

export default AudiocastLiveHeader;
