import { TherapistDashboardWrapper } from "components";
import TherAudiocastsView from "./TherAudiocastsView";

export const TherAudioCastsContainer = () => {
  return (
    <TherapistDashboardWrapper>
      <TherAudiocastsView />
    </TherapistDashboardWrapper>
  );
};
